import React, { useEffect, useState } from "react";
import {
  CommonHeading,
  CommonTextField,
  Loader,
  ProfileDetail,
  ProfileSummary,
} from "../../../components";
import "./style.scss";
import { Col, Divider, Row, Space } from "antd";
import { CommonDivider } from "../../../components/common";
import { Images } from "../../../theme";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getMaterialServiceByIdRequest,
  getServiceByIdRequest,
} from "../../../redux/slicers/priceBook";

const MaterialDetail = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const serviceName = params.get("name");

  console.log("🚀 ~ MaterialDetail ~ list:", list);
  const { id: paramId } = useParams();

  const {
    image,
    name,
    serviceCost,
    unit,
    servicePrice,
    isTaxable,
    isOnline,
    description,
    categoryName,
  } = list ?? {};

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getMaterialServiceByIdRequest({
        payloadData: {},
        parameter: paramId,
        responseCallback: (res) => {
          setIsLoading(false);
          if (res.status) {
            setList(res.data);
          } else {
            console.log(res.errors, "error");
          }
        },
      })
    );
  }, [paramId]);

  if (isLoading) return <Loader />;

  return (
    <div className="service-detail-parent">
      <Space>
        <img
          className="c-p"
          src={Images.backArrow}
          width={"8px"}
          onClick={() => navigate(-1)}
        />
        <CommonHeading className={"theme-text"} text={serviceName} />
      </Space>

      <div className="common-container img-section">
        <CommonTextField text={"Image"} fontWeight={600} mb={"20px"} />
        <img src={image} />
      </div>

      <div className="common-container">
        <CommonTextField
          text={"Service Information"}
          fontWeight={600}
          mb={"20px"}
        />
        <Row gutter={[20, 20]}>
          <Col xs={12} sm={8} md={6} lg={6}>
            <CommonTextField className={"light-text"} text={"Service Name"} />
            <CommonTextField text={name} fontWeight={500} />
          </Col>
          <Col xs={12} sm={8} md={6} lg={6}>
            <CommonTextField className={"light-text"} text={"Category"} />
            <CommonTextField text={categoryName} fontWeight={500} />
          </Col>
          <Col xs={12} sm={8} md={6} lg={6}>
            <CommonTextField className={"light-text"} text={"unit"} />
            <CommonTextField text={unit} fontWeight={500} />
          </Col>
          <Col xs={12} sm={8} md={6} lg={6}>
            <CommonTextField className={"light-text"} text={"Service Cost"} />
            <CommonTextField text={`$${serviceCost}`} fontWeight={500} />
          </Col>
          <Col xs={12} sm={8} md={6} lg={6}>
            <CommonTextField className={"light-text"} text={"Service Price"} />
            <CommonTextField text={`$${servicePrice}`} fontWeight={500} />
          </Col>
          <Col xs={12} sm={8} md={6} lg={6}>
            <CommonTextField className={"light-text"} text={"Taxable"} />
            <CommonTextField text={isTaxable ? "Yes" : "No"} fontWeight={500} />
          </Col>
          <Col xs={12} sm={8} md={6} lg={6}>
            <CommonTextField className={"light-text"} text={"Online Booking"} />
            <CommonTextField text={isOnline ? "Yes" : "No"} fontWeight={500} />
          </Col>

          <Col xs={24} sm={24} md={24} lg={24}>
            <CommonTextField className={"light-text"} text={"Description"} />
            <CommonTextField text={description} fontWeight={500} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MaterialDetail;
