import React, { useState } from "react";
import { CommonButton, CommonHeading, CommonModal } from "../../common";
import { Images } from "../../../theme";
import AddNew from "./AddNew";
import AddEmployee from "./AddEmployee";
import AddNewCustomer from "./AddNewCustomer";
import { ADD_ESTIMATE_ROUTE } from "../../../constants";
import { useNavigate } from "react-router-dom";

const AddNewItem = () => {
  const [Add, setAdd] = useState(false);
  const [AddWorker, setAddWorker] = useState(false);
  const [AddCustomer, setAddCustomer] = useState(false);
  const navigate = useNavigate();

  const onWorker = () => {
    setAdd(false);
    setAddWorker(true);
  };

  const onCustomer = () => {
    setAdd(false);
    setAddCustomer(true);
  };

  const onEstimate = () => {
    setAdd(false);
    navigate(ADD_ESTIMATE_ROUTE);
  };

  return (
    <div>
      <CommonButton
        classname={"icon-but"}
        icon={<img src={Images.plusIcon} width={"20px"} height={"20px"} />}
        text={"Add New Item"}
        onClick={() => setAdd(true)}
      />
      <CommonModal
        title={<CommonHeading level={1} text={"Add New"} />}
        isModalVisible={Add}
        setIsModalVisible={setAdd}
      >
        <AddNew
          onWorker={onWorker}
          onCustomer={onCustomer}
          onEstimate={onEstimate}
        />
      </CommonModal>
      <CommonModal
        destroyOnClose={true}
        title={<CommonHeading level={1} text={"Add Employee"} />}
        isModalVisible={AddWorker}
        setIsModalVisible={setAddWorker}
      >
        <AddEmployee setAddWorker={setAddWorker} invite={true} />
      </CommonModal>
      <CommonModal
        destroyOnClose={true}
        title={<CommonHeading level={1} text={"Add Customer"} />}
        isModalVisible={AddCustomer}
        setIsModalVisible={setAddCustomer}
      >
        <AddNewCustomer setAddCustomer={setAddCustomer} />
      </CommonModal>
    </div>
  );
};

export default AddNewItem;
