import React, { useEffect, useState } from "react";
import {
  CommonAuthLayout,
  CommonInputField,
  CommonTextField,
} from "../../../components";
import { EMAIL_RULE, otpValidation } from "../../../utils";
import { Checkbox, Form, Input, Progress, Space } from "antd";
import { CommonButton } from "../../../components/common";
import OTPInput from "react-otp-input";
import "./styles.scss";
import {
  COMPANY_SETUP,
  PROFILE_SETUP,
  RESET_PASSWORD,
  USER_ROLE,
  WAITING_SCREEN,
} from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import {
  confirmOtpRequest,
  resendOtpRequest,
} from "../../../redux/slicers/user";
import { useDispatch } from "react-redux";

const EmailVerification = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(100);
  const [secondsLeft, setSecondsLeft] = useState(60);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  const { action, email } = location.state;

  const onFinish = (values) => {
    setLoading(true);
    console.log("🚀 ~ onFinish ~ values:", values);

    const payloadData = {
      email: email,
      otp: values.otp,
      deviceToken: "c6517c3d-fcba-48c6-a49f-f33e3aed634f",
    };

    dispatch(
      confirmOtpRequest({
        payloadData,
        responseCallback: (res) => {
          console.log("🚀 ~ onFinish ~ res:", res);
          if (res.status) {
            if (action === "signup") {
              navigate(COMPANY_SETUP, { state: { userID: res.data.id } });
            } else if (action === "forget") {
              navigate(RESET_PASSWORD, {
                state: { email: email, otp: res.data.otp },
              });
            } else if (action === USER_ROLE["WORKER"]) {
              navigate(WAITING_SCREEN);
            } else if (action === USER_ROLE["FREELANCER"]) {
              navigate(PROFILE_SETUP, { state: { userID: res.data.id } });
            }
          } else {
          }
          setLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        return prevProgress - 100 / 60;
      });

      setSecondsLeft((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          return prevSeconds;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <CommonAuthLayout heading={"Email Verification"} backBut={true}>
      <Form onFinish={onFinish} className="verification-parent">
        <Space direction="vertical" align="center">
          <CommonTextField topClass={"mb-15"} text={email} />
          <Form.Item
            name={"otp"}
            rules={[
              {
                validator: (_, value) => {
                  return otpValidation(value);
                },
              },
            ]}
          >
            <OTPInput
              inputType="number"
              containerStyle={"otp-parent"}
              numInputs={6}
              renderInput={(props) => <Input type="number" {...props} />}
            />
          </Form.Item>
          <Progress
            strokeWidth={10}
            type="circle"
            percent={progress}
            format={() => {
              return (
                <CommonTextField
                  text={`0:${secondsLeft.toString().padStart(2, "0")}`}
                />
              );
            }}
          ></Progress>
        </Space>
        <CommonButton
          loading={loading}
          topClass="mt-20"
          htmlType="submit"
          text={"Verify"}
        />
        <div className={`resend-but ${secondsLeft > 0 ? "disable" : ""}`}>
          <CommonTextField
            fontWeight={500}
            text={"Resend Code"}
            onClick={() => {
              dispatch(
                resendOtpRequest({
                  payloadData: { email: email },
                  responseCallback: (res) => {
                    console.log("🚀 ~ onFinish ~ res:", res);
                    if (res.status) {
                      setProgress(100);
                      setSecondsLeft(60);
                    } else {
                    }
                  },
                })
              );
            }}
          />
        </div>
      </Form>
    </CommonAuthLayout>
  );
};

export default EmailVerification;
