import _ from "lodash";
import { EMPLOYEE_TYPE, getCustomerRole } from "../constants";

export const manipulateUserData = (data) => {
  if (_.isEmpty(data)) return {};
  let user = {};
  user["id"] = data?.id ?? 0;

  return user;
};

export function ProfileManipulatorObject(data = {}) {
  console.log(
    "🚀 ~ ProfileManipulatorObject ~ ProfileManipulatorObject:",
    ProfileManipulatorObject
  );
  try {
    if (_.isEmpty(data)) return {};

    const payload = {};

    payload.profile = companyProfileManipulator(data);
    payload.services = serviceAreaManipulator(data.serviceArea);
    payload.operationTime = operationTimeManipulatorObject(
      data?.operationTime[0]
    );

    return payload;
  } catch (error) {
    console.error("singleStockNameManipulator error --->>> ", error);
  }
}

export function operationTimeManipulatorObject(data = {}) {
  console.log("🚀 ~ operationTimeManipulatorObject ~ data:", data);
  try {
    if (_.isEmpty(data)) return {};

    const payload = {};

    payload.companyId = data?.companyId;
    payload.operatingHoursStartDay = data?.operatingHoursStartDay;
    payload.operatingHoursEndDay = data?.operatingHoursEndDay;
    payload.onlineWindowStartDay = data?.onlineWindowStartDay;
    payload.onlineWindowEndDay = data?.onlineWindowEndDay;
    payload.earliestAvailability = data?.earliestAvailability;

    return payload;
  } catch (error) {
    console.error("operationTimeManipulatorObject error --->>> ", error);
  }
}

export function serviceAreaManipulator(list = []) {
  try {
    if (_.isEmpty(list) ?? !list?.length) {
      return [];
    }

    const serviceAreaList = [];

    for (const area of list) {
      const payload = {};

      payload.id = area?.id;
      payload.state = area?.state;
      payload.city = area?.city;

      serviceAreaList.push(payload);
    }

    return serviceAreaList;
  } catch (error) {
    console.error("serviceAreaManipulator error --->>>> ", error);
    return [];
  }
}

export function serviceAreaManipulatorObject(data = {}) {
  console.log("🚀 ~ serviceAreaManipulatorObject ~ data:", data);
  try {
    if (_.isEmpty(data)) return {};

    const payload = {};

    payload.id = data?.id;
    payload.state = data?.state;
    payload.city = data?.city;
    return payload;
  } catch (error) {
    console.error("serviceAreaManipulatorObject error --->>> ", error);
  }
}

export function companyProfileManipulator(data = {}) {
  try {
    if (_.isEmpty(data)) return {};

    const payload = {};

    payload.companyId = data.id;
    payload.companyCode = data.companyCode;
    payload.logo = data?.logo;
    payload.businessName = data?.businessName;
    payload.supportEmail = data?.supportEmail;
    payload.businessPhone = data?.businessPhone;
    payload.licenseNumber = data?.licenseNumber;
    payload.website = data?.website;
    payload.legalEntityName = data?.legalEntityName;
    payload.industry = data?.industry;
    payload.address = data?.address;
    payload.companyDescription = data?.companyDesription;
    payload.termAndCondition = data?.termAndCondition;
    payload.messageInvoiceReceiptEstimate = data?.messageInvoiceReceiptEstimate;

    return payload;
  } catch (error) {
    console.error("companyProfileManipulator error --->>> ", error);
  }
}

export function CompanyListManipulator(list = []) {
  console.log("🚀 ~ organizationListManipulator ~ list:", list);
  try {
    if (_.isEmpty(list) ?? !list?.length) {
      return [];
    }

    const userList = [];

    for (const user of list) {
      const payload = {};

      payload.value = user.companyCode;
      payload.label = user.businessName;
      payload.id = user.id;

      userList.push(payload);
    }

    return userList;
  } catch (error) {
    console.error("organizationListManipulator error --->>>> ", error);
    return [];
  }
}

export function customerManipulator(list = []) {
  try {
    if (_.isEmpty(list) ?? !list?.length) {
      return [];
    }

    const stockList = [];

    for (const data of list) {
      const payload = {};

      payload.key = data?.id;
      payload.id = data?.id;
      payload.userId = data?.userId;
      payload.fullName = data?.fullName;
      payload.firstName = data?.firstName;
      payload.lastName = data?.lastName;
      payload.displayName = data?.displayName;
      payload.email = data?.email;
      payload.mobilePhone = data?.mobilePhone;
      payload.workPhone = data?.workPhone;
      payload.role = data?.role;
      payload.isHomeOwner = data?.isHomeOwner;
      payload.isBusinessOwner = data?.isBusinessOwner;
      payload.streetAddress = data?.streetAddress;
      payload.city = data?.city;
      payload.unit = data?.unit;
      payload.state = data?.state;
      payload.zipCode = data?.zipCode;
      payload.addressNote = data?.addressNote;
      payload.addresses = data?.addresses;
      payload.homePhone = data?.homePhone;
      payload.customerType = getCustomerRole(data);

      stockList.push(payload);
    }

    return stockList;
  } catch (error) {
    console.error("customerManipulator error --->>>> ", error);
    return [];
  }
}

export function employeeManipulator(list = []) {
  console.log("🚀 ~ employeeManipulator ~ list:", list);
  try {
    if (_.isEmpty(list) ?? !list?.length) {
      return [];
    }

    const employeeList = [];

    for (const data of list) {
      const payload = {};

      payload.key = data?.id;
      payload.id = data?.id;
      payload.companyId = data?.companyId;
      payload.fullName = data?.fullName;
      payload.email = data?.email;
      payload.phoneNumber = data?.phone;
      payload.employeeType = EMPLOYEE_TYPE[data?.workerType];
      payload.companyName = data?.companyProfile?.businessName;
      payload.logo = data?.companyProfile?.logo;

      employeeList.push(payload);
    }

    return employeeList;
  } catch (error) {
    console.error("employeeManipulator error --->>>> ", error);
    return [];
  }
}

export function commonTableManipulator(list = []) {
  console.log("🚀 ~ commonTableManipulator ~ list:", list);
  try {
    if (_.isEmpty(list) ?? !list?.length) {
      return [];
    }

    const listData = [];

    for (const data of list) {
      const payload = {};

      payload.key = data?.id;
      payload.id = data?.id;
      payload.name = data?.name;

      listData.push(payload);
    }

    return listData;
  } catch (error) {
    console.error("employeeManipulator error --->>>> ", error);
    return [];
  }
}
