import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  CommonButton,
  CommonHeading,
  CommonInputField,
  CommonModal,
  CommonTable,
  CommonTextField,
  CommonThreeDotMenu,
} from "../../../common";
import { Images } from "../../../../theme";
import { Avatar, Col, Pagination, Row, Space } from "antd";
// import "./styles.scss";
import { employeeTableData } from "../../../../constants";
import AddEmployee from "../../AddNewItem/AddEmployee";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getStockRequest } from "../../../../redux/slicers/customer";
import { employeeManipulator } from "../../../../data-manipulator/user";
import AddLead from "./AddLead";
import {
  deleteLeadSourceRequest,
  getLeadSourceRequest,
} from "../../../../redux/slicers/leadSource";

const LeadSource = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const { companyId } = useSelector((t) => t.user.data);

  const searchRef = useRef();
  const [offSet, setOffSet] = useState(0);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onDelete = (id) => {
    setId(id);
    setDeleteModal(true);
  };

  const onEdit = (id) => {
    console.log("🚀 ~ onEdit ~ id:", id);
    setId(id);
    setUpdateModal(true);
  };

  const callRequest = (search = "") => {
    dispatch(
      getLeadSourceRequest({
        payloadData: {},
        query: `limit=10&offset=${offSet}&search=${search}&id=${companyId}`,
        responseCallback: (res) => {
          console.log("🚀 ~ useEffect ~ res:", res);
          setIsLoading(false);
          if (res.status) {
            console.log(res, "res");
            setList(res.data);
            setTotal(res.count);
          } else {
            console.log(res.errors, "error");
          }
        },
      })
    );
  };

  useEffect(() => {
    callRequest();
  }, [offSet]);

  const handleSearch = (e) => {
    if (searchRef.current) clearTimeout(searchRef.current);

    searchRef.current = setTimeout(() => {
      callRequest(e.target.value);
    }, 300);
  };

  const handlePagination = (e) => {
    console.log(e, "pagination");
    let test = (e - 1) * 10;
    setOffSet(test);
  };

  const renderNameColumn = useCallback(
    (text) => <CommonTextField text={text} />,
    []
  );

  const renderActionColumn = useCallback(
    (_, { id }) => (
      <div className="action-parent">
        <img
          src={Images.deleteIcon}
          className="ml-10"
          onClick={() => onDelete(id)}
        />
      </div>
    ),
    []
  );

  const renderActionHeader = useCallback(
    () => (
      <img
        src={Images.add}
        className="c-p"
        onClick={() => setCreateModal(true)}
      />
    ),
    []
  );

  const columns = [
    {
      title: "Lead Sources",
      dataIndex: "name",
      key: "name",
      render: renderNameColumn,
    },

    {
      title: renderActionHeader,
      dataIndex: "action",
      key: "action",
      width: "50px",
      render: renderActionColumn,
    },
  ];
  return (
    <div className="common-container employee-parent">
      <CommonInputField
        onChange={handleSearch}
        name="search"
        className="small-input"
        placeholder={"search..."}
        suffix={<img src={Images.searchIcon} />}
      />

      <CommonTable
        width={300}
        total={total}
        handlePagination={handlePagination}
        columns={columns}
        dataSource={list}
        loading={isLoading}
      />

      <CommonModal
        destroyOnClose={true}
        title={
          <CommonHeading
            level={1}
            text={updateModal ? "Update Lead Source" : "Add Lead Source"}
          />
        }
        isModalVisible={createModal || updateModal}
        setIsModalVisible={updateModal ? setUpdateModal : setCreateModal}
      >
        <AddLead
          id={id}
          setId={setId}
          setList={setList}
          list={list}
          setCreateModal={setCreateModal}
          setUpdateModal={setUpdateModal}
        />
      </CommonModal>
      <CommonModal
        isModalVisible={deleteModal}
        setIsModalVisible={setDeleteModal}
        discription="Do you want to delete lead source?"
        // loading={isLoading}
        onConfirm={() => {
          dispatch(
            deleteLeadSourceRequest({
              payloadData: {},
              parameter: id,
              responseCallback: (res) => {
                if (res.status) {
                  let listCopy = [...list];
                  let index = listCopy.findIndex((user) => user.id === id);
                  if (index !== -1) {
                    listCopy.splice(index, 1);
                  }
                  setList(listCopy);
                  setDeleteModal(false);
                  setId(null);
                } else {
                  console.log(res.errors, "error");
                }
              },
            })
          );
        }}
      ></CommonModal>
    </div>
  );
};

export default LeadSource;
