import React, { useCallback, useState } from "react";
import {
  CommonAuthLayout,
  CommonInputField,
  CommonTextField,
} from "../../../../components";
import {
  EMAIL_RULE,
  handlePassworMatch,
  passwordValidation,
  phoneValidation,
  validatorField,
} from "../../../../utils";
import { Checkbox, Col, Form, Row, Space } from "antd";
import {
  COMPANY_SETUP,
  EMAIL_VERIFICATION,
  LOGIN_ROUTE,
  WeekDays,
  EmployeeTypeOption,
  EMPLOYEE_TYPE,
} from "../../../../constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CommonButton,
  CommonDivider,
  CommonSelect,
  CommonSwitch,
} from "../../../common";
import { inviteUserRequest } from "../../../../redux/slicers/user";
import "./styles.scss";
import { employeeManipulator } from "../../../../data-manipulator/user";
import {
  approvedEmployeeRequest,
  createEmployeeRequest,
  updateEmployeeRequest,
} from "../../../../redux/slicers/employee";

const permissionsList = [
  "Create RFP",
  "Create Quotation",
  "Online Booking Availability",
  "Take payments & see prices",
  "Show techs new job",
  "See customer Number & email",
  "Show techs full schedule",
  "Allow user to chat with customer",
  "See job costing margins",
  "See and edit customer database",
  "Edit message on invoice",
  "Update company account info",
  "Show home data",
  "Build pro network & share job",
];

const AddEmployee = ({
  id = null,
  setId = () => {},
  setList = () => {},
  list = [],
  setCreateModal = () => {},
  setUpdateModal = () => {},
  setAddWorker = () => {},
  setApprovedModal = () => {},
  approved = false,
  invite = false,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { companyCode } = useSelector((t) => t.user.data);

  const onFinish = (values) => {
    setLoading(true);
    console.log("🚀 ~ onFinish ~ values:", values);

    const { email, fullName, phoneNumber, employeeType } = values;

    const updatePayloadData = {
      phone: phoneNumber,
      fullName: fullName,
    };

    const payloadData = {
      email: email,
      phone: phoneNumber,
      workerType: employeeType,
      companyName: "",
      companyCode: companyCode,
      fullName: fullName,
      isInvited: false,
      isAdmin: false,
      isFreelancer: false,
      isWorker: true,
    };

    if (approved) {
      dispatch(
        approvedEmployeeRequest({
          payloadData: { fullName: fullName },
          parameter: id,
          responseCallback: (res) => {
            if (res.status) {
              let listCopy = [...list];
              let index = listCopy.findIndex((user) => user.id === id);
              if (index !== -1) {
                listCopy.splice(index, 1);
              }
              setList(listCopy);
              setApprovedModal(false);
              setId(null);
              setLoading(false);
            }
          },
        })
      );

      return;
    }

    if (invite) {
      dispatch(
        inviteUserRequest({
          payloadData,
          responseCallback: (res) => {
            if (res.status) {
              setAddWorker(false);
            }
          },
        })
      );

      return;
    }

    if (id) {
      dispatch(
        updateEmployeeRequest({
          payloadData: updatePayloadData,
          parameter: id,
          responseCallback: (res) => {
            setLoading(false);
            if (res.status) {
              let userCopy = [...list];
              let index = userCopy.findIndex((user) => user.id === id);

              if (index !== -1) {
                userCopy[index] = employeeManipulator([res.data])[0];
              }
              setList(userCopy);
              setUpdateModal(false);
              setId(null);
            }
          },
        })
      );
    } else {
      dispatch(
        createEmployeeRequest({
          payloadData,
          responseCallback: (res) => {
            setLoading(false);
            if (res.status) {
              let newData = employeeManipulator([res.data]);
              console.log("🚀 ~ onFinish ~ newData:", newData);
              setList((pre) => [newData[0], ...pre]);
              setCreateModal(false);
            }
          },
        })
      );
    }
  };

  const initialValues = useCallback(() => {
    const data = id && list.find((user) => user.id === id);

    return {
      fullName: data?.fullName,
      email: data?.email,
      phoneNumber: data?.phoneNumber,
      employeeType: EMPLOYEE_TYPE[data?.employeeType],
    };
  }, []);

  return (
    <Form onFinish={onFinish} initialValues={initialValues()}>
      <CommonInputField name="fullName" placeholder={"Full Name"} />
      {(!id || approved) && (
        <CommonInputField
          disabled={approved}
          name="email"
          type={"email"}
          placeholder={"Email Address"}
          rules={EMAIL_RULE}
        />
      )}
      <CommonInputField
        disabled={approved}
        type={"number"}
        name="phoneNumber"
        placeholder={"Mobile Number"}
        rules={[
          {
            validator: (_, value) => {
              return phoneValidation(_, value);
            },
          },
        ]}
      />
      {!id && (
        <CommonSelect
          options={EmployeeTypeOption}
          name="employeeType"
          placeholder={"Employee Role"}
        />
      )}
      <CommonTextField text={"Permissions"} fontWeight={500} />
      <CommonDivider />

      <Row gutter={[20, 10]} className="permission-parent">
        {permissionsList.map((t) => (
          <Col
            key={Math.random()}
            sm={24}
            xs={24}
            md={12}
            className="permission-items"
          >
            <CommonSwitch />
            <CommonTextField text={t} />
          </Col>
        ))}
      </Row>

      <div className="but-sec">
        <CommonButton
          width={"180px"}
          loading={loading}
          htmlType="submit"
          topClass={"small-but"}
          text={`${
            approved ? "Approved" : `${id ? "Update" : "Add"} Employee`
          }`}
        />
      </div>
    </Form>
  );
};

export default AddEmployee;
