import React, { useEffect, useRef, useState } from "react";
import {
  CommonButton,
  CommonCard,
  CommonHeading,
  CommonInputField,
  CommonModal,
  CommonPagination,
} from "../../../../common";
import { Images } from "../../../../../theme";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddIndustry from "./AddIndustry";
import { Col, Row } from "antd";
import { PRICE_BOOK_ROUTE } from "../../../../../constants";
import {
  deleteIndustryRequest,
  getIndustryRequest,
} from "../../../../../redux/slicers/priceBook";
import Loader from "../../../../loader";

const Industry = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const { companyId } = useSelector((t) => t.user.data);

  const searchRef = useRef();
  const [offSet, setOffSet] = useState(0);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onDelete = (id) => {
    setId(id);
    setDeleteModal(true);
  };

  const onEdit = (id) => {
    console.log("🚀 ~ onEdit ~ id:", id);
    setId(id);
    setUpdateModal(true);
  };

  const callRequest = (search = "") => {
    dispatch(
      getIndustryRequest({
        payloadData: {},
        query: `limit=10&offset=${offSet}&search=${search}&id=${companyId}`,
        responseCallback: (res) => {
          console.log("🚀 ~ useEffect ~ res:", res);
          setIsLoading(false);
          if (res.status) {
            console.log(res, "res");
            setList(res.data);
            setTotal(res.count);
          } else {
            console.log(res.errors, "error");
          }
        },
      })
    );
  };

  useEffect(() => {
    callRequest();
  }, [offSet]);

  const handleSearch = (e) => {
    if (searchRef.current) clearTimeout(searchRef.current);

    searchRef.current = setTimeout(() => {
      callRequest(e.target.value);
    }, 300);
  };

  const handlePagination = (e) => {
    console.log(e, "pagination");
    let test = (e - 1) * 10;
    setOffSet(test);
  };

  if (isLoading) return <Loader />;

  return (
    <div className="common-container">
      <div className="search-parent">
        <CommonInputField
          onChange={handleSearch}
          name="search"
          className="small-input"
          placeholder={"search..."}
          suffix={<img src={Images.searchIcon} />}
        />
        <CommonButton
          topClass={"small-but"}
          width={"140px"}
          text={"Add Industry"}
          onClick={() => {
            setCreateModal(true);
            setId(null);
          }}
        />
      </div>

      <Row gutter={[20, 20]} className="mt-20">
        {list.map(({ image, name, id }) => (
          <Col key={id} xs={24} sm={12} md={12} lg={12} xxl={8}>
            <CommonCard
              onClick={() => {
                const path = PRICE_BOOK_ROUTE.replace(":name", "services");
                navigate(
                  `${path}?&name=${name}&type=category&id=${id}&bread=${[name]}`
                );
              }}
              src={image}
              name={name}
              onEdit={onEdit}
              onDelete={onDelete}
              id={id}
            />
          </Col>
        ))}
      </Row>

      <CommonPagination total={total} handlePagination={handlePagination} />

      <CommonModal
        destroyOnClose={true}
        title={
          <CommonHeading
            level={1}
            text={updateModal ? "Update Industry" : "Add Industry"}
          />
        }
        isModalVisible={createModal || updateModal}
        setIsModalVisible={updateModal ? setUpdateModal : setCreateModal}
      >
        <AddIndustry
          id={id}
          setId={setId}
          setList={setList}
          list={list}
          setCreateModal={setCreateModal}
          setUpdateModal={setUpdateModal}
        />
      </CommonModal>
      <CommonModal
        isModalVisible={deleteModal}
        setIsModalVisible={setDeleteModal}
        discription="Do you want to delete Industry?"
        // loading={isLoading}
        onConfirm={() => {
          dispatch(
            deleteIndustryRequest({
              payloadData: {},
              parameter: id,
              responseCallback: (res) => {
                if (res.status) {
                  let listCopy = [...list];
                  let index = listCopy.findIndex((user) => user.id === id);
                  if (index !== -1) {
                    listCopy.splice(index, 1);
                  }
                  setList(listCopy);
                  setDeleteModal(false);
                  setId(null);
                } else {
                  console.log(res.errors, "error");
                }
              },
            })
          );
        }}
      ></CommonModal>
    </div>
  );
};

export default Industry;
