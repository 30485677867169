import React, { useCallback, useState } from "react";
import {
  CommonAuthLayout,
  CommonInputField,
  CommonTextField,
} from "../../../../..";
import { EMAIL_RULE, phoneValidation } from "../../../../../../utils";
import { Checkbox, Col, Flex, Form, Row } from "antd";
import {
  COMPANY_SETUP,
  EMAIL_VERIFICATION,
  LOGIN_ROUTE,
  WeekDays,
  EmployeeTypeOption,
  EMPLOYEE_TYPE,
} from "../../../../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CommonButton,
  CommonDivider,
  CommonSelect,
  CommonSwitch,
  CommonTextAreaField,
  ImageUploader,
} from "../../../../../common";
import {
  inviteUserRequest,
  uploadImgRequest,
} from "../../../../../../redux/slicers/user";
// import "./styles.scss";
import { employeeManipulator } from "../../../../../../data-manipulator/user";

import {
  createServiceRequest,
  createIndustryRequest,
  updateServiceRequest,
  updateIndustryRequest,
} from "../../../../../../redux/slicers/priceBook";

const AddService = ({
  id = null,
  setId = () => {},
  setList = () => {},
  list = [],
  setCreateModal = () => {},
  setUpdateModal = () => {},
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const categoryId = params.get("id");
  const categoryName = params.get("name");

  const onFinish = (values) => {
    setLoading(true);

    console.log("🚀 ~ onFinish ~ values:", values);

    const {
      name,
      description,
      unit,
      serviceCost,
      servicePrice,
      isTaxable,
      isOnline,
      imgUpload,
    } = values;

    const payloadData = {
      name,
      description,
      unit,
      serviceCost: Number(serviceCost),
      servicePrice: Number(servicePrice),
      isTaxable,
      isOnline,
    };

    console.log(payloadData, "payloadData");

    if (id) {
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        dispatch(
          uploadImgRequest({
            payloadData: formData,
            responseCallback: (res) => {
              if (res.status)
                dispatch(
                  updateServiceRequest({
                    payloadData: {
                      ...payloadData,
                      image: res.key,
                    },
                    parameter: id,
                    responseCallback: (res) => {
                      if (res.status) {
                        let userCopy = [...list];
                        let index = userCopy.findIndex(
                          (user) => user.id === id
                        );

                        if (index !== -1) {
                          userCopy[index] = res.data;
                        }
                        setList(userCopy);
                        setUpdateModal(false);
                        setId(null);
                        setLoading(false);
                      }
                    },
                  })
                );
            },
          })
        );
      } else {
        dispatch(
          updateServiceRequest({
            payloadData: { ...payloadData },
            parameter: id,
            responseCallback: (res) => {
              if (res.status) {
                let userCopy = [...list];
                let index = userCopy.findIndex((user) => user.id === id);

                if (index !== -1) {
                  userCopy[index] = res.data;
                }
                setList(userCopy);
                setUpdateModal(false);
                setId(null);
                setLoading(false);
              }
            },
          })
        );
      }
    } else {
      const formData = new FormData();
      formData.append("file", file);
      dispatch(
        uploadImgRequest({
          payloadData: formData,
          responseCallback: (res) => {
            if (res.status) {
              dispatch(
                createServiceRequest({
                  payloadData: {
                    ...payloadData,
                    image: res.key,
                    categoryId: categoryId,
                  },
                  responseCallback: (res) => {
                    console.log(res, "resresresres");
                    if (res.status) {
                      setList((pre) => [res.data, ...pre]);
                      setCreateModal(false);
                      setLoading(false);
                    }
                  },
                })
              );
            }
          },
        })
      );
    }
  };

  const initialValues = useCallback(() => {
    const data = id && list.find((user) => user.id === id);
    return {
      name: data?.name,
      description: data?.description,
      unit: data?.unit,
      serviceCost: data?.serviceCost,
      servicePrice: data?.servicePrice,
      isTaxable: data?.isTaxable || false,
      isOnline: data?.isOnline || false,
      imgUpload: data?.image,
      category: categoryName,
    };
  }, []);

  console.log(initialValues(), "initialValues");

  return (
    <Form onFinish={onFinish} initialValues={initialValues()}>
      <CommonInputField name="name" placeholder={"Service Name"} />
      <CommonTextAreaField name={"description"} placeholder={"Description"} />
      <Row gutter={[20, 0]}>
        <Col xs={24} sm={24} md={24} lg={12}>
          <CommonInputField
            name="category"
            placeholder={"category"}
            disabled={true}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <CommonSelect
            options={[
              { label: "Each", value: "each" },
              { label: "Px", value: "px" },
            ]}
            name="unit"
            placeholder={"Select Unit"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <CommonInputField
            type={"number"}
            name="serviceCost"
            placeholder={"Service Cost"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <CommonInputField
            type={"number"}
            name="servicePrice"
            placeholder={"Service Price"}
          />
        </Col>
      </Row>
      <ImageUploader
        profileImage={initialValues().imgUpload}
        secondary={true}
        setFile={setFile}
      />
      <Flex align="baseline" gap={10}>
        <CommonSwitch name={"isTaxable"} />
        <CommonTextField text={"Taxable"} />
      </Flex>

      <Flex align="baseline" gap={10}>
        <CommonSwitch name={"isOnline"} />
        <CommonTextField text={"Online Booking"} />
      </Flex>

      <div className="but-sec">
        <CommonButton
          width={"180px"}
          loading={loading}
          htmlType="submit"
          topClass={"small-but"}
          text={`${id ? "Update" : "Add"} Service`}
        />
      </div>
    </Form>
  );
};

export default AddService;
