import React, { useEffect, useState } from "react";
import {
  CommonArrowBut,
  CommonButton,
  CommonDivider,
  CommonHeading,
  CommonInputField,
  CommonModal,
  CommonTextAreaField,
  CommonTextField,
} from "../../../../common";
import { EMAIL_RULE, phoneValidation } from "../../../../../utils";
import { Col, Divider, Form, Row } from "antd";
import "./styles.scss";
import { Images } from "../../../../../theme";
import { useDispatch, useSelector } from "react-redux";
import { addServiceAreaRequest } from "../../../../../redux/slicers/userProfile";

const AddServiceDetail = ({ data, setDetail, detail }) => {
  const { userProfile } = useSelector((t) => t.userProfile);
  console.log("🚀 ~ AddServiceDetail ~ userProfile:", userProfile);

  const dispatch = useDispatch();
  // const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  // const { city, state } = data ?? {};

  // useEffect(() => {
  //   form.setFieldsValue({
  //     city: city,
  //     state: state,
  //   });
  // }, [data, detail]);

  const onFinish = (values) => {
    console.log("🚀 ~ onFinish ~ values:", values);
    setLoading(true);
    const { city, state } = values;

    const payloadData = {
      city: city,
      state: state,
    };

    dispatch(
      addServiceAreaRequest({
        payloadData,
        parameter: userProfile?.companyId,
        responseCallback: (res) => {
          console.log("🚀 ~ onFinish ~ res:", res);
          setDetail(false);
          setLoading(false);
        },
      })
    );
    // navigate(COMPANY_SETUP);
  };

  return (
    <>
      <CommonModal
        title={
          <CommonHeading fontSize={"24px"} text={"Add New Service Area"} />
        }
        isModalVisible={detail}
        setIsModalVisible={setDetail}
      >
        <Form onFinish={onFinish}>
          <Row gutter={[20, 0]} className="spacing">
            <Col span={24} md={12}>
              <CommonInputField name="state" placeholder={"State"} />
            </Col>
            <Col span={24} md={12}>
              <CommonInputField name="city" placeholder={"City"} />
            </Col>
          </Row>

          <div className="but-sec">
            <CommonButton
              htmlType="submit"
              topClass={"small-but"}
              text={"Skip"}
              onClick={() => setDetail(false)}
            />
            <CommonButton
              htmlType="submit"
              topClass={"small-but"}
              text={"Save"}
            />
          </div>
        </Form>
      </CommonModal>
    </>
  );
};

export default AddServiceDetail;
