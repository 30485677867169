// @flow
import _ from "lodash";
import { createSlice, current } from "@reduxjs/toolkit";

const leadSource = createSlice({
  name: "leadSource",
  initialState: {
    data: {},
  },
  reducers: {
    getLeadSourceRequest(state, action) {},
    createLeadSourceRequest(state, action) {},
    updateLeadSourceRequest(state, action) {},
    deleteLeadSourceRequest(state, action) {},
    getAllLeadSourceRequest(state, action) {},
  },
});

export const {
  getLeadSourceRequest,
  createLeadSourceRequest,
  updateLeadSourceRequest,
  deleteLeadSourceRequest,
  getAllLeadSourceRequest,
} = leadSource.actions;

export default leadSource.reducer;
