import FullCalendar from "@fullcalendar/react";
import moment from "moment";
import "./styles.scss";
import { useEffect, useRef, useState } from "react";
import { MEETING_CLASS } from "../../../../constants";
import { CommonHeading, CommonTextField } from "../../../common";

export function renderEventContent(eventInfo) {
  return (
    <div
      className={`${
        MEETING_CLASS[`${eventInfo?.event?.title.toUpperCase()}`]
      } serviceItemWrap`}
    >
      <CommonTextField text={eventInfo?.event?.title} />
    </div>
  );
}
const Calendar = ({
  plugins,
  initialView = "dayGridMonth",
  servicesList,
  eventsSet,
  serviceClickHandler,
  serviceTypeTitle,
}) => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const calendarRef = useRef(null);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // const onYearChange = (event) => {
  //   setYear(parseInt(event.target.value, 10));
  //   updateCalendarDate();
  // };

  const onMonthChange = (event) => {
    setMonth(parseInt(event.target.value, 10));
    updateCalendarDate();
  };

  const updateCalendarDate = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(new Date(year, month - 1, 1)); // month is zero-based
    }
  };

  useEffect(() => {
    updateCalendarDate();
  }, [year, month]);

  return (
    <>
      <div className="calendarHeaderWrap">
        <CommonHeading text={months[month - 1]} />

        {/* {months.map((monthName, index) => (
          <option key={index + 1} value={index + 1}>{`${monthName}`}</option>
        ))} */}
      </div>

      <FullCalendar
        showNonCurrentDates={true}
        fixedWeekCount={false}
        datesSet={(args) => {
          return args;
        }}
        dayMaxEvents={4}
        headerToolbar={false}
        ref={calendarRef}
        plugins={plugins}
        initialView={initialView}
        events={[
          { title: "Management Meeting", date: "2024-05-01" },
          { title: "Management Meeting", date: "2024-05-03" },
          { title: "Class Scheduling", date: "2024-05-05" },
          { title: "Project Discussion", date: "2024-05-09" },
          { title: "Management Meeting", date: "2024-05-12" },
          { title: "Management Meeting", date: "2024-05-17" },
          { title: "Project Discussion", date: "2024-05-20" },
          { title: "Class Scheduling", date: "2024-05-22" },
        ]}
        eventsSet={eventsSet}
        eventClick={serviceClickHandler}
        eventContent={renderEventContent}
        dayHeaderFormat={{ weekday: "long" }}
        viewClassNames={"outer-calendar-wrap"}
        moreLinkClassNames={"show-more-services"}
        moreLinkContent={(args) => {
          return `${args.num}+`;
        }}
      />
    </>
  );
};

export default Calendar;
