import React, { useState } from "react";
import {
  CommonAuthLayout,
  CommonInputField,
  CommonTextField,
} from "../../../components";
import { EMAIL_RULE } from "../../../utils";
import { Checkbox, Form, Space } from "antd";
import "./styles.scss";
import { CommonButton } from "../../../components/common";
import {
  FORGOT_PASSWORD,
  CREATE_ORG_PROFILE,
  SETTING_ROUTE,
  WAITING_SCREEN,
  DASHBOARD_ROUTE,
} from "../../../constants";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginRequest, loginSuccess } from "../../../redux/slicers/user";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    console.log("🚀 ~ onFinish ~ values:", values);

    const { email, password, isAccepted } = values;

    const payloadData = {
      email: email,
      password: password,
      deviceToken: "sdsdffsdsdf",
      rememberMe: isAccepted,
    };

    dispatch(
      loginRequest({
        payloadData,
        responseCallback: (res) => {
          console.log("🚀 ~ onFinish ~ res:", res);
          if (res.status) {
            if (res.data.isWorker) {
              navigate(WAITING_SCREEN);
            } else if (res.data.isFreelancer) {
              navigate(DASHBOARD_ROUTE);
            } else {
              navigate(SETTING_ROUTE);
            }
          } else {
            // setLoading(false);
          }
          setLoading(false);
        },
      })
    );
  };

  return (
    <CommonAuthLayout
      heading={"Welcome Back"}
      bottomText={
        <CommonTextField
          topClass="bottom-text"
          text={
            <>
              Don’t Have An Account?{" "}
              <span
                className="bottom-link"
                onClick={() => navigate(CREATE_ORG_PROFILE)}
              >
                Register Here
              </span>
            </>
          }
        />
      }
    >
      <Form onFinish={onFinish} className="login-parent">
        <CommonInputField
          name="email"
          type={"email"}
          placeholder={"name@email.com"}
          rules={EMAIL_RULE}
        />
        <CommonInputField
          name="password"
          type={"password"}
          placeholder={"Password"}
          rules={[
            {
              required: true,
              message: "Field is required",
            },
          ]}
        />
        <div className="remember-parent">
          <Space align="baseline">
            <Form.Item name="isAccepted" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <CommonTextField text="Remember me" />
          </Space>
          <CommonTextField
            className={"c-p"}
            onClick={() => navigate(FORGOT_PASSWORD)}
            text={"Forgot Password"}
          />
        </div>
        <CommonButton loading={loading} htmlType="submit" text={"Login"} />
      </Form>
    </CommonAuthLayout>
  );
};

export default Login;
