import React, { useEffect, useState } from "react";
import {
  CommonArrowBut,
  CommonButton,
  CommonDivider,
  CommonHeading,
  CommonInputField,
  CommonModal,
  CommonTextAreaField,
  CommonTextField,
  ImageUploader,
} from "../../../../common";
import {
  EMAIL_RULE,
  URL_RULE,
  isValidHttpsURL,
  phoneValidation,
} from "../../../../../utils";
import { Col, DatePicker, Divider, Form, Row } from "antd";
import "./styles.scss";
import { Images } from "../../../../../theme";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserProfileRequest,
  updateUserProfileRequest,
} from "../../../../../redux/slicers/userProfile";
import { uploadImgRequest } from "../../../../../redux/slicers/user";

const AddProfileDetail = ({ data }) => {
  const { data: userData } = useSelector((t) => t.user);

  const [detail, setDetail] = useState(false);
  const [file, setFile] = useState();
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    businessName,
    supportEmail,
    licenseNumber,
    logo,
    website,
    legalEntityName,
    industry,
    address,
    companyDescription,
    termAndCondition,
    companyId,
    businessPhone,
    messageInvoiceReceiptEstimate,
  } = data ?? {};

  useEffect(() => {
    dispatch(
      getUserProfileRequest({
        payloadData: {},
        responseCallback: (res) => {
          console.log("🚀 ~ onFinish ~ res:", res);
          if (res.status) {
            if (!res?.data?.companyProfile?.address) setDetail(true);
          } else {
            // setLoading(false);
          }
        },
      })
    );
  }, []);

  useEffect(() => {
    // if (!businessPhone) {
    //   setDetail(true);
    // }
    form.setFieldsValue({
      imgUpload: logo,
      businessName: businessName,
      supportEmail: supportEmail,
      businessPhone: businessPhone,
      licenseNumber: licenseNumber,
      website: website,
      legalEntityName: legalEntityName,
      industry: industry,
      address: address,
      companyDescription: companyDescription,
      termAndCondition: termAndCondition,
      invoice: messageInvoiceReceiptEstimate,
    });
  }, [data, detail]);

  const onFinish = (values) => {
    console.log("🚀 ~ onFinish ~ values:", values);
    const {
      businessName,
      supportEmail,
      businessPhone,
      website,
      licenseNumber,
      industry,
      invoice,
      companyDescription,
      legalEntityName,
      address,
      termAndCondition,
      imgUpload,
    } = values;

    const payloadData = {
      businessName: businessName,
      userId: userData?.id,
      supportEmail: supportEmail,
      businessPhone: businessPhone,
      website: website,
      industry: industry,
      legalEntityName: legalEntityName,
      licenseNumber: licenseNumber,
      messageInvoiceReceiptEstimate: invoice,
      companyDesription: companyDescription,
      address: address,
      termAndCondition: termAndCondition,
    };
    console.log(file, "file");

    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      dispatch(
        uploadImgRequest({
          payloadData: formData,
          responseCallback: (res) => {
            if (res.status)
              dispatch(
                updateUserProfileRequest({
                  payloadData: { ...payloadData, logo: res.key },
                  parameter: companyId,
                  responseCallback: (res) => {
                    console.log("🚀 ~ onFinish ~ res:", res);
                    setDetail(false);
                    setLoading(false);
                  },
                })
              );
          },
        })
      );
    } else {
      dispatch(
        updateUserProfileRequest({
          payloadData: { ...payloadData },
          parameter: companyId,
          responseCallback: (res) => {
            console.log("🚀 ~ onFinish ~ res:", res);
            setDetail(false);
            setLoading(false);
          },
        })
      );
    }

    // navigate(COMPANY_SETUP);
  };
  console.log("🚀 ~ onFinish ~ file:", file);

  return (
    <>
      <CommonArrowBut
        className="edit-icon"
        width={"15px"}
        height={"15px"}
        src={Images.editIcon}
        onClick={() => {
          setId(2);
          setDetail(true);
        }}
      />
      <CommonModal
        destroyOnClose={true}
        title={<CommonHeading fontSize={"24px"} text={id ? "Edit" : "Add"} />}
        isModalVisible={detail}
        setIsModalVisible={setDetail}
      >
        <Form
          form={form}
          onFinish={onFinish}
          // initialValues={{
          //   businessName: businessName,
          //   supportEmail: supportEmail,
          //   businessPhone: businessPhone,
          //   licenseNumber: licenseNumber,
          //   website: website,
          //   legalEntityName: legalEntityName,
          //   industry: industry,
          //   address: address,
          //   companyDescription: companyDescription,
          //   termAndCondition: termAndCondition,
          //   invoice: messageInvoiceReceiptEstimate,
          // }}
        >
          <CommonTextField fontWeight={500} text={"Logo"} />
          <CommonDivider />
          <div className="image-upload-profile">
            <ImageUploader
              profileImage={logo}
              setFile={setFile}
              secondary={true}
            />
          </div>

          <CommonTextField fontWeight={500} text={"Business Information"} />
          <CommonDivider />
          <Row gutter={[20, 0]} className="spacing">
            <Col span={24} md={12}>
              <CommonInputField
                name="businessName"
                placeholder={"Business Name"}
              />
            </Col>
            <Col span={24} md={12}>
              <CommonInputField
                rules={EMAIL_RULE}
                name="supportEmail"
                placeholder={"Support Email"}
              />
            </Col>
            <Col span={24} md={12}>
              <CommonInputField
                type={"number"}
                name="licenseNumber"
                placeholder={"License Number"}
              />
            </Col>
            <Col span={24} md={12}>
              <CommonInputField
                rules={[
                  {
                    validator: (_, value) => {
                      return phoneValidation(_, value);
                    },
                  },
                ]}
                type={"number"}
                name="businessPhone"
                placeholder={"Business Phone"}
              />
            </Col>
            <Col span={24} md={12}>
              <CommonInputField
                rules={URL_RULE}
                name="website"
                placeholder={"Website"}
              />
            </Col>
            <Col span={24} md={12}>
              <CommonInputField
                name="legalEntityName"
                placeholder={"Legal Entity Name"}
              />
            </Col>
            <Col span={24} md={12}>
              <CommonInputField name="industry" placeholder={"Industry"} />
            </Col>
            <Col span={24} md={12}>
              <CommonInputField name="address" placeholder={"Address"} />
            </Col>
          </Row>
          <CommonTextField fontWeight={500} text={"Company Description"} />
          <CommonDivider />
          <div className="spacing">
            <CommonTextAreaField
              name={"companyDescription"}
              placeholder={"Write here..."}
            />
          </div>

          <CommonTextField
            fontWeight={500}
            text={"Message on invoice, receipt, and estimate"}
          />
          <CommonDivider />
          <div className="spacing">
            <CommonTextAreaField
              name={"invoice"}
              placeholder={"Write here..."}
            />
          </div>

          <CommonTextField fontWeight={500} text={"Terms and Conditions"} />
          <CommonDivider />
          <div className="spacing">
            <CommonTextAreaField
              name={"termAndCondition"}
              placeholder={"Write here..."}
            />
          </div>

          <div className="but-sec">
            <CommonButton
              htmlType="submit"
              topClass={"small-but"}
              text={"Skip"}
              onClick={() => setDetail(false)}
            />
            <CommonButton
              loading={loading}
              htmlType="submit"
              topClass={"small-but"}
              text={"Save"}
            />
          </div>
        </Form>
      </CommonModal>
    </>
  );
};

export default AddProfileDetail;
