import React, { useCallback } from "react";
import { MaterialPriceBookList } from "../../../../components";

const MaterialPriceBook = () => {
  return (
    <div>
      <MaterialPriceBookList />
    </div>
  );
};

export default MaterialPriceBook;
