import React, { useCallback, useEffect, useRef, useState } from "react";
import { Images } from "../../../../theme";
import { Button, Col, Flex, Form, Row, Space } from "antd";
import {
  CommonButton,
  CommonDivider,
  CommonInputField,
  CommonPhoneInput,
  CommonRadio,
  CommonSelect,
  CommonTextField,
} from "../../../common";
import {
  EMAIL_RULE,
  passwordValidation,
  phoneValidation,
  validatorField,
} from "../../../../utils";
import {
  createStockRequest,
  getCompanyListRequest,
  updateStockRequest,
} from "../../../../redux/slicers/customer";
import { useDispatch, useSelector } from "react-redux";
import {
  CompanyListManipulator,
  WorkerManipulator,
  customerManipulator,
  organizationListManipulator,
} from "../../../../data-manipulator/user";
import {
  CUSTOMER_ROLE,
  getCustomerCondition,
  EMPLOYEE_ROLE,
} from "../../../../constants";
import "./styles.scss";

const CreateCustomer = ({
  stockId,
  setStockId,
  setStockList,
  stockList,
  setCreateStockModal,
  setStockUpdateModal,
}) => {
  const [loading, setLoading] = useState(false);
  const { data: companyData } = useSelector((t) => t.user);

  const dispatch = useDispatch();

  const initialValues = useCallback(() => {
    const data = stockId && stockList.find((user) => user.id === stockId);
    return {
      fullName: data?.fullName,
      displayName: data?.displayName,
      email: data?.email,
      companyId: data?.data?.companyId,
      companyName: companyData?.businessName,
      mobilePhone: data?.mobilePhone,
      workPhone: data?.workPhone,
      role: data?.role,
      isHomeOwner: data?.isHomeOwner,
      isBusinessOwner: data?.isBusinessOwner,
      streetAddress: data?.streetAddress,
      city: data?.city,
      unit: data?.unit,
      state: data?.state,
      zipCode: data?.zipCode,
      addressNote: data?.addressNote,
      addresses: !data?.addresses ? [{}] : data?.addresses,
      homePhone: data?.homePhone,
      customerType: !data?.customerType
        ? CUSTOMER_ROLE.HOMEOWNER
        : data?.customerType,
      userId: data?.userId,
    };
  }, [stockId]);

  const onFinish = (values) => {
    console.log("🚀 ~ onFinish ~ values:", values);
    setLoading(true);
    const {
      fullName,
      mobilePhone,
      displayName,
      workPhone,
      email,
      role,
      streetAddress,
      city,
      unit,
      state,
      zipCode,
      addressNote,
      addresses,
      homePhone,
      customerType,
    } = values;

    const payloadData = {
      fullName: fullName,
      displayName: displayName,
      email: email,
      companyId: companyData?.companyId,
      mobilePhone: mobilePhone,
      workPhone: workPhone,
      role: role,
      isHomeOwner: customerType === CUSTOMER_ROLE.HOMEOWNER ? true : false,
      isBusinessOwner: customerType === CUSTOMER_ROLE.BUSINESS ? true : false,
      streetAddress: streetAddress,
      city: city,
      unit: unit,
      state: state,
      zip: zipCode,
      addressNotes: addressNote,
      addresses: addresses,
      homePhone: homePhone,
    };

    if (stockId) {
      dispatch(
        updateStockRequest({
          payloadData: { ...payloadData, userId: initialValues().userId },
          parameter: stockId,
          responseCallback: (res) => {
            if (res.status) {
              let userCopy = [...stockList];
              let index = userCopy.findIndex((user) => user.id === stockId);

              if (index !== -1) {
                userCopy[index] = customerManipulator([res.data])[0];
              }
              setStockList(userCopy);
              setStockUpdateModal(false);
              setStockId(null);
            } else {
              console.log(res.errors, "error");
            }
          },
        })
      );
    } else {
      dispatch(
        createStockRequest({
          payloadData: payloadData,
          responseCallback: (res) => {
            console.log(res, "createStock");

            if (res.status) {
              let newData = customerManipulator([res.data]);
              setStockList((pre) => [newData[0], ...pre]);
              setCreateStockModal(false);
            } else {
              console.log(res.errors, "error");
            }
          },
        })
      );
    }

    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  console.log(initialValues(), "initialValues");

  return (
    <Form
      initialValues={initialValues()}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="create-customer-parent"
    >
      <Row gutter={[10, 0]}>
        <Col span={24}>
          <CommonInputField name="fullName" placeholder={"Full Name"} />
        </Col>

        <Col lg={24} md={24} sm={24} xs={24}>
          <CommonInputField name="displayName" placeholder={"Display Name"} />
        </Col>
        {!stockId && (
          <Col lg={24} md={24} sm={24} xs={24}>
            <CommonInputField
              name="email"
              type={"email"}
              className={"auth"}
              placeholder={"email"}
              rules={EMAIL_RULE}
            />
          </Col>
        )}
        <Col lg={8} md={24} sm={24} xs={24}>
          <CommonInputField
            name="mobilePhone"
            type={"number"}
            placeholder={"Mobile Phone"}
            rules={[
              {
                validator: (_, value) => {
                  return phoneValidation(_, value);
                },
              },
            ]}
          />
        </Col>
        <Col lg={8} md={12} sm={12} xs={24}>
          <CommonInputField
            name="homePhone"
            type={"number"}
            placeholder={"Home Phone"}
            rules={[
              {
                validator: (_, value) => {
                  return phoneValidation(_, value);
                },
              },
            ]}
          />
        </Col>
        <Col lg={8} md={12} sm={12} xs={24}>
          <CommonInputField
            name="workPhone"
            type={"number"}
            placeholder={"Work Phone"}
            rules={[
              {
                validator: (_, value) => {
                  return phoneValidation(_, value);
                },
              },
            ]}
          />
        </Col>
        <Col lg={12} md={12} sm={12} xs={24}>
          <CommonInputField name="companyName" placeholder={"Company Name"} />
        </Col>
        <Col lg={12} md={12} sm={12} xs={24}>
          <CommonInputField name="role" placeholder={"Role"} />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <CommonRadio
            options={[
              {
                name: (
                  <CommonTextField
                    className={"light-text"}
                    text={CUSTOMER_ROLE["HOMEOWNER"]}
                  />
                ),
                value: CUSTOMER_ROLE["HOMEOWNER"],
              },
              {
                name: (
                  <CommonTextField
                    className={"light-text"}
                    text={CUSTOMER_ROLE["BUSINESS"]}
                  />
                ),
                value: CUSTOMER_ROLE["BUSINESS"],
              },
            ]}
            name={"customerType"}
          />
          <CommonDivider className={"role-divider"} />
        </Col>
        <Col lg={12} md={12} sm={12} xs={24}>
          <CommonInputField
            name="streetAddress"
            placeholder={"Street Address"}
          />
        </Col>
        <Col lg={12} md={12} sm={12} xs={24}>
          <CommonInputField name="unit" placeholder={"Unit"} />
        </Col>

        <Col lg={8} md={12} sm={12} xs={24}>
          <CommonInputField name="city" placeholder={"City"} />
        </Col>
        <Col lg={8} md={12} sm={12} xs={24}>
          <CommonInputField name="state" placeholder={"State"} />
        </Col>
        <Col lg={8} md={12} sm={12} xs={24}>
          <CommonInputField
            type={"number"}
            name="zipCode"
            placeholder={"Zip Code"}
          />
        </Col>
        <Col lg={24} md={12} sm={12} xs={24}>
          <CommonInputField name="addressNote" placeholder={"Address Note"} />
        </Col>

        <CommonDivider />
        <Form.List name="addresses">
          {(fields, { add, remove }) => (
            <>
              <div className="flex-between address-text">
                <CommonTextField
                  text={"Add Address"}
                  className={"theme-text"}
                  fontWeight={500}
                />
                <img src={Images.add} onClick={() => add()} />
              </div>

              {fields.map(({ key, name, ...restField }) => (
                <>
                  <div key={key} className="address-container">
                    <Row gutter={[10, 0]}>
                      <Col lg={12} md={12} sm={12} xs={24}>
                        <CommonInputField
                          name={[name, "customerNote"]}
                          field={{ ...restField }}
                          placeholder={"Customer Note"}
                        />
                      </Col>

                      <Col lg={12} md={12} sm={12} xs={24}>
                        <CommonInputField
                          name={[name, "customerTag"]}
                          field={{ ...restField }}
                          placeholder={"Customer Tags"}
                        />
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={24}>
                        <CommonInputField
                          name={[name, "customerBillTo"]}
                          field={{ ...restField }}
                          placeholder={"This customer bill to"}
                        />
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={24}>
                        <CommonInputField
                          name={[name, "leadResource"]}
                          field={{ ...restField }}
                          placeholder={"Lead Source"}
                        />
                      </Col>
                    </Row>
                    {fields.length > 1 ? (
                      <div
                        className="delete-option"
                        onClick={() => remove(name)}
                      >
                        <img src={Images.deleteIcon} />
                      </div>
                    ) : null}
                  </div>

                  <CommonDivider className={"address-divider"} />
                </>
              ))}
            </>
          )}
        </Form.List>
      </Row>

      <CommonButton
        topClass={"small-but"}
        width={"160px"}
        htmlType={"submit"}
        loading={loading}
        text={`${stockId ? "Update" : "Create"} Customer`}
      />
    </Form>
  );
};
export default CreateCustomer;
