import React, { useEffect, useState } from "react";
import {
  BusinessHoursSection,
  CommonHeading,
  CompanyList,
  EmployeePermission,
  JobsEstimateList,
  LeadSource,
  ProfileSection,
  ServiceArea,
  Tags,
} from "../../../components";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { getUserProfileRequest } from "../../../redux/slicers/userProfile";
import {
  PRICE_BOOK_ROUTE,
  settingList,
  settingListMobile,
} from "../../../constants";
import { CommonSelect } from "../../../components/common";
import { useNavigate } from "react-router-dom";

const Setting = () => {
  const [activeComponent, setActiveComponent] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getUserProfileRequest({
        payloadData: {},
        responseCallback: (res) => {
          console.log("🚀 ~ onFinish ~ res:", res);
          if (res.status) {
          } else {
            // setLoading(false);
          }
        },
      })
    );
  }, []);

  const componentList = [
    <ProfileSection />,
    <BusinessHoursSection />,
    <ServiceArea />,
    <EmployeePermission />,
    "",
    "",
    "",
    <LeadSource />,
    <Tags />,
  ];

  const onList = (t, name) => {
    if (name === "Price Book") {
      navigate(PRICE_BOOK_ROUTE.replace(":name", "services"));
    }
    setActiveComponent(t);
  };

  return (
    <div>
      <div className="top-section">
        <CommonHeading
          className={"theme-text"}
          text={settingList[activeComponent].name}
        />
      </div>

      <div className="setting-content">
        <div className="list-section">
          <CompanyList onList={onList} activeComponent={activeComponent} />
          <JobsEstimateList onList={onList} activeComponent={activeComponent} />
        </div>
        <div className="list-select">
          <CommonSelect
            options={settingListMobile}
            defaultValue={0}
            onSelect={(t) => onList(t)}
          />
        </div>
        <div className="detail-section">{componentList[activeComponent]}</div>
      </div>
    </div>
  );
};

export default Setting;
