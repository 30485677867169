import React from "react";
import { CommonTextField } from "../../../common";
import { settingList } from "../../../../constants";

const JobsEstimateList = ({ onList, activeComponent }) => {
  return (
    <div className="company-list-parent">
      <CommonTextField className={"light-text"} text={"Jobs & Estimates"} />
      {settingList.slice(6, 9).map((t) => (
        <div
          className={`list-item ${activeComponent === t.key ? "active" : ""}`}
          key={t.key}
        >
          <CommonTextField
            text={t.name}
            onClick={() => onList(t.key, t.name)}
          />
        </div>
      ))}
    </div>
  );
};

export default JobsEstimateList;
