import React, { useEffect, useState } from "react";
import {
  CommonDatePicker,
  CommonHeading,
  CommonInputField,
  CommonModal,
  CommonSelect,
  CommonTextField,
} from "../../../common";
import { EmployeeTypeOption } from "../../../../constants";
import { Images } from "../../../../theme";
import "./style.scss";
import AddNewCustomer from "../../AddNewItem/AddNewCustomer";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCustomerRequest,
  getStockRequest,
} from "../../../../redux/slicers/customer";
import { customerManipulator } from "../../../../data-manipulator/user";
import {
  createTagRequest,
  getAllTagRequest,
  getTagRequest,
} from "../../../../redux/slicers/general";
import { Form, Tag } from "antd";
import {
  createLeadSourceRequest,
  getAllLeadSourceRequest,
} from "../../../../redux/slicers/leadSource";
import _ from "lodash";
import EstimateUpload from "./Upload";

const EstimateCustomer = ({
  setFile,
  file,
  form,
  setSelectedTags,
  selectedTags,
  setSelectedSource,
  selectedSource,
}) => {
  const [expend1, setExpend1] = useState(false);
  const [expend2, setExpend2] = useState(false);
  const [expend3, setExpend3] = useState(false);
  const [expend4, setExpend4] = useState(false);
  const [AddCustomer, setAddCustomer] = useState(false);
  const [list, setList] = useState([]);
  const [tag, setTag] = useState([]);
  const [source, setSource] = useState([]);

  const dispatch = useDispatch();
  const { companyId } = useSelector((t) => t.user.data);

  const items = [
    {
      title: "Schedule Estimate",
    },
    {
      title: "Add Estimates Fields",
    },
    {
      title: "Add Estimates Tags",
    },
    {
      title: "Add Private Notes",
    },
    {
      title: "Add Lead source",
    },
    {
      title: "Attachments",
    },
  ];

  useEffect(() => {
    dispatch(
      getAllCustomerRequest({
        payloadData: {},
        parameter: companyId,
        responseCallback: (res) => {
          console.log("🚀 ~ useEffect ~ res:", res);
          if (res.status) {
            console.log(res, "res");
            setList(
              res?.data?.map((t) => {
                return {
                  label: t.fullName,
                  value: t.id,
                };
              })
            );
          } else {
            console.log(res.errors, "error");
          }
        },
      })
    );

    dispatch(
      getAllTagRequest({
        payloadData: {},
        parameter: companyId,
        responseCallback: (res) => {
          console.log("🚀 ~ useEffect ~ res:", res);
          if (res.status) {
            console.log(res, "res");
            setTag(
              res?.data?.map((t) => {
                return {
                  label: t.name,
                  value: t.id,
                };
              })
            );
          } else {
            console.log(res.errors, "error");
          }
        },
      })
    );
    dispatch(
      getAllLeadSourceRequest({
        payloadData: {},
        parameter: companyId,
        responseCallback: (res) => {
          console.log("🚀 ~ useEffect ~ res:", res);
          if (res.status) {
            console.log(res, "res");
            setSource(
              res?.data?.map((t) => {
                return {
                  label: t.name,
                  value: t.id,
                };
              })
            );
          } else {
            console.log(res.errors, "error");
          }
        },
      })
    );
  }, []);

  const onAddCustomer = (value) => {
    setList((pre) => [{ label: value.fullName, value: value.id }, ...pre]);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="common-container estimate">
      <CommonTextField
        text={"Customer"}
        fontWeight={500}
        className={"light-text"}
        mb={"10px"}
      />
      <CommonSelect
        showSearch={true}
        filterOption={filterOption}
        options={list}
        name="customerId"
        placeholder={"Name, email"}
      />
      <CommonTextField
        className={"theme-text c-p"}
        fontWeight={500}
        text={"+ Add Customer"}
        onClick={() => setAddCustomer(true)}
      />

      <CommonTextField
        mt={"10px"}
        mb={"10px"}
        text={"Schedule Estimate"}
        fontWeight={500}
        className={"light-text"}
      />
      <CommonDatePicker name="estimateStartTime" placeholder={"Start Date"} />
      <CommonDatePicker name="estimateEndTime" placeholder={"End Date"} />

      <div className={`extra-field ${expend1 ? "expend" : ""}`}>
        <div className="flex-between heading">
          <CommonTextField
            text={"Add Estimates Fields"}
            fontWeight={500}
            className={"light-text"}
          />
          <img
            src={Images.add}
            width={"20px"}
            height={"20px"}
            className="c-p"
            onClick={() => setExpend1(true)}
          />
        </div>

        <div className="show">
          <CommonInputField name="jobType" placeholder={"Job Type"} />
          <CommonInputField
            name={"businessUnit"}
            placeholder={"Business Unit"}
          />
        </div>
      </div>

      <div className={`extra-field ${expend2 ? "expend" : ""}`}>
        <div className="flex-between heading">
          <CommonTextField
            text={"Add Estimates Tags"}
            fontWeight={500}
            className={"light-text"}
          />
          <img
            src={Images.add}
            width={"20px"}
            height={"20px"}
            className="c-p"
            onClick={() => setExpend2(true)}
          />
        </div>

        <div className="show">
          <CommonSelect
            rules={[
              {
                required: false,
              },
            ]}
            onSelect={(t, s) => {
              if (_.isEmpty(s)) {
                dispatch(
                  createTagRequest({
                    payloadData: { name: t, companyId: companyId },
                    responseCallback: (res) => {
                      if (res.status) {
                        setSelectedTags((pre) => [
                          ...pre,
                          { label: res.data.name, value: res.data.id },
                        ]);
                        //         const isId = (item) => {
                        //           const idPattern = /^[a-zA-Z0-9-]{36}$/;
                        //           return idPattern.test(item);
                        //         };
                        //         const currentValues = form.getFieldValue("tags") || [];
                        //         const filteredIds = currentValues.filter(isId);
                        //         const newValues = [res.data.id];
                        //         const combinedValues = Array.from(
                        //           new Set([...filteredIds, ...newValues])
                        //         );
                        //         form.setFieldValue("tags", combinedValues);
                      }
                    },
                  })
                );
              } else {
                setSelectedTags((pre) => [...pre, s]);
              }
            }}
            onDeselect={(e, t) => {
              const filter = selectedTags.filter((t) => t.value !== e);
              setSelectedTags(filter);
            }}
            mode="tags"
            options={tag}
            name="tags"
            placeholder={"Type here..."}
          />

          <div className="source-tag-parent">
            {selectedTags?.map((tag) => (
              <>
                <Tag key={tag.value}>
                  {tag.label}
                  <img
                    src={Images.crossIcon}
                    onClick={() => {
                      const filter = selectedTags.filter(
                        (t) => t.value !== tag.value
                      );
                      setSelectedTags(filter);
                    }}
                  />
                </Tag>
              </>
            ))}
          </div>
        </div>
      </div>

      <div className={`extra-field ${expend3 ? "expend" : ""}`}>
        <div className="flex-between heading">
          <CommonTextField
            text={"Add Private Notes"}
            fontWeight={500}
            className={"light-text"}
          />
          <img
            src={Images.add}
            width={"20px"}
            height={"20px"}
            className="c-p"
            onClick={() => setExpend3(true)}
          />
        </div>
        <div className="show">
          <CommonInputField name="privateNote" placeholder={"Type here..."} />
        </div>
      </div>

      <div className={`extra-field ${expend4 ? "expend" : ""}`}>
        <div className="flex-between heading">
          <CommonTextField
            text={"Add Lead source"}
            fontWeight={500}
            className={"light-text"}
          />
          <img
            src={Images.add}
            width={"20px"}
            height={"20px"}
            className="c-p"
            onClick={() => setExpend4(true)}
          />
        </div>

        <div className="show">
          <CommonSelect
            rules={[
              {
                required: false,
              },
            ]}
            onSelect={(t, s) => {
              if (_.isEmpty(s)) {
                dispatch(
                  createLeadSourceRequest({
                    payloadData: { name: t, companyId: companyId },
                    responseCallback: (res) => {
                      if (res.status) {
                        setSelectedSource((pre) => [
                          ...pre,
                          { label: res.data.name, value: res.data.id },
                        ]);
                        // const isId = (item) => {
                        //   const idPattern = /^[a-zA-Z0-9-]{36}$/;
                        //   return idPattern.test(item);
                        // };
                        // const currentValues =
                        //   form.getFieldValue("leadSource") || [];
                        // const filteredIds = currentValues.filter(isId);
                        // const newValues = [res.data.id];
                        // const combinedValues = Array.from(
                        //   new Set([...filteredIds, ...newValues])
                        // );
                        // form.setFieldValue("leadSource", combinedValues);
                      }
                    },
                  })
                );
              } else {
                setSelectedSource((pre) => [...pre, s]);
              }
            }}
            onDeselect={(e, t) => {
              const filter = selectedSource.filter((t) => t.value !== e);
              setSelectedSource(filter);
            }}
            mode="tags"
            options={source}
            name="leadSource"
            placeholder={"Type here..."}
          />
          <div className="source-tag-parent">
            {selectedSource?.map((source) => (
              <Tag key={source.value}>
                {source.label}
                <img
                  src={Images.crossIcon}
                  onClick={() => {
                    const filter = selectedSource.filter(
                      (t) => t.value !== source.value
                    );
                    setSelectedSource(filter);
                  }}
                />
              </Tag>
            ))}
          </div>
        </div>
      </div>

      <EstimateUpload file={file} setFile={setFile}>
        <div className={`extra-field`}>
          <div className="flex-between heading">
            <CommonTextField
              text={"Attachments"}
              fontWeight={500}
              className={"light-text"}
            />
            <img
              src={Images.add}
              width={"20px"}
              height={"20px"}
              className="c-p"
            />
          </div>
        </div>
      </EstimateUpload>

      <div className="img-preview">
        {file?.map((data, index) => {
          return (
            <div key={data.uid} className="preview">
              {data.originFileObj && (
                <img
                  className="img"
                  src={URL.createObjectURL(data.originFileObj)}
                  alt={data.name}
                  style={{
                    width: 100,
                    height: 100,
                    objectFit: "cover",
                  }}
                />
              )}
              <div
                className="delete"
                onClick={() => {
                  const updatedFileList = file.filter(
                    (file) => file.uid !== data.uid
                  );
                  setFile(updatedFileList);
                }}
              >
                <img src={Images.crossImg} />
              </div>
            </div>
          );
        })}
      </div>
      <CommonModal
        destroyOnClose={true}
        title={<CommonHeading level={1} text={"Add Customer"} />}
        isModalVisible={AddCustomer}
        setIsModalVisible={setAddCustomer}
      >
        <AddNewCustomer
          onAddCustomer={onAddCustomer}
          setAddCustomer={setAddCustomer}
        />
      </CommonModal>
    </div>
  );
};

export default EstimateCustomer;
