import React from "react";
import { CommonTextField } from "../../../common";
import { Images } from "../../../../theme";
import "./styles.scss";
import { Col, Row } from "antd";

const addList = [
  "Add New Employee",
  "Add New Job",
  "Add New Estimate",
  "Add New Invoice",
  "Add New Customer",
];

const AddNew = ({ onWorker, onCustomer, onEstimate }) => {
  return (
    <Row gutter={[20, 20]}>
      {addList.map((t, i) => (
        <Col md={i >= addList.length - 1 ? 24 : 12} sm={24} xs={24}>
          <div
            className="add-new-card"
            onClick={
              i === 0
                ? onWorker
                : i === 4
                ? onCustomer
                : i === 2
                ? onEstimate
                : () => {}
            }
          >
            <CommonTextField fontWeight={500} text={t} />
            <img src={Images.plusIcon} />
          </div>
        </Col>
      ))}
      <Col md={24} sm={24} xs={24}>
        <div className="add-new-card rfp">
          <CommonTextField fontWeight={500} text={"Send RFP"} />
          <img src={Images.sendIcon} />
        </div>
      </Col>
    </Row>
  );
};

export default AddNew;
