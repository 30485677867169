import React from "react";
import "./styles.scss";
import { CommonTextField } from "../../../common";
import { settingList } from "../../../../constants";

const CompanyList = ({ onList, activeComponent }) => {
  return (
    <div className="company-list-parent">
      <CommonTextField className={"light-text"} text={"COMPANY"} />
      {settingList.slice(0, 6).map((t) => (
        <div
          className={`list-item ${activeComponent === t.key ? "active" : ""}`}
          key={t.key}
        >
          <CommonTextField text={t.name} onClick={() => onList(t.key)} />
        </div>
      ))}
    </div>
  );
};

export default CompanyList;
