import React from "react";
import { Header } from "../../components";

const PublicSharedLayout = ({ children }) => {
  return (
    <section className="pb-wrapper">
      {/* <Header /> */}
      {children}
    </section>
  );
};

export default PublicSharedLayout;
