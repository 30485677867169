import { take, put, call, fork } from "redux-saga/effects";
import {
  confirmOtpRequest,
  createCompanyRequest,
  createCompanySuccess,
  createProfileRequest,
  createProfileSuccess,
  forgetPasswordRequest,
  inviteUserRequest,
  loginRequest,
  loginSuccess,
  logoutRequest,
  resendOtpRequest,
  resetPasswordRequest,
  signupRequest,
  uploadImgRequest,
  userSignOutSuccess,
  verifyAccountRequest,
  approvePriceRequest,
} from "../slicers/user";
import { ALERT_TYPES } from "../../constants";
import {
  ADD_OPERATION_REQUEST,
  APPROVE_PRICE_REQUEST,
  callRequest,
  CONFIRM_OTP_REQUEST,
  CREATE_COMPANY_REQUEST,
  CREATE_PROFILE_REQUEST,
  FORFET_PASSWORD_REQUEST,
  INVITE_USER_REQUEST,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  RESEND_OTP_REQUEST,
  RESET_PASSWORD_REQUEST,
  SIGNUP_REQUEST,
  UPLOAD_IMG_REQUEST,
  VERIFY_ACCOUNT_REQUEST,
} from "../../config/web-service";
import { toastAlert } from "../../utils";

// USER LOGIN
function* login() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(loginRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        LOGIN_REQUEST,
        payloadData,
        "",
        "",
        {}
      );

      if (response.status) {
        if (!response.data.isWorker) {
          yield put(loginSuccess(response?.data));
        }
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* signup() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(signupRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        SIGNUP_REQUEST,
        payloadData,
        "",
        "",
        {}
      );

      if (response.status) {
        // yield put(userLoginSuccess(response?.data));
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* logout() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(logoutRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        LOGOUT_REQUEST,
        payloadData,
        "",
        "",
        {}
      );

      if (response.status) {
        yield put(userSignOutSuccess(response?.data));
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* confirmOtp() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(confirmOtpRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        CONFIRM_OTP_REQUEST,
        payloadData,
        "",
        "",
        {}
      );

      if (response.status) {
        // yield put(userLoginSuccess(response?.data));
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* createCompany() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(createCompanyRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        CREATE_COMPANY_REQUEST,
        payloadData,
        "",
        "",
        {}
      );

      if (response.status) {
        yield put(createCompanySuccess(response?.data));
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* createProfile() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(createProfileRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, parameter } = payload;
    try {
      const response = yield call(
        callRequest,
        CREATE_PROFILE_REQUEST,
        payloadData,
        parameter,
        "",
        {}
      );

      if (response.status) {
        yield put(createProfileSuccess(response?.data));
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* forgetPassword() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(forgetPasswordRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        FORFET_PASSWORD_REQUEST,
        payloadData,
        "",
        "",
        {}
      );

      if (response.status) {
        // yield put(userLoginSuccess(response?.data));
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* resetPassword() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(resetPasswordRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        RESET_PASSWORD_REQUEST,
        payloadData,
        "",
        "",
        {}
      );

      if (response.status) {
        // yield put(userLoginSuccess(response?.data));
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* uploadImg() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(uploadImgRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        UPLOAD_IMG_REQUEST,
        payloadData,
        "",
        "",
        {}
      );

      if (response.status) {
        // yield put(userLoginSuccess(response?.data));
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* resendOtp() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(resendOtpRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        RESEND_OTP_REQUEST,
        payloadData,
        "",
        "",
        {}
      );

      if (response.status) {
        // yield put(userLoginSuccess(response?.data));
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* inviteUser() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(inviteUserRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        INVITE_USER_REQUEST,
        payloadData,
        "",
        "",
        {}
      );
      if (response.status) {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* verifyAccount() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(verifyAccountRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, parameter } = payload;
    try {
      const response = yield call(
        callRequest,
        VERIFY_ACCOUNT_REQUEST,
        payloadData,
        parameter,
        "",
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* approvePrice() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(approvePriceRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, parameter } = payload;
    try {
      const response = yield call(
        callRequest,
        APPROVE_PRICE_REQUEST,
        payloadData,
        parameter,
        "",
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

export default function* root() {
  yield fork(login);
  yield fork(signup);
  yield fork(logout);
  yield fork(confirmOtp);
  yield fork(createCompany);
  yield fork(forgetPassword);
  yield fork(resetPassword);
  yield fork(createProfile);
  yield fork(uploadImg);
  yield fork(resendOtp);
  yield fork(inviteUser);
  yield fork(verifyAccount);
  yield fork(approvePrice);
}
