import { Form, Switch } from "antd";
import React from "react";
import "./styles.scss";

function CommonSwitch({ name }) {
  return (
    <Form.Item name={name} valuePropName="checked">
      <Switch />
    </Form.Item>
  );
}

export default CommonSwitch;
