import React, { useCallback, useState } from "react";
import { CommonInputField, CommonTextField } from "../../../..";
import { Checkbox, Col, Flex, Form, Input, InputNumber, Row } from "antd";

import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CommonButton,
  CommonSelect,
  CommonSwitch,
  CommonTextAreaField,
  ImageUploader,
} from "../../../../common";
import { uploadImgRequest } from "../../../../../redux/slicers/user";

import {
  createServiceRequest,
  createIndustryRequest,
  updateServiceRequest,
  updateIndustryRequest,
  updateTaxRequest,
  createMaterialMarkupRequest,
  updateMaterialMarkupRequest,
} from "../../../../../redux/slicers/priceBook";
import { useForm } from "antd/es/form/Form";
import { numberValidatorField, validatorField } from "../../../../../utils";

const AddMarkup = ({
  id = null,
  setId = () => {},
  setList = () => {},
  list = [],
  setCreateModal = () => {},
  setUpdateModal = () => {},
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { companyId } = useSelector((t) => t.user.data);

  const onFinish = (values) => {
    console.log("🚀 ~ onFinish ~ values:", values);
    setLoading(true);

    const { costFrom, costTo, markup, profit } = values;

    const payloadData = {
      costFrom: Number(costFrom),
      costTo: Number(costTo),
      markup: Number(markup),
      profit: Number(profit),
    };

    console.log(payloadData, "payloadData");

    if (id) {
      dispatch(
        updateMaterialMarkupRequest({
          payloadData,
          parameter: id,
          responseCallback: (res) => {
            if (res.status) {
              // let userCopy = [...list];
              // let index = userCopy.findIndex((user) => user.id === id);

              // if (index !== -1) {
              //   userCopy[index] = res.data;
              // }
              setList(res.data);
              setUpdateModal(false);
              setId(null);
            }
          },
        })
      );
    } else {
      dispatch(
        createMaterialMarkupRequest({
          payloadData: {
            ...payloadData,
            // companyId: companyId,
          },
          responseCallback: (res) => {
            console.log(res, "resresresres");
            if (res.status) {
              setList(res.data);
              setCreateModal(false);
            }
          },
        })
      );
    }
    setLoading(false);
  };

  const initialValues = useCallback(() => {
    const data = id && list.find((user) => user.id === id);
    const secondLast = list[list.length - 2];
    console.log("🚀 ~ initialValues ~ secondLast:", data);

    if (!id) {
      return {
        costFrom: secondLast?.costTo + 0.01,
        costTo: secondLast?.costTo + 0.01 + 99.99,
        markup: secondLast?.markup,
      };
    } else {
      return {
        costFrom: data.costFrom,
        costTo: data.costTo,
        markup: data?.markup,
        profit: data?.profit,
      };
    }
  }, []);

  console.log("🚀 ~ initialValues ~ initialValues:", initialValues());

  const [form] = Form.useForm();

  const numberValidatorField = (_, value) => {
    const costFrom = form.getFieldValue("costFrom");

    const check = () => {
      if (id) {
        let index = list.findIndex((user) => user.id === id);
        if (index !== -1) {
          let item = list[index + 1];
          if (value >= item.costTo && item.costTo !== 0) {
            return true;
          }
        }
      }
      return false;
    };

    if (value === undefined || !value) {
      return Promise.reject(new Error("Markup boundary cannot be empty"));
    } else if (value < costFrom || check()) {
      return Promise.reject(new Error("Markup boundaries cannot overlap"));
    } else if (`${value}`.toLowerCase().includes("e")) {
      return Promise.reject(new Error("Invalid Value."));
    } else {
      return Promise.resolve();
    }
  };

  return (
    <Form form={form} onFinish={onFinish} initialValues={initialValues()}>
      <Row gutter={[20, 0]}>
        <Col lg={12}>
          <CommonInputField
            disabled={true}
            type={"number"}
            name="costFrom"
            placeholder={"Cost From"}
          />
        </Col>
        <Col lg={12}>
          <CommonInputField
            rules={[
              {
                validator: numberValidatorField,
              },
            ]}
            type={"number"}
            name="costTo"
            placeholder={"Cost To"}
          />
        </Col>
        <Col lg={12}>
          <CommonInputField
            type={"number"}
            name="markup"
            placeholder={"Markup %"}
          />
        </Col>
        <Col lg={12}>
          <Form.Item
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.markup !== currentValues.markup
            }
            noStyle
          >
            {({ getFieldValue }) => {
              const costFrom = getFieldValue("costFrom") || 0;
              const markupPer = getFieldValue("markup") || 0;
              console.log("🚀 ~ costFrom:", costFrom + 20, markupPer);

              // const markupPer = 30
              const range = +costFrom + 20;

              const markupValue = range * (markupPer / 100); //= $200
              const sellingPrice = range + markupValue; //= $600

              const ProfitMarginPercentage =
                ((sellingPrice - range) / sellingPrice) * 100;

              console.log(
                "🚀 ~ initialValues ~ ProfitMarginPercentage:",
                ProfitMarginPercentage?.toFixed(2)
              );
              form.setFieldValue("profit", ProfitMarginPercentage?.toFixed(2));
              return (
                <CommonInputField
                  type={"number"}
                  name="profit"
                  placeholder={"Profit %"}
                />
              );
            }}
          </Form.Item>
        </Col>
      </Row>

      <div className="but-sec">
        <CommonButton
          width={"220px"}
          loading={loading}
          htmlType="submit"
          topClass={"small-but"}
          text={`${id ? "Update" : "Add"} Material Markup`}
        />
      </div>
    </Form>
  );
};

export default AddMarkup;
