import React, { useState } from "react";
import "./styles.scss";
import { Images } from "../../../../theme";
import { CommonTextField } from "../../../../components";

const Slider = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="slider">
      <SliderItem content={items[currentIndex]} />
      <div className="but-parent">
        <div className="arrow-but" onClick={handlePrev}>
          <img src={Images.backIcon} height={"17px"} width={"26px"} />
          <CommonTextField text={"Back"} fontSize={"18px"} />
        </div>
        <div className="arrow-but" onClick={handleNext}>
          <CommonTextField text={"Next"} fontSize={"18px"} />
          <img src={Images.nextIcon} height={"17px"} width={"26px"} />
        </div>
      </div>
    </div>
  );
};

export default Slider;

const SliderItem = ({ content }) => {
  const { title, description } = content;
  return (
    <div className="slider-item">
      <CommonTextField fontSize={"20px"} fontWeight={700} text={title} />
      <CommonTextField
        mt={"20px"}
        fontSize={"24px"}
        lineHeight={"30px"}
        text={description}
      />
    </div>
  );
};
