import { createSlice } from "@reduxjs/toolkit";

const general = createSlice({
  name: "general",
  initialState: {},
  reducers: {
    getTagRequest(state, action) {},
    createTagRequest(state, action) {},
    updateTagRequest(state, action) {},
    deleteTagRequest(state, action) {},
    mergeTagRequest(state, action) {},
    getAllTagRequest(state, action) {},
    getEstimateByIdRequest(state, action) {},
    createEstimateRequest(state, action) {},
    updateEstimateRequest(state, action) {},
    getAllEstimateRequest(state, action) {},
    getAllCustomerEstimateRequest(state, action) {},
    uploadMultiImgRequest() {},
    getMessageOrgRequest(state, action) {},
  },
});

export const {
  getTagRequest,
  createTagRequest,
  updateTagRequest,
  deleteTagRequest,
  mergeTagRequest,
  getAllTagRequest,
  getEstimateByIdRequest,
  createEstimateRequest,
  updateEstimateRequest,
  getAllEstimateRequest,
  getAllCustomerEstimateRequest,
  uploadMultiImgRequest,
  getMessageOrgRequest,
} = general.actions;

export default general.reducer;
