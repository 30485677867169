import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Industry from "./Industry";
import Category from "./Category";
import Service from "./Service";
import { Breadcrumb } from "antd";

const PriceBookMaterialComponent = () => {
  const [item, setItem] = useState([]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type = params.get("type");
  const bread = params.get("bread");

  const renderComponent = useCallback(() => {
    if (type == "category") {
      return <Category />;
    } else if (type == "service") {
      return <Service />;
    } else {
      return <Industry />;
    }
  }, [location]);

  useEffect(() => {
    let breadArray = bread?.split(",");
    setItem(breadArray?.map((t) => ({ title: t })));
  }, [type]);

  return (
    <div>
      <Breadcrumb separator=">" items={item} />
      {renderComponent()}
    </div>
  );
};

export default PriceBookMaterialComponent;
