import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import {
  CommonArrowBut,
  CommonButton,
  CommonDivider,
  CommonHeading,
  CommonInputField,
  CommonModal,
  CommonSelect,
  CommonTextAreaField,
  CommonTextField,
} from "../../../common";
import { Checkbox, Col, Form, Row, Space } from "antd";
import { numberValidatorField } from "../../../../utils";
import { Images } from "../../../../theme";
import {
  MATERIAL_PRICE_BOOK_LIST_ROUTE,
  SERVICE_PRICE_BOOK_LIST_ROUTE,
} from "../../../../constants";
import { useNavigate } from "react-router-dom";
import {
  addAllEstimateData,
  addAllMaterialServices,
  addAllServices,
  addServices,
  deleteMaterialServices,
  deleteServices,
  getAllTaxRequest,
} from "../../../../redux/slicers/priceBook";
import { useDispatch, useSelector } from "react-redux";
import { getMessageOrgRequest } from "../../../../redux/slicers/general";
import OrgMessageModal from "./OrgMessageModal";

const LineItem = ({
  estimateId,
  customerProfile,
  selectedSource,
  selectedTags,
  form,
  test,
  taxCalculate,
  setTax,
  file,
  recentActivity,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [taxList, setTaxList] = useState([]);
  const [orgMessageModal, setOrgMessageModal] = useState(false);
  const { companyId } = useSelector((t) => t.user.data);
  const { services } = useSelector((state) => state.priceBook);

  useEffect(() => {
    dispatch(
      getAllTaxRequest({
        payloadData: {},
        parameter: companyId,
        responseCallback: (res) => {
          console.log("🚀 ~ useEffect ~ res:", res);
          if (res.status) {
            setTaxList(res.data);
          } else {
            console.log(res.errors, "error");
          }
        },
      })
    );

    // dispatch(
    //   getMessageOrgRequest({
    //     payloadData: {},
    //     parameter: companyId,
    //     responseCallback: (res) => {
    //       console.log("🚀 ~ useEffect ~ res:", res);
    //       if (res.status) {
    //         form.setFieldsValue({
    //           orgMessage: res.data.messageInvoiceReceiptEstimate,
    //         });
    //       } else {
    //         console.log(res.errors, "error");
    //       }
    //     },
    //   })
    // );
  }, []);

  console.log(form.getFieldValue("orgMessage"), "OrgNMessage");

  return (
    <>
      <div className="line-section common-container">
        <div className="flex-between">
          <CommonTextField
            className={"text-18 light-text"}
            fontWeight={600}
            text={"Line Item"}
          />
          <CommonButton
            topClass={"small-but"}
            onClick={() => {
              const detail = form.getFieldsValue();
              console.log("🚀 ~ detail:", detail);

              dispatch(
                addAllEstimateData({
                  ...detail,
                  selectedTags,
                  selectedSource,
                  customerProfile,
                  estimateId,
                  update: true,
                  file,
                  recentActivity,
                })
              );

              navigate(SERVICE_PRICE_BOOK_LIST_ROUTE);
            }}
            text={"Service Price Book"}
          />
        </div>
        <CommonTextField text={"Services"} mt={"10px"} mb={"10px"} />
        <Form.List name="services">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => {
                return (
                  <>
                    <div key={key} className="address-container">
                      <Row gutter={[10, 0]}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                          <CommonInputField
                            name={[name, "serviceName"]}
                            field={{ ...restField }}
                            placeholder={"Service name"}
                          />
                        </Col>
                        <Col xs={24} sm={10} md={4} lg={4}>
                          <CommonInputField
                            name={[name, "qty"]}
                            field={{ ...restField }}
                            placeholder={"Qty"}
                            type={"number"}
                          />
                        </Col>
                        <Col xs={24} sm={10} md={4} lg={4}>
                          <CommonInputField
                            name={[name, "unitPrice"]}
                            field={{ ...restField }}
                            type={"number"}
                            placeholder={"Unit Price"}
                            rules={[
                              {
                                validator: (_, value) => {
                                  return numberValidatorField(_, value);
                                },
                              },
                            ]}
                          />
                        </Col>
                        <Col lg={4}>
                          <Form.Item
                            shouldUpdate={(prevValues, currentValues) =>
                              prevValues.services !== currentValues.services
                            }
                            noStyle
                          >
                            {({ getFieldValue }) => {
                              const unitPrice =
                                getFieldValue([
                                  "services",
                                  name,
                                  "unitPrice",
                                ]) || 0;
                              const qty =
                                getFieldValue(["services", name, "qty"]) || 0;
                              const calculate = unitPrice * qty;

                              return (
                                <div className="count">
                                  <CommonTextField
                                    color={"#96a0ad"}
                                    text={`$${calculate.toFixed(2)}`}
                                  />
                                </div>
                              );
                            }}
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                          <CommonInputField
                            name={[name, "description"]}
                            field={{ ...restField }}
                            placeholder={"Description"}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                          <CommonInputField
                            name={[name, "unitCost"]}
                            field={{ ...restField }}
                            placeholder={"Unit Cost"}
                          />
                        </Col>
                        <Col>
                          <Space align="baseline">
                            <Form.Item
                              name={[name, "taxable"]}
                              field={{ ...restField }}
                              valuePropName="checked"
                              initialValue={false}
                            >
                              <Checkbox />
                            </Form.Item>
                            <CommonTextField
                              text="Taxable"
                              className={"light-text"}
                              fontWeight={500}
                            />
                          </Space>
                        </Col>
                      </Row>
                      {fields.length > 1 ? (
                        <div
                          className="delete-option"
                          onClick={() => {
                            remove(name);
                            dispatch(deleteServices(key));
                          }}
                        >
                          <img src={Images.deleteIcon} />
                        </div>
                      ) : null}
                    </div>

                    <CommonDivider className={"address-divider"} />
                  </>
                );
              })}
              <div className="flex-between address-text">
                <CommonTextField
                  text={"Add Service"}
                  className={"theme-text"}
                  fontWeight={500}
                />
                <img src={Images.add} onClick={() => add()} />
              </div>
              <CommonDivider />
            </>
          )}
        </Form.List>

        <div className="flex-between mt-15 mb-15">
          <CommonTextField text={"Material"} />
          <CommonButton
            topClass={"small-but"}
            onClick={() => {
              const detail = form.getFieldsValue();

              dispatch(
                addAllEstimateData({
                  ...detail,
                  selectedTags,
                  selectedSource,
                  customerProfile,
                  estimateId,
                  update: true,
                  file,
                  recentActivity,
                })
              );

              navigate(MATERIAL_PRICE_BOOK_LIST_ROUTE);
            }}
            text={"Material Price Book"}
          />
        </div>
        <Form.List name="material">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <>
                  <div key={key} className="address-container">
                    <Row gutter={[10, 0]}>
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <CommonInputField
                          name={[name, "materialName"]}
                          field={{ ...restField }}
                          placeholder={"Material name"}
                        />
                      </Col>
                      <Col xs={24} sm={10} md={4} lg={4}>
                        <CommonInputField
                          name={[name, "qty"]}
                          field={{ ...restField }}
                          placeholder={"Qty"}
                          type={"number"}
                        />
                      </Col>
                      <Col xs={24} sm={10} md={4} lg={4}>
                        <CommonInputField
                          name={[name, "unitPrice"]}
                          field={{ ...restField }}
                          type={"number"}
                          placeholder={"Unit Price"}
                          rules={[
                            {
                              validator: (_, value) => {
                                return numberValidatorField(_, value);
                              },
                            },
                          ]}
                        />
                      </Col>
                      <Col lg={4}>
                        <Form.Item
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.material !== currentValues.material
                          }
                          noStyle
                        >
                          {({ getFieldValue }) => {
                            const unitPrice =
                              getFieldValue(["material", name, "unitPrice"]) ||
                              0;
                            const qty =
                              getFieldValue(["material", name, "qty"]) || 0;
                            const calculate = unitPrice * qty;

                            return (
                              <div className="count">
                                <CommonTextField
                                  color={"#96a0ad"}
                                  text={`$${calculate.toFixed(2)}`}
                                />
                              </div>
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <CommonInputField
                          name={[name, "description"]}
                          field={{ ...restField }}
                          placeholder={"Description"}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <CommonInputField
                          name={[name, "unitCost"]}
                          field={{ ...restField }}
                          placeholder={"Unit Cost"}
                          type={"number"}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <CommonInputField
                          name={[name, "partNumber"]}
                          field={{ ...restField }}
                          type={"number"}
                          placeholder={"Part Number"}
                        />
                      </Col>
                      <Col>
                        <Space align="baseline">
                          <Form.Item
                            name={[name, "taxable"]}
                            field={{ ...restField }}
                            valuePropName="checked"
                            initialValue={false}
                          >
                            <Checkbox />
                          </Form.Item>
                          <CommonTextField
                            text="Taxable"
                            className={"light-text"}
                            fontWeight={500}
                          />
                        </Space>
                      </Col>
                    </Row>
                    {fields.length > 1 ? (
                      <div
                        className="delete-option"
                        onClick={() => {
                          remove(name);
                          dispatch(deleteMaterialServices(key));
                        }}
                      >
                        <img src={Images.deleteIcon} />
                      </div>
                    ) : null}
                  </div>

                  <CommonDivider className={"address-divider"} />
                </>
              ))}
              <div className="flex-between address-text">
                <CommonTextField
                  text={"Add Material"}
                  className={"theme-text"}
                  fontWeight={500}
                />
                <img src={Images.add} onClick={() => add()} />
              </div>
              <CommonDivider />
            </>
          )}
        </Form.List>
      </div>

      <div className="total-parent">
        <div className="total">
          <CommonTextField
            className={"text-16 light-text"}
            text={"Sub Total"}
            fontWeight={600}
          />
          <Form.Item
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.material !== currentValues.material ||
              prevValues.services !== currentValues.services
            }
            noStyle
          >
            {({ getFieldValue }) => {
              const grandTotal = test();

              return (
                <CommonTextField
                  className={"light-text"}
                  text={`$${grandTotal.toFixed(2)}`}
                />
              );
            }}
          </Form.Item>
        </div>
        <CommonDivider />
        <div className="total">
          <CommonTextField
            className={"text-16 light-text"}
            text={"Tax rate"}
            fontWeight={600}
          />
          <div style={{ width: "130px" }}>
            <CommonSelect
              name="taxRate"
              placeholder={"none"}
              className={"tax-select"}
              options={taxList.map((t) => ({
                label: `${t.description} (${t.rate}%)`,
                value: t.rate,
              }))}
            />
          </div>
        </div>
        <CommonDivider />
        <div className="total">
          <CommonTextField
            className={"text-16 light-text"}
            text={"Total Amount"}
            fontWeight={600}
          />
          <Form.Item
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.material !== currentValues.material ||
              prevValues.services !== currentValues.services ||
              prevValues.taxRate !== currentValues.taxRate
            }
            noStyle
          >
            {({}) => {
              const grandTotal = taxCalculate();
              console.log("🚀 ~ LineItem ~ grandTotal:", grandTotal);

              return (
                <CommonTextField
                  className={"light-text"}
                  text={`$${grandTotal.toFixed(2)}`}
                />
              );
            }}
          </Form.Item>
        </div>
      </div>

      <div className="common-container message">
        <div className="flex-between">
          <CommonTextField
            className={"text-16 light-text"}
            text={"Message from ORG"}
            fontWeight={600}
          />
          <CommonArrowBut
            width={"18px"}
            height={"18px"}
            src={Images.editIcon}
            onClick={() => {
              setOrgMessageModal(true);
            }}
          />
        </div>
        <CommonTextField
          mt={"10px"}
          className={"light-text"}
          text={form.getFieldValue("orgMessage")}
        />
      </div>
      <Form.Item name="orgMessage">{/* Message for org*/}</Form.Item>

      <CommonButton
        topClass={"estimate-btn"}
        text={"Save Estimate"}
        // onClick={() => form.submit()}
        htmlType="submit"
      />
      <CommonModal
        destroyOnClose={true}
        title={<CommonHeading level={1} text={"Estimate message"} />}
        isModalVisible={orgMessageModal}
        setIsModalVisible={setOrgMessageModal}
      >
        <OrgMessageModal form={form} setOrgMessageModal={setOrgMessageModal} />
      </CommonModal>
    </>
  );
};

export default LineItem;
