// @flow
import _ from "lodash";
import { createSlice, current } from "@reduxjs/toolkit";

const employee = createSlice({
  name: "employee",
  initialState: {
    data: {},
  },
  reducers: {
    getEmployeeRequest(state, action) {},
    createEmployeeRequest(state, action) {},
    updateEmployeeRequest(state, action) {},
    deleteEmployeeRequest(state, action) {},
    getUnverifiedEmployeeRequest(state, action) {},
    approvedEmployeeRequest(state, action) {},
    rejectEmployeeRequest(state, action) {},
  },
});

export const {
  getEmployeeRequest,
  createEmployeeRequest,
  updateEmployeeRequest,
  deleteEmployeeRequest,
  getUnverifiedEmployeeRequest,
  approvedEmployeeRequest,
  rejectEmployeeRequest,
} = employee.actions;

export default employee.reducer;
