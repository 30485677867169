import { createSlice } from "@reduxjs/toolkit";

const UserReducer = createSlice({
  name: "user",
  initialState: {
    data: {},
    isAuthenticated: false,
    userProfile: null,
  },
  reducers: {
    // USER LOGIN
    loginRequest() {},
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.data = action.payload;
    },
    signupRequest() {},

    confirmOtpRequest() {},
    resendOtpRequest() {},

    uploadImgRequest() {},

    createCompanyRequest() {},
    createCompanySuccess(state, action) {
      state.isAuthenticated = true;
      state.data = action.payload;
    },

    createProfileRequest() {},
    createProfileSuccess(state, action) {
      state.isAuthenticated = true;
      state.data = action.payload;
    },

    forgetPasswordRequest() {},
    resetPasswordRequest() {},

    // USER SIGNOUT
    logoutRequest(state, action) {},
    userSignOutSuccess(state, action) {
      state.isAuthenticated = false;
      state.data = {};
    },

    inviteUserRequest(state, action) {},
    verifyAccountRequest() {},
    approvePriceRequest() {},
  },
});

export const {
  loginRequest,
  loginSuccess,
  logoutRequest,
  userSignOutSuccess,
  signupRequest,
  confirmOtpRequest,
  createCompanyRequest,
  forgetPasswordRequest,
  resetPasswordRequest,
  createProfileRequest,
  createProfileSuccess,
  createCompanySuccess,
  uploadImgRequest,
  resendOtpRequest,
  inviteUserRequest,
  verifyAccountRequest,
  approvePriceRequest,
} = UserReducer.actions;

export default UserReducer.reducer;
