import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  CommonButton,
  CommonCard,
  CommonDivider,
  CommonHeading,
  CommonInputField,
  CommonModal,
  CommonPagination,
  CommonTable,
  CommonTextField,
  CommonThreeDotMenu,
} from "../../../common";
import { Images } from "../../../../theme";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AddMarkup from "./AddMarkup";
import { Flex, Space } from "antd";
import { SERVICE_DETAIL_ROUTE } from "../../../../constants";
import {
  deleteMaterialMarkupRequest,
  deleteServiceRequest,
  deleteTaxRequest,
  getMaterialMarkupRequest,
  getServiceRequest,
  getTaxRequest,
} from "../../../../redux/slicers/priceBook";

const MaterialMarkup = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const { companyId } = useSelector((t) => t.user.data);

  const searchRef = useRef();
  const [offSet, setOffSet] = useState(0);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onDelete = (id) => {
    setId(id);
    setDeleteModal(true);
  };

  const onEdit = (id) => {
    console.log("🚀 ~ onEdit ~ id:", id);
    setId(id);
    setUpdateModal(true);
  };

  const callRequest = (search = "") => {
    dispatch(
      getMaterialMarkupRequest({
        payloadData: {},
        query: `limit=10&offset=${offSet}&search=${search}&id=${companyId}`,
        responseCallback: (res) => {
          console.log("🚀 ~ useEffect ~ res:", res);
          setIsLoading(false);
          if (res.status) {
            console.log(res, "res");
            setList(res.data.map((t, i) => ({ ...t, key: i })));
            setTotal(res.count);
          } else {
            console.log(res.errors, "error");
          }
        },
      })
    );
  };

  useEffect(() => {
    callRequest();
  }, [offSet]);

  const handleSearch = (e) => {
    if (searchRef.current) clearTimeout(searchRef.current);

    searchRef.current = setTimeout(() => {
      callRequest(e.target.value);
    }, 300);
  };

  const handlePagination = (e) => {
    console.log(e, "pagination");
    let test = (e - 1) * 10;
    setOffSet(test);
  };

  const renderColumnName = useCallback((text, data) => {
    console.log(text, data, "data data");
    return (
      <CommonTextField
        className={"light-text"}
        text={`Markup ${data.key + 1}`}
      />
    );
  }, []);

  const renderColumn = useCallback(
    (text) => <CommonTextField className={"light-text"} text={text} />,
    []
  );

  const renderPerColumn = useCallback(
    (text) => <CommonTextField className={"light-text"} text={`${text}%`} />,
    []
  );

  const renderNumColumn = useCallback((text, data) => {
    console.log(data.costTo, "data");
    return (
      <CommonTextField
        className={"light-text"}
        text={data?.costTo === 0 ? "$ -" : `$${text.toFixed(2)}`}
      />
    );
  }, []);

  const renderFromNumColumn = useCallback(
    (text) => (
      <CommonTextField className={"light-text"} text={`$${text.toFixed(2)}`} />
    ),
    []
  );

  const renderActionColumn = useCallback(
    (_, { id }) => (
      <div className="action-parent">
        <img
          width={"18px"}
          height={"18px"}
          src={Images.editIcon}
          onClick={() => onEdit(id)}
        />
        <img src={Images.deleteIcon} onClick={() => onDelete(id)} />
      </div>
    ),
    []
  );

  const columns = [
    {
      title: "Markups",
      dataIndex: "description",
      key: "description",
      render: renderColumnName,
    },
    {
      title: "Cost From",
      dataIndex: "costFrom",
      key: "costFrom",
      render: renderFromNumColumn,
    },
    {
      title: "Cost To",
      dataIndex: "costTo",
      key: "costTo",
      render: renderNumColumn,
    },

    {
      title: "Markup %",
      dataIndex: "markup",
      key: "markup",
      render: renderPerColumn,
    },

    {
      title: "Profit %",
      dataIndex: "profit",
      key: "profit",
      render: renderPerColumn,
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: renderActionColumn,
    },
  ];

  return (
    <div className="common-container">
      <div className="search-parent">
        <CommonInputField
          onChange={handleSearch}
          name="search"
          className="small-input"
          placeholder={"search..."}
          suffix={<img src={Images.searchIcon} />}
        />
        <CommonButton
          topClass={"small-but"}
          text={"Add Material Markup"}
          onClick={() => {
            setCreateModal(true);
            setId(null);
          }}
        />
      </div>

      <CommonTable
        width={400}
        total={total}
        // handlePagination={handlePagination}
        columns={columns}
        dataSource={list}
        loading={isLoading}
      />

      <CommonModal
        destroyOnClose={true}
        title={
          <CommonHeading
            level={1}
            text={`${updateModal ? "Update" : "Add"} Material markup`}
          />
        }
        isModalVisible={createModal || updateModal}
        setIsModalVisible={updateModal ? setUpdateModal : setCreateModal}
      >
        <AddMarkup
          id={id}
          setId={setId}
          setList={setList}
          list={list}
          setCreateModal={setCreateModal}
          setUpdateModal={setUpdateModal}
        />
      </CommonModal>
      <CommonModal
        isModalVisible={deleteModal}
        setIsModalVisible={setDeleteModal}
        discription="Do you want to delete Material markup?"
        // loading={isLoading}
        onConfirm={() => {
          dispatch(
            deleteMaterialMarkupRequest({
              payloadData: {},
              parameter: id,
              responseCallback: (res) => {
                if (res.status) {
                  // let listCopy = [...list];
                  // let index = listCopy.findIndex((user) => user.id === id);
                  // if (index !== -1) {
                  //   listCopy.splice(index, 1);
                  // }
                  setList(res.data);
                  setDeleteModal(false);
                  setId(null);
                } else {
                  console.log(res.errors, "error");
                }
              },
            })
          );
        }}
      ></CommonModal>
    </div>
  );
};

export default MaterialMarkup;
