import { Form, Select } from "antd";
import React, { useState } from "react";
import "./styles.scss";

const CommonSelect = ({
  showSearch = false,
  filterOption,
  name,
  rules,
  options,
  placeholder,
  defaultValue,
  className,
  prefix,
  form,
  onSelect = () => {},
  mode = "none",
  disabled = false,
  onDeselect,
}) => {
  return (
    <Form.Item
      name={name}
      rules={
        rules
          ? rules
          : [
              {
                required: true,
                message: "Field is required.",
              },
            ]
      }
      style={{ width: "100%" }}
    >
      <Select
        onDeselect={onDeselect}
        showSearch={showSearch}
        filterOption={filterOption}
        defaultValue={defaultValue}
        mode={mode}
        className={`${className ? className : "common-select"}`}
        placeholder={placeholder}
        options={options}
        disabled={disabled}
        onSelect={onSelect}
      />
    </Form.Item>
  );
};

export default CommonSelect;
