import React, { useState } from "react";
import { CommonAuthLayout, CommonInputField } from "../../../components";
import { EMAIL_RULE, validatorField } from "../../../utils";
import { Form } from "antd";
import { CommonButton, ImageUploader } from "../../../components/common";
import { useLocation, useNavigate } from "react-router-dom";
import { Images } from "../../../theme";
import { useDispatch } from "react-redux";
import { createProfileRequest } from "../../../redux/slicers/user";
import { DASHBOARD_ROUTE } from "../../../constants";

const ProfileSetup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { userID } = location.state;

  const onFinish = (values) => {
    setLoading(true);
    console.log("🚀 ~ onFinish ~ values:", values);
    const { supportEmail, licenseNumber } = values;

    const payloadData = {
      supportEmail: supportEmail,
      licenseNumber: licenseNumber,
    };

    dispatch(
      createProfileRequest({
        payloadData,
        parameter: userID,
        responseCallback: (res) => {
          if (res.status) {
            navigate(DASHBOARD_ROUTE);
          }
          console.log("🚀 ~ onFinish ~ res:", res);
          setLoading(false);
        },
      })
    );
  };

  return (
    <CommonAuthLayout heading={"Setup your Profile"} patternPosition={"right"}>
      <Form onFinish={onFinish}>
        <CommonInputField
          name="supportEmail"
          type={"email"}
          placeholder={"Support Email"}
          rules={EMAIL_RULE}
        />
        <CommonInputField
          name="licenseNumber"
          placeholder={"License Number"}
          type={"number"}
        />

        <CommonButton loading={loading} htmlType="submit" text={"Continue"} />
      </Form>
    </CommonAuthLayout>
  );
};

export default ProfileSetup;
