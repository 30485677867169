import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CommonButton,
  CommonDivider,
  CommonDropdown,
  CommonHeading,
  CommonInputField,
  CommonModal,
  CommonTextField,
  CommonThreeDotMenu,
} from "../../../components/common";
import CommonTable from "../../../components/common/CommonTable";
import { Images } from "../../../theme";
import {
  ALERT_TYPES,
  CUSTOMER_DETAIL_ROUTE,
  startFilter,
} from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  customerManipulator,
  WorkerManipulator,
} from "../../../data-manipulator/user";
import "./styles.scss";
import { toastAlert } from "../../../utils";
import {
  deleteStockRequest,
  getStockRequest,
} from "../../../redux/slicers/customer";
import { CreateCustomer } from "../../../components";
import { useNavigate } from "react-router-dom";

const Customer = () => {
  const [stockList, setStockList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteStock, setDeleteStock] = useState(false);
  const [stockId, setStockId] = useState(null);
  const [CreateStockModal, setCreateStockModal] = useState(false);
  const [stockUpdateModal, setStockUpdateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { companyId } = useSelector((t) => t.user.data);

  const searchRef = useRef();
  const [offSet, setOffSet] = useState(0);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const filter = useSelector((state) => state?.stocks?.filter);

  const dispatch = useDispatch();

  const onDelete = (id) => {
    setStockId(id);
    setDeleteStock(true);
  };

  const onEdit = (id) => {
    console.log("🚀 ~ onEdit ~ id:", id);
    setStockId(id);
    setStockUpdateModal(true);
  };

  const callRequest = (search = "") => {
    setLoading(true);

    dispatch(
      getStockRequest({
        payloadData: {},
        query: `limit=10&offset=${offSet}&search=${search}&id=${companyId}`,
        responseCallback: (res) => {
          console.log("🚀 ~ useEffect ~ res:", res);
          setIsLoading(false);
          if (res.status) {
            console.log(res, "res");
            setStockList(customerManipulator(res.data));
            setTotal(res.count);
            setLoading(false);
          } else {
            console.log(res.errors, "error");
            setLoading(false);
          }
        },
      })
    );
  };

  useEffect(() => {
    callRequest();
  }, [offSet]);

  const handleSearch = (e) => {
    if (searchRef.current) clearTimeout(searchRef.current);

    searchRef.current = setTimeout(() => {
      callRequest(e.target.value);
    }, 300);
  };

  const handlePagination = (e) => {
    console.log(e, "pagination");
    let test = (e - 1) * 10;
    setOffSet(test);
  };

  const renderColumn = useCallback(
    (text) => <CommonTextField className={"light-text"} text={text} />,
    []
  );

  const renderActionColumn = useCallback(
    (_, { id }) => (
      <div className="action-parent">
        <CommonButton
          width={"120px"}
          text={"View Details"}
          topClass={"small-but"}
          onClick={() => navigate(CUSTOMER_DETAIL_ROUTE.replace(":id", id))}
        />
        <CommonThreeDotMenu
          onEdit={() => onEdit(id)}
          onDelete={() => onDelete(id)}
        />
      </div>
    ),
    []
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "name",
      render: renderColumn,
    },
    {
      title: "Address",
      dataIndex: "streetAddress",
      key: "address",
      render: renderColumn,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: renderColumn,
    },
    {
      title: "Phone",
      dataIndex: "mobilePhone",
      key: "phone",
      render: renderColumn,
    },
    {
      title: "Customer Type",
      dataIndex: "customerType",
      key: "type",
      render: renderColumn,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: renderActionColumn,
    },
  ];

  return (
    <div className="customer-parent">
      <div className="search-section">
        <CommonTextField text={"Customers"} fontWeight={600} />
        <div className="search-parent">
          <CommonInputField
            onChange={handleSearch}
            name="search"
            className="small-input"
            placeholder={"search..."}
            suffix={<img src={Images.searchIcon} />}
          />
          <CommonButton
            topClass={"small-but"}
            width={"140px"}
            text={"Add Customer"}
            onClick={() => {
              setCreateStockModal(true);
              setStockId(null);
            }}
          />
        </div>
      </div>
      <CommonTable
        total={total}
        handlePagination={handlePagination}
        columns={columns}
        dataSource={stockList}
        loading={isLoading}
      />
      <CommonModal
        destroyOnClose={true}
        title={
          <CommonHeading
            fontSize={"24px"}
            text={stockUpdateModal ? "Update Customer" : "Add Customer"}
          />
        }
        isModalVisible={CreateStockModal || stockUpdateModal}
        setIsModalVisible={
          stockUpdateModal ? setStockUpdateModal : setCreateStockModal
        }
      >
        <CreateCustomer
          stockId={stockId}
          setStockId={setStockId}
          setStockList={setStockList}
          stockList={stockList}
          setCreateStockModal={setCreateStockModal}
          setStockUpdateModal={setStockUpdateModal}
        />
      </CommonModal>
      <CommonModal
        isModalVisible={deleteStock}
        setIsModalVisible={setDeleteStock}
        discription="Do you want to delete customer?"
        // loading={isLoading}
        onConfirm={() => {
          dispatch(
            deleteStockRequest({
              payloadData: {},
              parameter: stockId,
              responseCallback: (res) => {
                if (res.status) {
                  let userCopy = [...stockList];
                  let index = userCopy.findIndex((user) => user.id === stockId);
                  if (index !== -1) {
                    userCopy.splice(index, 1);
                  }
                  toastAlert("Delete Worker successfully", ALERT_TYPES.SUCCESS);
                  setStockList(userCopy);
                  setDeleteStock(false);
                  setStockId(null);
                } else {
                  console.log(res.errors, "error");
                }
              },
            })
          );
        }}
      ></CommonModal>
    </div>
  );
};

export default Customer;
