import { combineReducers } from "@reduxjs/toolkit";

import general from "./general";
import user from "./user";
import userProfile from "./userProfile";
import customer from "./customer";
import employee from "./employee";
import leadSource from "./leadSource";
import priceBook from "./priceBook";

export default combineReducers({
  general,
  user,
  userProfile,
  customer,
  employee,
  leadSource,
  priceBook,
});
