import React from "react";
import { Images } from "../../../theme";
import "./styles.scss";
import { Row } from "antd";
import { CommonHeading, CommonTextField } from "../../../components";
import {
  CREATE_WORKER_PROFILE,
  CREATE_ORG_PROFILE,
  HOME_ROUTE,
} from "../../../constants";
import { useNavigate } from "react-router-dom";

const SelectUser = () => {
  const navigate = useNavigate();
  const onUserCard = (url) => {
    navigate(url);
  };
  return (
    <div className="select-user-parent">
      <img
        className="logo c-p"
        src={Images.mainLogo}
        onClick={() => navigate(HOME_ROUTE)}
      />
      <div className="select-user-content">
        <div
          className="user-card"
          onClick={() => onUserCard(CREATE_ORG_PROFILE)}
        >
          <CommonHeading
            className={"white-text card-text"}
            text={"Organization"}
          />
          <div className="img-parent">
            <img src={Images.user1} />
          </div>
        </div>
        <div
          className="user-card"
          onClick={() => onUserCard(CREATE_WORKER_PROFILE)}
        >
          <CommonHeading className={"white-text card-text"} text={"Worker"} />
          <div className="img-parent">
            <img src={Images.user2} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectUser;
