import ErrorThumbnail from "../assets/images/error-thumb.svg";
import authPattern from "../assets/auth/authPattern.png";
import mainLogo from "../assets/images/mainLogo.png";
import backArrow from "../assets/images/backArrow.png";
import cameraIcon from "../assets/images/cameraIcon.png";
import editIcon from "../assets/images/editIcon.png";
import user1 from "../assets/auth/user1.png";
import user2 from "../assets/auth/user2.png";
import locationIcon from "../assets/images/locationIcon.png";
import messageIcon from "../assets/images/messageIcon.png";
import avatar from "../assets/images/avatar.png";
import rightArrow from "../assets/images/rightArrow.png";
import searchIcon from "../assets/images/searchIcon.png";
import ThreeDots from "../assets/images/threeDot.png";
import avatar1 from "../assets/images/avatar1.png";
import plusIcon from "../assets/images/plus.png";
import sendIcon from "../assets/images/sendIcon.png";
import add from "../assets/images/add.png";
import deleteIcon from "../assets/images/delete.png";
import checkIcon from "../assets/images/checkIcon.png";
import backIconBlack from "../assets/images/backIcon.png";
import { ReactComponent as DashboardIcon } from "../assets/images/dashboardIcon.svg";
import { ReactComponent as ScheduleIcon } from "../assets/images/scheduleIcon.svg";
import { ReactComponent as CustomerIcon } from "../assets/images/customerIcon.svg";
import { ReactComponent as SettingIcon } from "../assets/images/settingIcon.svg";
import sideBarIcon from "../assets/images/sideBar.svg";

import headPhone from "../assets/landingPage/headPhone.png";
import serviceIcon1 from "../assets/landingPage/serviceIcon1.png";
import serviceIcon3 from "../assets/landingPage/serviceIcon3.png";
import serviceIcon2 from "../assets/landingPage/serviceIcon2.png";
import serviceIcon4 from "../assets/landingPage/serviceIcon4.png";
import serviceIcon5 from "../assets/landingPage/serviceIcon5.png";

import serviceIcon6 from "../assets/landingPage/serviceIcon6.png";
import aboutImg from "../assets/landingPage/aboutImg.png";
import drawerIcon from "../assets/landingPage/drawerIcon.png";
import fbIcon from "../assets/landingPage/fbLogo.png";
import linkdinIcon from "../assets/landingPage/linkdinLogo.png";
import twitterIcon from "../assets/landingPage/twitterLogo.png";
import experience from "../assets/landingPage/experience.png";
import designIcon from "../assets/landingPage/designIcon.png";
import crossImg from "../assets/images/cross-img.png";

import backIcon from "../assets/landingPage/backArrow.png";
import nextIcon from "../assets/landingPage/nextArrow.png";

import waitingImg from "../assets/auth/waitingImg.png";
import crossIcon from "../assets/images/cross.png";
import industryImg from "../assets/images/industry.png";
import addIcon from "../assets/images/plusIcon.png";
import minusIcon from "../assets/images/minusIcon.png";

export default {
  ErrorThumbnail,
  authPattern,
  mainLogo,
  backArrow,
  cameraIcon,
  editIcon,
  user1,
  user2,
  locationIcon,
  messageIcon,
  avatar,
  rightArrow,
  searchIcon,
  ThreeDots,
  avatar1,
  headPhone,
  serviceIcon1,
  serviceIcon2,
  serviceIcon3,
  serviceIcon4,
  serviceIcon5,
  serviceIcon6,
  aboutImg,
  drawerIcon,
  waitingImg,
  fbIcon,
  linkdinIcon,
  twitterIcon,
  experience,
  designIcon,
  backIcon,
  nextIcon,
  plusIcon,
  sendIcon,
  add,
  deleteIcon,
  checkIcon,
  backIconBlack,
  DashboardIcon,
  ScheduleIcon,
  CustomerIcon,
  SettingIcon,
  sideBarIcon,
  crossIcon,
  crossImg,
  industryImg,
  addIcon,
  minusIcon,
};
