import React from "react";
import { Calendar } from "../../../components";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!

const Schedule = () => {
  return (
    <div>
      <Calendar
        servicesList={[]}
        plugins={[dayGridPlugin]}
        serviceClickHandler={() => {}}
        serviceTypeTitle={"asdas"}
      />
    </div>
  );
};

export default Schedule;
