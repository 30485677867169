import React, { useCallback, useState } from "react";
import {
  CommonAuthLayout,
  CommonInputField,
  CommonTextField,
} from "../../../..";
import { EMAIL_RULE, phoneValidation } from "../../../../../utils";
import { Col, Form, Row } from "antd";
import {
  COMPANY_SETUP,
  EMAIL_VERIFICATION,
  LOGIN_ROUTE,
  WeekDays,
  EmployeeTypeOption,
  EMPLOYEE_TYPE,
} from "../../../../../constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CommonButton,
  CommonDivider,
  CommonSelect,
  CommonSwitch,
} from "../../../../common";
import { inviteUserRequest } from "../../../../../redux/slicers/user";
// import "./styles.scss";
import {
  commonTableManipulator,
  employeeManipulator,
} from "../../../../../data-manipulator/user";
import {
  createTagRequest,
  mergeTagRequest,
} from "../../../../../redux/slicers/general";

const AddLead = ({
  id = null,
  setId = () => {},
  setList = () => {},
  list = [],
  setCreateModal = () => {},
  setUpdateModal = () => {},
  merge,
  setMerge,
  setTotal,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { companyId } = useSelector((t) => t.user.data);

  const onFinish = (values) => {
    setLoading(true);

    console.log("🚀 ~ onFinish ~ values:", values);

    const { tag } = values;

    const payloadData = {
      name: tag,
      companyId: companyId,
    };

    if (id) {
      // dispatch(
      //   inviteUserRequest({
      //     payloadData,
      //     responseCallback: (res) => {
      //       setLoading(false);
      //       if (res.status) {
      // let userCopy = [...list];
      // let index = userCopy.findIndex((user) => user.id === id);
      // if (index !== -1) {
      //   userCopy[index] = employeeManipulator([payloadData])[0];
      // }
      // setList(userCopy);
      // setUpdateModal(false);
      // setId(null);
      //       }
      //     },
      //   })
      // );
    } else if (merge?.length > 0) {
      const mergePayloadData = { ...payloadData, ids: merge.map((t) => t.id) };
      dispatch(
        mergeTagRequest({
          payloadData: mergePayloadData,
          responseCallback: (res) => {
            if (res.status) {
              let listCopy = [...list];
              let filterData = listCopy.filter(
                (user) => !merge.find((t) => t.id === user.id)
              );
              let newData = commonTableManipulator([res.data]);
              setList(() => [newData[0], ...filterData]);
              console.log(merge?.length, "merge");
              setTotal((pre) => pre - (merge?.length - 1));

              setMerge([]);
              setCreateModal(false);
            }
          },
        })
      );
    } else {
      dispatch(
        createTagRequest({
          payloadData,
          responseCallback: (res) => {
            if (res.status) {
              let newData = commonTableManipulator([res.data]);
              setList((pre) => [newData[0], ...pre]);
              setTotal((pre) => pre + 1);

              setCreateModal(false);
            }
          },
        })
      );
    }
    setLoading(false);
  };

  const initialValues = useCallback(() => {
    const data = id && list.find((user) => user.id === id);
    return {
      tag: data?.tag,
    };
  }, []);

  return (
    <Form onFinish={onFinish} initialValues={initialValues()}>
      <Row gutter={[20, 0]}>
        <Col md={24} sm={24} xs={24}>
          <CommonInputField name="tag" placeholder={"Type here"} />
        </Col>
      </Row>

      <div className="but-sec">
        <CommonButton
          width={"180px"}
          loading={loading}
          htmlType="submit"
          topClass={"small-but"}
          text={`${merge.length > 0 ? "Merge" : "Add"} Tag`}
        />
      </div>
    </Form>
  );
};

export default AddLead;
