import React, { useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Form, Upload, message } from "antd";
import { useState } from "react";
import { Images } from "../../../theme";
import "./styles.scss";
import { CommonTextField, Loader } from "../../../components";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const ImageUploader = ({ setFile, profileImage, secondary }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(profileImage);

  const handleChange = (file) => {
    const isImage = file.file.type.startsWith("image/");
    if (!isImage) {
      return;
    }

    setLoading(true);

    setFile(file?.fileList[file?.fileList.length - 1].originFileObj ?? null);
    getBase64(
      file?.fileList[file?.fileList.length - 1].originFileObj,
      (url) => {
        setImageUrl(url);
        setLoading(false);
      }
    );
  };

  const getFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("Only image files (JPEG, PNG, etc.) are allowed.");
    }
    return isImage;
  };

  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <Form.Item
      name={"imgUpload"}
      getValueFromEvent={getFile}
      rules={[
        {
          required: true,
          message: "Image is required",
        },
      ]}
    >
      <Upload
        accept="image/png, image/jpeg"
        className={secondary ? "secondary" : "my-uploader"}
        name="avatar"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        customRequest={customRequest}
      >
        {loading ? (
          <Loader />
        ) : imageUrl ? (
          <img className="preview-img" src={imageUrl} alt="avatar" />
        ) : (
          <div className="upload-content">
            {secondary ? (
              <CommonTextField
                fontWeight={600}
                className={"theme-text"}
                text={"+ Add Image"}
              />
            ) : (
              <>
                <img
                  className="camera"
                  src={Images.cameraIcon}
                  alt="avatar"
                  width={"100%"}
                  height={"100%"}
                />
                <CommonTextField
                  fontWeight={600}
                  className={"theme-text"}
                  text={"Upload Logo"}
                />
              </>
            )}
          </div>
        )}
        {/* <div className="edit-icon">
        <img src={Images.editIcon} width={"25.5px"} height={"25.5px"} />
      </div> */}
      </Upload>
    </Form.Item>
  );
};
export default ImageUploader;
