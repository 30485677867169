import { Table } from "antd";
import React from "react";
import "./styles.scss";

const CommonTable = ({
  rowSelection,
  total,
  handlePagination,
  dataSource,
  columns,
  loading,
  width = 900,
  height,
}) => {
  return (
    <Table
      rowSelection={rowSelection}
      pagination={
        handlePagination
          ? {
              total: total,
              onChange: handlePagination,
              showSizeChanger: false,
              pageSize: 10,
              position: ["bottomCenter"],
            }
          : false
      }
      dataSource={dataSource}
      columns={columns}
      loading={loading}
      scroll={{
        x: width,
        y: height,
      }}
    />
  );
};

export default CommonTable;
