import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ADD_ESTIMATE_ROUTE,
  CUSTOMER_DETAIL_ROUTE,
  ESTIMATE_DETAIL_ROUTE,
} from "../../../../constants";
import { getStockRequest } from "../../../../redux/slicers/customer";
import { customerManipulator } from "../../../../data-manipulator/user";
import { useDispatch, useSelector } from "react-redux";
import { Images } from "../../../../theme";
// import "./styles.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CommonTable,
  CommonInputField,
  CommonTextField,
  CommonButton,
} from "../../../common";
import {
  getAllCustomerEstimateRequest,
  getAllEstimateRequest,
} from "../../../../redux/slicers/general";
import { Avatar, Space } from "antd";

const EstimateList = ({ dashboard = false }) => {
  const [estimateList, setEstimateList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const searchRef = useRef();
  const [offSet, setOffSet] = useState(0);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyId } = useSelector((t) => t.user.data);
  const { id: customerId } = useParams();

  const callRequest = (search = "") => {
    setIsLoading(true);

    if (dashboard) {
      dispatch(
        getAllEstimateRequest({
          payloadData: {},
          query: `id=${companyId}&search=${search}`,
          // query: `limit=10&offset=${offSet}&search=${search}`,

          responseCallback: (res) => {
            console.log("🚀 ~ useEffect ~ res:", res);
            setIsLoading(false);
            if (res.status) {
              console.log(res, "res");
              setEstimateList(res.data);
              setTotal(res.total);
            } else {
              console.log(res.errors, "error");
            }
          },
        })
      );
    } else {
      dispatch(
        getAllCustomerEstimateRequest({
          payloadData: {},
          query: `limit=10&offset=${offSet}&search=${search}&companyId=${companyId}&customerId=${customerId}`,

          responseCallback: (res) => {
            console.log("🚀 ~ useEffect ~ res:", res);
            setIsLoading(false);
            if (res.status) {
              console.log(res, "res");
              setEstimateList(res.data);
              setTotal(res.total);
            } else {
              console.log(res.errors, "error");
            }
          },
        })
      );
    }
  };

  useEffect(() => {
    callRequest();
  }, [offSet]);

  const handleSearch = (e) => {
    if (searchRef.current) clearTimeout(searchRef.current);

    searchRef.current = setTimeout(() => {
      callRequest(e.target.value);
    }, 300);
  };

  const handlePagination = (e) => {
    console.log(e, "pagination");
    let test = (e - 1) * 10;
    setOffSet(test);
  };

  const renderNameColumn = useCallback(
    (text) => (
      <Space>
        <Avatar
          size={50}
          src={Images.avatar}
          icon={<img src={Images.avatar1} />}
        />
        <CommonTextField className={"light-text"} text={text} />
      </Space>
    ),
    []
  );

  const renderColumn = useCallback(
    (text) => <CommonTextField className={"light-text"} text={text} />,
    []
  );

  const renderActionColumn = useCallback(
    (_, { id }) => (
      <div className="action-parent">
        <CommonButton
          width={"120px"}
          text={"View Details"}
          topClass={"small-but"}
          onClick={() => navigate(`${ESTIMATE_DETAIL_ROUTE}?&id=${id}`)}
        />
      </div>
    ),
    []
  );

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      render: renderNameColumn,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: renderColumn,
    },
    {
      title: "Open Value",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: renderColumn,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: renderColumn,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: renderActionColumn,
    },
  ];

  return (
    <section className="customer-parent">
      <div className="search-section">
        <CommonTextField
          className={"text-18"}
          text={"Estimates"}
          fontWeight={600}
        />
        <div className="search-parent">
          <CommonInputField
            onChange={handleSearch}
            name="search"
            className="small-input"
            placeholder={"search..."}
            suffix={<img src={Images.searchIcon} />}
          />
          {/* <CommonButton
            topClass={"small-but"}
            width={"140px"}
            text={"Add Customer"}
            onClick={() => {
              // setCreateStockModal(true);
              // setStockId(null);
            }}
          /> */}
        </div>
      </div>
      <CommonTable
        total={total}
        // handlePagination={handlePagination}
        columns={columns}
        dataSource={estimateList}
        loading={isLoading}
        height={dashboard && 500}
      />
    </section>
  );
};

export default EstimateList;
