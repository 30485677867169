import React, { useCallback, useState } from "react";
import {
  CommonAuthLayout,
  CommonInputField,
  CommonTextField,
} from "../../../../../components";
import { EMAIL_RULE, phoneValidation } from "../../../../../utils";
import { Col, Form, Row } from "antd";
import {
  COMPANY_SETUP,
  EMAIL_VERIFICATION,
  LOGIN_ROUTE,
  WeekDays,
  EmployeeTypeOption,
  EMPLOYEE_TYPE,
} from "../../../../../constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CommonButton,
  CommonDivider,
  CommonSelect,
  CommonSwitch,
} from "../../../../common";
import { inviteUserRequest } from "../../../../../redux/slicers/user";
// import "./styles.scss";
import { employeeManipulator } from "../../../../../data-manipulator/user";
import {
  createLeadSourceRequest,
  updateLeadSourceRequest,
} from "../../../../../redux/slicers/leadSource";

const AddLead = ({
  id = null,
  setId = () => {},
  setList = () => {},
  list = [],
  setCreateModal = () => {},
  setUpdateModal = () => {},
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { companyId } = useSelector((t) => t.user.data);

  const onFinish = (values) => {
    setLoading(true);

    console.log("🚀 ~ onFinish ~ values:", values);

    const { leadSource } = values;

    const payloadData = {
      name: leadSource,
    };

    if (id) {
      dispatch(
        updateLeadSourceRequest({
          payloadData,
          responseCallback: (res) => {
            if (res.status) {
              let userCopy = [...list];
              let index = userCopy.findIndex((user) => user.id === id);

              if (index !== -1) {
                // userCopy[index] = employeeManipulator([payloadData])[0];
              }
              setList(userCopy);
              setUpdateModal(false);
              setId(null);
            }
          },
        })
      );
    } else {
      dispatch(
        createLeadSourceRequest({
          payloadData: { ...payloadData, companyId: companyId },
          responseCallback: (res) => {
            if (res.status) {
              setList((pre) => [res.data, ...pre]);
              setCreateModal(false);
            }
          },
        })
      );
    }
  };

  const initialValues = useCallback(() => {
    const data = id && list.find((user) => user.id === id);
    return {
      leadSource: data?.leadSource,
    };
  }, []);

  return (
    <Form onFinish={onFinish} initialValues={initialValues()}>
      <CommonInputField name="leadSource" placeholder={"Type here"} />

      <div className="but-sec">
        <CommonButton
          width={"180px"}
          loading={loading}
          htmlType="submit"
          topClass={"small-but"}
          text={`${id ? "Update" : "Add"} Lead Source`}
        />
      </div>
    </Form>
  );
};

export default AddLead;
