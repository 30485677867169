import React, { useCallback, useState } from "react";
import { CommonInputField } from "../../../../..";
import { Form } from "antd";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CommonButton, ImageUploader } from "../../../../../common";
import {
  inviteUserRequest,
  uploadImgRequest,
} from "../../../../../../redux/slicers/user";
import {
  createMaterialIndustryRequest,
  updateMaterialIndustryRequest,
} from "../../../../../../redux/slicers/priceBook";

const AddIndustry = ({
  id = null,
  setId = () => {},
  setList = () => {},
  list = [],
  setCreateModal = () => {},
  setUpdateModal = () => {},
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();

  const { companyId } = useSelector((t) => t.user.data);

  const onFinish = (values) => {
    setLoading(true);

    console.log("🚀 ~ onFinish ~ values:", values);

    const { industry, imgUpload } = values;

    const payloadData = {
      name: industry,
    };

    if (id) {
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        dispatch(
          uploadImgRequest({
            payloadData: formData,
            responseCallback: (res) => {
              if (res.status)
                dispatch(
                  updateMaterialIndustryRequest({
                    payloadData: {
                      ...payloadData,
                      image: res.key,
                    },
                    parameter: id,
                    responseCallback: (res) => {
                      if (res.status) {
                        let userCopy = [...list];
                        let index = userCopy.findIndex(
                          (user) => user.id === id
                        );

                        if (index !== -1) {
                          userCopy[index] = res.data;
                        }
                        setList(userCopy);
                        setUpdateModal(false);
                        setId(null);
                        setLoading(false);
                      }
                    },
                  })
                );
            },
          })
        );
      } else {
        dispatch(
          updateMaterialIndustryRequest({
            payloadData: { ...payloadData },
            parameter: id,
            responseCallback: (res) => {
              if (res.status) {
                let userCopy = [...list];
                let index = userCopy.findIndex((user) => user.id === id);

                if (index !== -1) {
                  userCopy[index] = res.data;
                }
                setList(userCopy);
                setUpdateModal(false);
                setId(null);
                setLoading(false);
              }
            },
          })
        );
      }
    } else {
      const formData = new FormData();
      formData.append("file", file);
      dispatch(
        uploadImgRequest({
          payloadData: formData,
          responseCallback: (res) => {
            if (res.status)
              dispatch(
                createMaterialIndustryRequest({
                  payloadData: {
                    ...payloadData,
                    image: res.key,
                    companyId: companyId,
                  },
                  responseCallback: (res) => {
                    if (res.status) {
                      setList((pre) => [res.data, ...pre]);
                      setCreateModal(false);
                      setLoading(false);
                    }
                  },
                })
              );
          },
        })
      );
    }
    console.log(loading, "loading");
  };

  const initialValues = useCallback(() => {
    const data = id && list.find((user) => user.id === id);
    return {
      industry: data?.name,
      imgUpload: data?.image,
    };
  }, []);

  return (
    <Form onFinish={onFinish} initialValues={initialValues()}>
      <CommonInputField name="industry" placeholder={"Name"} />
      <ImageUploader
        profileImage={initialValues().imgUpload}
        secondary={true}
        setFile={setFile}
      />

      <div className="but-sec">
        <CommonButton
          width={"180px"}
          loading={loading}
          htmlType="submit"
          topClass={"small-but"}
          text={`${id ? "Update" : "Add"} Industry`}
        />
      </div>
    </Form>
  );
};

export default AddIndustry;
