// @flow
import _ from "lodash";
import { createSlice, current } from "@reduxjs/toolkit";

const customer = createSlice({
  name: "customer",
  initialState: {
    data: {},
  },
  reducers: {
    getStockRequest(state, action) {},
    getCustomerByIdRequest(state, action) {},
    createStockRequest(state, action) {},
    updateStockRequest(state, action) {},
    deleteStockRequest(state, action) {},
    getAllCustomerRequest(state, action) {}, // in future backend developer merge get all api with pagination api so we need to call same api with limit="all"
  },
});

export const {
  getStockRequest,
  getCustomerByIdRequest,
  createStockRequest,
  updateStockRequest,
  deleteStockRequest,
  getAllCustomerRequest,
} = customer.actions;

export default customer.reducer;
