/* eslint-disable react/prop-types */
import React from "react";
import { Form, Input } from "antd";
import "./styles.scss";
import { validatorField } from "../../../utils";

const CommonInputField = ({
  placeholder,
  name,
  // label,
  className,
  maxLength,
  showCount,
  addonBefore,
  type,
  onChange,
  // onBlur,
  // value,
  // errors,
  // touch,
  height,
  suffix,
  rules,
  disabled,
  autoFocus,
  onKeyDown,
  reference,
  prefix,
  field,
}) => {
  return (
    <Form.Item
      {...field}
      name={name}
      rules={
        rules
          ? rules
          : [
              {
                validator: (_, value) => {
                  return validatorField(_, value, 1, 80);
                },
              },
            ]
      }
    >
      <Input
        ref={reference}
        onKeyDown={onKeyDown}
        autoFocus={autoFocus}
        style={{ height }}
        type={type}
        disabled={disabled}
        // name={name}
        addonBefore={addonBefore}
        showCount={showCount}
        maxLength={maxLength}
        className={`ad-input ${className || ""}`}
        placeholder={placeholder}
        onChange={onChange}
        // onBlur={onBlur}
        // value={value}
        prefix={prefix}
        suffix={suffix || true}
      />
      {/* {errors && touch && (
        <p
          style={{
            color: "red",
            fontSize: "13px",
            marginBottom: "0",
          }}
        >
          {errors[name]}
        </p>
      )} */}
    </Form.Item>
  );
};

export default CommonInputField;
