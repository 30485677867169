import React, { useCallback, useEffect, useRef, useState } from "react";
import "./styles.scss";
import {
  CommonButton,
  CommonInputField,
  CommonTable,
  CommonTextField,
} from "../../../components/common";
import { CUSTOMER_DETAIL_ROUTE } from "../../../constants";
import { getStockRequest } from "../../../redux/slicers/customer";
import { customerManipulator } from "../../../data-manipulator/user";
import { useDispatch, useSelector } from "react-redux";
import { Images } from "../../../theme";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { EstimateList } from "../../../components";
import { Avatar, Space } from "antd";

const Dashboard = () => {
  const [stockList, setStockList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const searchRef = useRef();
  const [offSet, setOffSet] = useState(0);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyId } = useSelector((t) => t.user.data);

  const callRequest = (search = "") => {
    setIsLoading(true);

    dispatch(
      getStockRequest({
        payloadData: {},
        query: `limit=10&offset=${offSet}&search=${search}&id=${companyId}`,
        responseCallback: (res) => {
          console.log("🚀 ~ useEffect ~ res:", res);
          setIsLoading(false);
          if (res.status) {
            console.log(res, "res");
            setStockList(customerManipulator(res.data));
            setTotal(res.total);
          } else {
            console.log(res.errors, "error");
          }
        },
      })
    );
  };

  useEffect(() => {
    callRequest();
  }, [offSet]);

  const handleSearch = (e) => {
    if (searchRef.current) clearTimeout(searchRef.current);

    searchRef.current = setTimeout(() => {
      callRequest(e.target.value);
    }, 300);
  };

  const handlePagination = (e) => {
    console.log(e, "pagination");
    let test = (e - 1) * 10;
    setOffSet(test);
  };

  const renderNameColumn = useCallback(
    (text) => (
      <Space>
        <Avatar
          size={50}
          src={Images.avatar}
          icon={<img src={Images.avatar1} />}
        />
        <CommonTextField className={"light-text"} text={text} />
      </Space>
    ),
    []
  );

  const renderColumn = useCallback(
    (text) => <CommonTextField className={"light-text"} text={text} />,
    []
  );

  const renderActionColumn = useCallback(
    (_, { id }) => (
      <div className="action-parent">
        <CommonButton
          width={"120px"}
          text={"View Details"}
          topClass={"small-but"}
          onClick={() => navigate(CUSTOMER_DETAIL_ROUTE.replace(":id", id))}
        />
      </div>
    ),
    []
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      render: renderNameColumn,
    },
    {
      title: "Address",
      dataIndex: "streetAddress",
      key: "address",
      render: renderColumn,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: renderColumn,
    },
    {
      title: "Phone Number",
      dataIndex: "mobilePhone",
      key: "phone",
      render: renderColumn,
    },
    {
      title: "Customer Type",
      dataIndex: "customerType",
      key: "type",
      render: renderColumn,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: renderActionColumn,
    },
  ];

  return (
    <section className="dashboard">
      <div className="common-container">
        <div className="search-section">
          <CommonTextField text={"Customers"} fontWeight={600} />
          <div className="search-parent">
            <CommonInputField
              onChange={handleSearch}
              name="search"
              className="small-input"
              placeholder={"search..."}
              suffix={<img src={Images.searchIcon} />}
            />
            {/* <CommonButton
            topClass={"small-but"}
            width={"140px"}
            text={"Add Customer"}
            onClick={() => {
              // setCreateStockModal(true);
              // setStockId(null);
            }}
          /> */}
          </div>
        </div>
        <CommonTable
          total={total}
          // handlePagination={handlePagination}
          columns={columns}
          dataSource={stockList}
          loading={isLoading}
          height={500}
        />
      </div>
      <EstimateList dashboard={true} />
    </section>
  );
};

export default Dashboard;
