import React, { useEffect, useState } from "react";
import CommonTextField from "../TextField";
import "./styles.scss";
import CommonThreeDotMenu from "../CommonThreeDotMenu";
import { Images } from "../../../theme";
import CommonInputField from "../CommonInput";
import { Input } from "antd";
import CommonTable from "../CommonTable";
import CommonButton from "../CommonButton";
import { useDispatch, useSelector } from "react-redux";
import { addServices, updateServices } from "../../../redux/slicers/priceBook";
import { ADD_ESTIMATE_ROUTE, ESTIMATE_DETAIL_ROUTE } from "../../../constants";
import { useNavigate } from "react-router-dom";

const CommonAddCard = ({ data }) => {
  const navigate = useNavigate();
  const { id, image, name, description, serviceCost, servicePrice, isTaxable } =
    data;

  const [count, setCount] = useState(0);
  const [update, setUpdate] = useState(false);

  const { addAllEstimateData } = useSelector((t) => t.priceBook);
  const { services } = addAllEstimateData;
  console.log("🚀 ~ CommonAddCard ~ services:", services);
  const dispatch = useDispatch();

  const show = () => {
    const findData = services?.find((t) => t.serviceId === id);
    console.log("🚀 ~ show ~ findData:", findData);
    if (findData) {
      setCount(Number(findData?.qty) || 0);
      setUpdate(true);
    }
  };

  useEffect(() => {
    show();
  }, []);

  return (
    <div className="common-add-card-parent">
      <img src={image} className="cover-img" />
      <div className="card-content">
        <CommonTextField text={name} fontWeight={500} />
        <CommonTextField text={description} />
        <div className="total-cost flex-between">
          <CommonTextField text={"Service total"} />
          <CommonTextField text={`$${serviceCost}`} />
        </div>
        <div className="counter">
          <div
            className="count-icon"
            onClick={() => {
              if (count === 0) {
                return;
              }
              setCount((pre) => pre - 1);
            }}
          >
            <img src={Images.minusIcon} />
          </div>
          <Input value={count} onChange={(e) => setCount(e.target.value)} />
          <div
            className="count-icon"
            onClick={() => setCount((pre) => pre + 1)}
          >
            <img src={Images.addIcon} />
          </div>
        </div>
        <div className="total-cost flex-between">
          <CommonTextField text={"Service total"} />
          <CommonTextField text={`$${serviceCost * count}`} />
        </div>
        <CommonButton
          topClass={"small-but"}
          text={update ? "Update" : "Add Item"}
          onClick={() => {
            setUpdate(true);

            if (update) {
              dispatch(
                updateServices({
                  id: id,
                  qty: count,
                })
              );
            } else {
              dispatch(
                addServices({
                  serviceId: id,
                  id: id,
                  taxable: isTaxable,
                  serviceName: name,
                  qty: count,
                  unitPrice: servicePrice,
                  description: description,
                  unitCost: serviceCost,
                })
              );
            }

            if (addAllEstimateData?.customerProfile?.id) {
              navigate(
                `${ESTIMATE_DETAIL_ROUTE}?&id=${addAllEstimateData?.estimateId}`
              );
            } else {
              navigate(ADD_ESTIMATE_ROUTE);
            }
          }}
        />
      </div>
    </div>
  );
};

export default CommonAddCard;
