import React from "react";
import { CommonTextField } from "../../../common";
import { Col } from "antd";
import AddBusinessDetail from "./AddBusinessDetail";
import { useSelector } from "react-redux";
import moment from "moment";

const BusinessHoursSection = () => {
  const { operationTime } = useSelector((t) => t.userProfile);
  console.log("🚀 ~ operationTime ~ operationTime:", operationTime);

  const {
    operatingHoursStartDay,
    operatingHoursEndDay,
    onlineWindowStartDay,
    onlineWindowEndDay,
    earliestAvailability,
  } = operationTime ?? {};

  console.log(
    "🚀 ~ BusinessHoursSection ~ operatingHoursStartDay:",
    operatingHoursStartDay ?? "-"
  );

  return (
    <>
      <div className="common-container">
        <CommonTextField
          fontWeight={500}
          mb={"20px"}
          text={"Operating Hours"}
        />

        <CommonTextField
          className={"light-text"}
          text={
            operatingHoursEndDay
              ? `${
                  moment(operatingHoursStartDay).utc().format("dddd") ?? "-"
                } - ${moment(operatingHoursEndDay).utc().format("dddd") ?? "-"}`
              : "-"
          }
        />
        <CommonTextField
          text={
            operatingHoursStartDay
              ? `${moment(operatingHoursStartDay).format("hh:mm A") ?? "-"} - ${
                  moment(operatingHoursEndDay).format("hh:mm A") ?? "-"
                }`
              : "-"
          }
          fontWeight={500}
        />

        <CommonTextField
          fontWeight={500}
          mb={"20px"}
          mt={"20px"}
          text={"Online booking windows"}
        />
        <CommonTextField className={"light-text"} text={"Booking Days"} />
        <CommonTextField
          text={
            onlineWindowStartDay
              ? `${onlineWindowStartDay ?? "-"} to ${onlineWindowEndDay ?? "-"}`
              : "-"
          }
          fontWeight={500}
        />

        <CommonTextField
          mt={"10px"}
          className={"light-text"}
          text={"Earliest availability"}
        />
        <CommonTextField text={earliestAvailability ?? "-"} fontWeight={500} />
      </div>
      <AddBusinessDetail data={operationTime} />
    </>
  );
};

export default BusinessHoursSection;
