import React, { useState } from "react";
import { CommonInputField, CommonTextField } from "../../../common";
import { Col, Row, Space } from "antd";
import "./styles.scss";
import AddServiceDetail from "./AddServiceDetail";
import { useSelector } from "react-redux";

const ServiceArea = () => {
  const { serviceArea } = useSelector((t) => t.userProfile);

  console.log("🚀 ~ ServiceArea ~ serviceArea:", serviceArea);
  const [detail, setDetail] = useState(false);

  return (
    <>
      <div className="common-container">
        <CommonTextField fontWeight={500} mb={"15px"} text={"Service Area"} />
        <div className="service-detail">
          {serviceArea?.map((t) => (
            <Space size={20}>
              <div>
                <CommonTextField className={"light-text"} text={"State"} />
                <CommonTextField text={t.state} fontWeight={500} />
              </div>
              <div>
                <CommonTextField className={"light-text"} text={"City"} />
                <CommonTextField text={t.city} fontWeight={500} />
              </div>
            </Space>
          ))}
        </div>
        <CommonTextField
          mt={"20px"}
          onClick={() => setDetail(true)}
          textAlign={"end"}
          className={"theme-text c-p"}
          text={"+ Add New Area"}
        />
      </div>

      <AddServiceDetail
        // data={serviceArea}
        setDetail={setDetail}
        detail={detail}
      />
    </>
  );
};

export default ServiceArea;
