import React, { useEffect, useState } from "react";
import { Tree } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllServiceRequest } from "../../../../redux/slicers/priceBook";

const TreeTest = ({ form, resetTree }) => {
  const [list, setList] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);

  console.log("🚀 ~ TreeTest ~ list:", list);
  const dispatch = useDispatch();

  const { companyId } = useSelector((t) => t.user.data);

  useEffect(() => {
    dispatch(
      getAllServiceRequest({
        payloadData: {},
        parameter: companyId,
        responseCallback: (res) => {
          console.log("🚀 ~ useEffect ~ res:", res);
          if (res.status) {
            console.log(res, "res");
            setList(res.data);
          } else {
            console.log(res.errors, "error");
          }
        },
      })
    );
  }, []);

  const onCheck = (checkedKeys, info) => {
    console.log("onCheck", checkedKeys, info.checkedNodes);
    setCheckedKeys(checkedKeys);

    let applyData;
    if (checkedKeys[0] === "parentId") {
      applyData = "all";
    } else {
      applyData = info.checkedNodes;
    }
    console.log(applyData, "applyData");
    form.setFieldValue("applyTo", applyData);
    form.validateFields(["applyTo"]);
  };

  const treeDatass = [
    {
      title: "All Services",
      key: "parentId",
      children: list?.map((t) => {
        return {
          title: t.name,
          key: t.id,
          children: t?.category?.map((d) => ({
            title: d.name,
            key: d.id,
            children: d?.services?.map((f) => ({
              title: f.name,
              key: f.id,
              service: true,
            })),
          })),
        };
      }),
    },
  ];

  useEffect(() => {
    resetTree(() => setCheckedKeys([]));
  }, [resetTree]);

  return (
    <Tree
      checkable
      //   defaultExpandedKeys={["0-0-0", "0-0-1"]}
      //   defaultCheckedKeys={["0-0-0", "0-0-1"]}
      checkedKeys={checkedKeys}
      selectable={false}
      onCheck={onCheck}
      treeData={treeDatass}
    />
  );
};
export default TreeTest;
