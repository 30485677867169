import qs from "qs";
import _ from "lodash";
import ApiHandler from "../services/api-handler";
import { getCurrentAccessToken } from "../utils";

export const API_TIMEOUT = 30000;
export const ABORT_REQUEST_MESSAGE = "Network failed. Aborted request.";

export const BASE_URL = "https://flowforge-api.devrims.co/api";

export const ERROR_SOMETHING_WENT_WRONG =
  "Something went wrong, Please try again later";
export const ERROR_API_NOT_FOUND = "Api not found, Please try again later";

export const ERROR_NETWORK_NOT_AVAILABLE =
  "Please connect to the working Internet";

export const ERROR_ACCOUNT_BLOCKED =
  "Either your account is blocked or deleted";

export const ERROR_TOKEN_EXPIRE = "Session Expired, Please login again!";

export const REQUEST_TYPE = {
  GET: "get",
  POST: "post",
  DELETE: "delete",
  PUT: "put",
  PATCH: "patch",
};

// AUTH ROUTES
export const LOGIN_REQUEST = {
  route: "/auth/signin",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

export const SIGNUP_REQUEST = {
  route: "/auth/signup",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

export const LOGOUT_REQUEST = {
  route: "/auth/logout",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const CONFIRM_OTP_REQUEST = {
  route: "/auth/confirmOtp",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

export const CREATE_COMPANY_REQUEST = {
  route: "/auth/createCompany",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

export const CREATE_PROFILE_REQUEST = {
  route: "/auth/createProfile",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

export const VERIFY_ACCOUNT_REQUEST = {
  route: "/customer/verifyAccount",
  access_token_required: false,
  type: REQUEST_TYPE.GET,
};

export const APPROVE_PRICE_REQUEST = {
  route: "/priceAdjuster/updateprice",
  access_token_required: false,
  type: REQUEST_TYPE.PATCH,
};

export const UPDATE_USER_PROFILE_REQUEST = {
  route: "/auth/updateCompanyProfile",
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const ADD_SERVICE_AREA_REQUEST = {
  route: "/auth/setProfileServiceArea",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const ADD_OPERATION_REQUEST = {
  route: "/auth/addProfileOperationTime",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const FORFET_PASSWORD_REQUEST = {
  route: "/auth/forgetPassword",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

export const RESET_PASSWORD_REQUEST = {
  route: "/auth/resetPassword",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

export const RESEND_OTP_REQUEST = {
  route: "/auth/resentOtp",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

export const GET_USER_PROFILE = {
  route: "/auth/getUserProfile",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_PROFILE_PERCENT = {
  route: "/users/profile-percentage",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const UPLOAD_IMG_REQUEST = {
  route: "/auth/uploadImage",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

export const INVITE_USER_REQUEST = {
  route: "/auth/sendInvitation",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const GET_COMPANY_LIST_REQUEST = {
  route: "/admin/companyAdmin/list",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_CUSTOMER_REQUEST = {
  route: "/customer",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_CUSTOMER_BY_ID_REQUEST = {
  route: "/customer",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_CUSTOMER_REQUEST = {
  route: "/customer/create",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_CUSTOMER_REQUEST = {
  route: "/customer",
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const DELETE_CUSTOMER_REQUEST = {
  route: "/customer",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_ALL_CUSTOMER_REQUEST = {
  route: "/customer/get/customers",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_EMPLOYEE_REQUEST = {
  route: "/worker",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_EMPLOYEE_REQUEST = {
  route: "/worker",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_EMPLOYEE_REQUEST = {
  route: "/worker",
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const DELETE_EMPLOYEE_REQUEST = {
  route: "/worker",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_UNVERIFIED_EMPLOYEE_REQUEST = {
  route: "/worker/get/unVerifiedWorker",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const APPROVED_EMPLOYEE_REQUEST = {
  route: "worker/accept",
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const REJECT_EMPLOYEE_REQUEST = {
  route: "worker/rejected",
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const GET_LEAD_SOURCE_REQUEST = {
  route: "/source",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_LEAD_SOURCE_REQUEST = {
  route: "/source",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_LEAD_SOURCE_REQUEST = {
  route: "/source",
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const DELETE_LEAD_SOURCE_REQUEST = {
  route: "/source",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_ALL_LEAD_SOURCE_REQUEST = {
  route: "/source/get/sources",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_TAG_REQUEST = {
  route: "/tags",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_TAG_REQUEST = {
  route: "/tags",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_TAG_REQUEST = {
  route: "/tags",
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const DELETE_TAG_REQUEST = {
  route: "/tags",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const MERGE_TAG_REQUEST = {
  route: "/tags/multi/delete",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_ALL_TAG_REQUEST = {
  route: "/tags/get/tags",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_ESTIMATE_BY_ID_REQUEST = {
  route: "/estimate",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_ESTIMATE_REQUEST = {
  route: "/estimate",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_ESTIMATE_REQUEST = {
  route: "/estimate",
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const GET_ALL_ESTIMATE_REQUEST = {
  route: "/estimate/dashboard",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const UPLOAD_MULTI_IMG_REQUEST = {
  route: "/auth/upload/multipleImage",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const GET_ORG_MESSAGE_REQUEST = {
  route: "/general/messageOrg",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_ALL_CUSTOMER_ESTIMATE_REQUEST = {
  route: "/estimate/customer/list",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_INDUSTRY_REQUEST = {
  route: "/pricebook/industry",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_INDUSTRY_REQUEST = {
  route: "/pricebook/industry",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_INDUSTRY_REQUEST = {
  route: "/pricebook/industry",
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const DELETE_INDUSTRY_REQUEST = {
  route: "/pricebook/industry",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_CATEGORY_REQUEST = {
  route: "/pricebook/industry/category/get",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_CATEGORY_REQUEST = {
  route: "/pricebook/industry/category",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_CATEGORY_REQUEST = {
  route: "/pricebook/industry/category",
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const DELETE_CATEGORY_REQUEST = {
  route: "/pricebook/industry/category",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_SERVICE_REQUEST = {
  route: "/pricebook/service",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_SERVICE_REQUEST = {
  route: "/pricebook/service",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_SERVICE_REQUEST = {
  route: "/pricebook/service",
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const DELETE_SERVICE_REQUEST = {
  route: "/pricebook/service",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GTE_SERVICE_BY_ID_REQUEST = {
  route: "/pricebook/service",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_ALL_SERVICE = {
  route: "priceAdjuster/priceBook",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_TAX_REQUEST = {
  route: "/taxrate",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_ALL_TAX_REQUEST = {
  route: "/taxrate/get/taxRates",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_TAX_REQUEST = {
  route: "/taxrate",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_TAX_REQUEST = {
  route: "/taxrate",
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const DELETE_TAX_REQUEST = {
  route: "/taxrate",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_MATERIAL_INDUSTRY_REQUEST = {
  route: "/material/industry",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_MATERIAL_INDUSTRY_REQUEST = {
  route: "/material/industry",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_MATERIAL_INDUSTRY_REQUEST = {
  route: "/material/industry",
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const DELETE_MATERIAL_INDUSTRY_REQUEST = {
  route: "/material/industry",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_MATERIAL_CATEGORY_REQUEST = {
  route: "material/industry/category/get",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_MATERIAL_CATEGORY_REQUEST = {
  route: "material/industry/category",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_MATERIAL_CATEGORY_REQUEST = {
  route: "material/industry/category",
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const DELETE_MATERIAL_CATEGORY_REQUEST = {
  route: "material/industry/category",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_MATERIAL_SERVICE_REQUEST = {
  route: "/material/service",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_MATERIAL_SERVICE_REQUEST = {
  route: "/material/service",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_MATERIAL_SERVICE_REQUEST = {
  route: "/material/service",
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const DELETE_MATERIAL_SERVICE_REQUEST = {
  route: "/material/service",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GTE_MATERIAL_SERVICE_BY_ID_REQUEST = {
  route: "/material/service",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_ADJUSTER_REQUEST = {
  route: "/priceAdjuster",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const GET_MATERIAL_MARKUP_REQUEST = {
  route: "/material/markup",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_MATERIAL_MARKUP_REQUEST = {
  route: "/material/markup",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_MATERIAL_MARKUP_REQUEST = {
  route: "/material/markup",
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const DELETE_MATERIAL_MARKUP_REQUEST = {
  route: "/material/markup",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const callRequest = async (
  url,
  data,
  parameter,
  query,
  header = {},
  baseURL = BASE_URL
) => {
  let _header = header;
  if (url.access_token_required) {
    const _access_token = getCurrentAccessToken();
    console.log("accc", _access_token);
    if (_access_token) {
      _header = {
        ..._header,
        ...{
          Authorization: `Bearer ${_access_token}`,
        },
      };
    }
  }

  let _url =
    parameter && parameter !== null ? `${url.route}/${parameter}` : url.route;
  if (query && query !== null) {
    _url = `${_url}?${query}`;
  }
  let response = await ApiHandler(url.type, _url, data, _header, baseURL);
  console.log("response", response);
  return response;
};
