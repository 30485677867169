import React from "react";
import {
  CommonHeading,
  CommonTextField,
  EstimateList,
  ProfileDetail,
  ProfileSummary,
} from "../../../components";
import "./styles.scss";
import { Col, Divider, Row, Space, Tabs } from "antd";
import { CommonDivider } from "../../../components/common";

const CustomerDetail = () => {
  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: "1",
      label: <CommonTextField textAlign={"center"} text={"Profile"} />,
      children: (
        <>
          <Col lg={12}>
            <ProfileDetail />
          </Col>
          <Col lg={12}>
            <ProfileSummary />
          </Col>
        </>
      ),
    },
    {
      key: "2",
      label: <CommonTextField textAlign={"center"} text={"Estimate"} />,
      children: <EstimateList />,
    },
    {
      key: "3",
      label: <CommonTextField textAlign={"center"} text={"Jobs"} />,
      children: "Content of Tab Pane 3",
    },
    {
      key: "4",
      label: <CommonTextField textAlign={"center"} text={"Invoice"} />,
      children: "Content of Tab Pane 4",
    },
  ];

  return (
    <Row gutter={[10, 10]} className="customer-detail-parent">
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </Row>
  );
};

export default CustomerDetail;
