import { fork } from "redux-saga/effects";

import general from "./general";
import user from "./user";
import userProfile from "./userProfile";
import customer from "./customer";
import employee from "./employee";
import leadSource from "./leadSource";
import priceBook from "./priceBook";

export default function* root() {
  yield fork(general);
  yield fork(user);
  yield fork(userProfile);
  yield fork(customer);
  yield fork(employee);
  yield fork(leadSource);
  yield fork(priceBook);
}
