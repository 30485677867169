import { take, put, call, fork } from "redux-saga/effects";

import {
  CREATE_EMPLOYEE_REQUEST,
  CREATE_LEAD_SOURCE_REQUEST,
  DELETE_EMPLOYEE_REQUEST,
  DELETE_LEAD_SOURCE_REQUEST,
  GET_ALL_LEAD_SOURCE_REQUEST,
  GET_LEAD_SOURCE_REQUEST,
  UPDATE_EMPLOYEE_REQUEST,
  UPDATE_LEAD_SOURCE_REQUEST,
  callRequest,
} from "../../config/web-service";
import {
  approvedLeadSourceRequest,
  createLeadSourceRequest,
  deleteLeadSourceRequest,
  getAllLeadSourceRequest,
  getLeadSourceRequest,
  updateLeadSourceRequest,
} from "../slicers/leadSource";
import { toastAlert } from "../../utils";
import { ALERT_TYPES } from "../../constants";

function* getLeadSource() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getLeadSourceRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, query } = payload;

    try {
      const response = yield call(
        callRequest,
        GET_LEAD_SOURCE_REQUEST,
        payloadData,
        "",
        query,
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* createLeadSource() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(createLeadSourceRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        CREATE_LEAD_SOURCE_REQUEST,
        payloadData,
        "",
        "",
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* updateLeadSource() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(updateLeadSourceRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, parameter } = payload;
    try {
      const response = yield call(
        callRequest,
        UPDATE_LEAD_SOURCE_REQUEST,
        payloadData,
        parameter,
        "",
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* deleteLeadSource() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(deleteLeadSourceRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, parameter } = payload;
    try {
      const response = yield call(
        callRequest,
        DELETE_LEAD_SOURCE_REQUEST,
        payloadData,
        parameter,
        "",
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* getAllLeadSource() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getAllLeadSourceRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, parameter } = payload;

    try {
      const response = yield call(
        callRequest,
        GET_ALL_LEAD_SOURCE_REQUEST,
        payloadData,
        parameter,
        "",
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

export default function* root() {
  yield fork(getLeadSource);
  yield fork(createLeadSource);
  yield fork(updateLeadSource);
  yield fork(deleteLeadSource);
  yield fork(getAllLeadSource);
}
