import React, { useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Form, Upload, message } from "antd";
import { Images } from "../../../../../theme";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const EstimateUpload = ({ file, setFile, children }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  console.log("🚀 ~ EstimateUpload ~ file:", file);

  const handleChange = ({ fileList }) => {
    console.log("🚀 ~ handleChange ~ fileList:", fileList);
    setLoading(true);

    setFile(fileList);

    const newImageUrls = [];
    fileList.forEach((t) => {
      getBase64(t.originFileObj, (url) => {
        newImageUrls.push(url);
        if (newImageUrls.length === fileList.length) {
          setImageUrl(newImageUrls);
          setLoading(false);
        }
      });
    });

    if (fileList.length === 0) {
      setLoading(false);
    }
  };

  const getFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e.filter((file) => file.type.startsWith("image/"));
    }
    return e && e.fileList.filter((file) => file.type.startsWith("image/"));
  };

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("You can only upload image files!");
    }
    return isImage;
  };

  return (
    <>
      <Upload
        listType="picture"
        name="avatar"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        multiple={true}
        fileList={file}
      >
        {children}
      </Upload>
    </>
  );
};

export default EstimateUpload;
