import { take, put, call, fork } from "redux-saga/effects";
import {
  addOperationRequest,
  addOperationSuccess,
  addServiceAreaRequest,
  addServiceAreaSuccess,
  getProfilePercentageRequest,
  getUserProfileRequest,
  getUserProfileSuccess,
  updateUserProfileRequest,
  updateUserProfileSuccess,
} from "../slicers/userProfile";
import { ALERT_TYPES } from "../../constants";
import {
  ADD_OPERATION_REQUEST,
  ADD_SERVICE_AREA_REQUEST,
  callRequest,
  GET_PROFILE_PERCENT,
  GET_USER_PROFILE,
  UPDATE_SERVICE_AREA_REQUEST,
  UPDATE_USER_PROFILE_REQUEST,
} from "../../config/web-service";
import { toastAlert } from "../../utils";
import {
  ProfileManipulatorObject,
  companyProfileManipulator,
  operationTimeManipulatorObject,
  serviceAreaManipulator,
  serviceAreaManipulatorObject,
} from "../../data-manipulator/user";

function* getUserProfile() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getUserProfileRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        GET_USER_PROFILE,
        payloadData,
        "",
        "",
        {}
      );

      if (response.status) {
        yield put(
          getUserProfileSuccess(
            ProfileManipulatorObject(response?.data.companyProfile)
          )
        );
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* updateUserProfile() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(updateUserProfileRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, parameter } = payload;
    console.log("🚀 ~ function*updateUserProfile ~ parameter:", parameter);
    try {
      const response = yield call(
        callRequest,
        UPDATE_USER_PROFILE_REQUEST,
        payloadData,
        parameter,
        "",
        {}
      );

      if (response.status) {
        yield put(
          updateUserProfileSuccess(companyProfileManipulator(response?.data))
        );
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* appServiceArea() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(addServiceAreaRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, parameter } = payload;
    console.log("🚀 ~ function*updateUserProfile ~ parameter:", parameter);
    try {
      const response = yield call(
        callRequest,
        ADD_SERVICE_AREA_REQUEST,
        payloadData,
        parameter,
        "",
        {}
      );

      if (response.status) {
        yield put(
          addServiceAreaSuccess(serviceAreaManipulatorObject(response?.data))
        );
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* addOperation() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(addOperationRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, parameter } = payload;
    console.log("🚀 ~ function*updateUserProfile ~ parameter:", parameter);
    try {
      const response = yield call(
        callRequest,
        ADD_OPERATION_REQUEST,
        payloadData,
        parameter,
        "",
        {}
      );

      if (response.status) {
        yield put(
          addOperationSuccess(operationTimeManipulatorObject(response?.data))
        );
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* getProfilePercentage() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getProfilePercentageRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        GET_PROFILE_PERCENT,
        payloadData,
        "",
        "",
        {}
      );

      if (response.status) {
        // yield put(
        //   getUserProfileSuccess(
        //     ProfileManipulatorObject(response?.data.companyProfile)
        //   )
        // );
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

export default function* root() {
  yield fork(getUserProfile);
  yield fork(updateUserProfile);
  yield fork(appServiceArea);
  yield fork(addOperation);
  yield fork(getProfilePercentage);
}
