import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  CommonButton,
  CommonHeading,
  CommonInputField,
  CommonModal,
  CommonTable,
  CommonTextField,
  CommonThreeDotMenu,
} from "../../../common";
import { Images } from "../../../../theme";
import { Avatar, Col, Pagination, Row, Space } from "antd";
// import "./styles.scss";
import { employeeTableData } from "../../../../constants";
import AddEmployee from "../../AddNewItem/AddEmployee";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getStockRequest } from "../../../../redux/slicers/customer";
import {
  commonTableManipulator,
  employeeManipulator,
} from "../../../../data-manipulator/user";
import AddTag from "./AddTag";
import "./styles.scss";
import {
  deleteTagRequest,
  getTagRequest,
} from "../../../../redux/slicers/general";

const Tags = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [merge, setMerge] = useState([]);
  const { companyId } = useSelector((t) => t.user.data);

  const searchRef = useRef();
  const [offSet, setOffSet] = useState(0);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onDelete = (id) => {
    setId(id);
    setDeleteModal(true);
  };

  const onEdit = (id) => {
    console.log("🚀 ~ onEdit ~ id:", id);
    setId(id);
    setUpdateModal(true);
  };

  const callRequest = (search = "") => {
    dispatch(
      getTagRequest({
        payloadData: {},
        query: `limit=10&offset=${offSet}&search=${search}&id=${companyId}`,
        responseCallback: (res) => {
          console.log("🚀 ~ useEffect ~ res:", res);
          setIsLoading(false);
          if (res.status) {
            console.log(res, "res");
            setList(commonTableManipulator(res.data));
            setTotal(res.count);
          } else {
            console.log(res.errors, "error");
          }
        },
      })
    );
  };

  useEffect(() => {
    callRequest();
  }, [offSet]);

  const handleSearch = (e) => {
    if (searchRef.current) clearTimeout(searchRef.current);

    searchRef.current = setTimeout(() => {
      callRequest(e.target.value);
    }, 300);
  };

  const handlePagination = (e) => {
    console.log(e, "pagination");
    let test = (e - 1) * 10;
    setOffSet(test);
  };

  const renderNameColumn = useCallback(
    (text) => <CommonTextField text={text} />,
    []
  );

  const renderActionColumn = useCallback(
    (_, { id }) => (
      <div className="action-parent">
        <img
          src={Images.deleteIcon}
          className="ml-10"
          onClick={() => onDelete(id)}
        />
      </div>
    ),
    []
  );

  const columns = [
    {
      title: "Tag Name",
      dataIndex: "name",
      key: "name",
      render: renderNameColumn,
    },

    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: "50px",
      render: renderActionColumn,
    },
  ];

  return (
    <div className="common-container tag-parent">
      <CommonInputField
        onChange={handleSearch}
        name="search"
        className="small-input"
        placeholder={"search..."}
        suffix={<img src={Images.searchIcon} />}
      />
      <div className="add-section">
        <Space size={1} direction="vertical">
          <CommonTextField fontWeight={600} text={"Tags"} />
          <CommonTextField className={"light-text"} text={`${total} tags`} />
        </Space>
        <Space>
          <CommonButton
            topClass={"small-but"}
            text="Add Tag"
            onClick={() => setCreateModal(true)}
          />
          <CommonButton
            disabled={!(merge?.length > 1 && merge)}
            topClass={"small-but"}
            text="Merge"
            onClick={() => setCreateModal(true)}
          />
        </Space>
      </div>

      <CommonTable
        rowSelection={{
          type: "checkbox",
          onChange: (_, selectedRows) => {
            setMerge(selectedRows);
          },
        }}
        width={300}
        total={total}
        handlePagination={handlePagination}
        columns={columns}
        dataSource={list}
        loading={isLoading}
      />

      <CommonModal
        destroyOnClose={true}
        title={
          <CommonHeading
            level={1}
            text={merge?.length > 0 ? "Merge Tag" : "Add Tag"}
          />
        }
        isModalVisible={createModal || updateModal}
        setIsModalVisible={updateModal ? setUpdateModal : setCreateModal}
      >
        <AddTag
          id={id}
          setId={setId}
          setList={setList}
          list={list}
          setCreateModal={setCreateModal}
          setUpdateModal={setUpdateModal}
          merge={merge}
          setMerge={setMerge}
          setTotal={setTotal}
        />
      </CommonModal>
      <CommonModal
        isModalVisible={deleteModal}
        setIsModalVisible={setDeleteModal}
        discription="Do you want to delete lead source?"
        // loading={isLoading}
        onConfirm={() => {
          dispatch(
            deleteTagRequest({
              payloadData: {},
              parameter: id,
              responseCallback: (res) => {
                if (res.status) {
                  let listCopy = [...list];
                  let index = listCopy.findIndex((user) => user.id === id);
                  if (index !== -1) {
                    listCopy.splice(index, 1);
                  }
                  setList(listCopy);
                  setDeleteModal(false);
                  setId(null);
                  setTotal((pre) => pre - 1);
                } else {
                  console.log(res.errors, "error");
                }
              },
            })
          );
        }}
      ></CommonModal>
    </div>
  );
};

export default Tags;
