import React, { useState } from "react";
import {
  CommonHeading,
  MaterialMarkup,
  PriceBookComponent,
  PriceBookMaterialComponent,
  ServiceAdjuster,
  TaxRate,
} from "../../../components";
import { PRICE_BOOK_ROUTE, priceListBook } from "../../../constants";
import { CommonList, CommonSelect } from "../../../components/common";
import { useNavigate, useParams } from "react-router-dom";

const PriceBook = () => {
  const navigate = useNavigate();
  let { name } = useParams();
  const [activeComponent, setActiveComponent] = useState(name);
  console.log("🚀 ~ PriceBook ~ name:", name);

  const componentList = {
    services: { component: <PriceBookComponent />, name: "Services" },
    material: { component: <PriceBookMaterialComponent />, name: "Material" },
    "tax-rate": { component: <TaxRate />, name: "Tax Rate" },
    "service-adjuster": {
      component: <ServiceAdjuster />,
      name: "Service per Adjuster",
    },
    "material-markup": {
      component: <MaterialMarkup />,
      name: "Material Markup",
    },
  };

  const onList = (t) => {
    navigate(PRICE_BOOK_ROUTE.replace(":name", t));
    setActiveComponent(t);
  };

  return (
    <>
      <div className="top-section">
        <CommonHeading
          className={"theme-text"}
          text={componentList[name]?.name}
        />
      </div>

      <div className="setting-content">
        <div className="list-section">
          <CommonList
            heading={"PRICEBOOK"}
            list={priceListBook}
            onList={onList}
            activeComponent={activeComponent}
          />
        </div>
        <div className="list-select">
          <CommonSelect
            options={priceListBook}
            defaultValue={0}
            onSelect={(t) => onList(t)}
          />
        </div>
        <div className="detail-section">{componentList[name].component}</div>
      </div>
    </>
  );
};

export default PriceBook;
