import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  CommonButton,
  CommonHeading,
  CommonInputField,
  CommonModal,
  CommonTable,
  CommonTextField,
  CommonThreeDotMenu,
} from "../../../common";
import { Images } from "../../../../theme";
import { Avatar, Col, Pagination, Row, Space } from "antd";
import "./styles.scss";
import { EMPLOYEE_REQUEST, employeeTableData } from "../../../../constants";
import AddEmployee from "../../AddNewItem/AddEmployee";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { employeeManipulator } from "../../../../data-manipulator/user";
import {
  deleteEmployeeRequest,
  getEmployeeRequest,
} from "../../../../redux/slicers/employee";

const EmployeePermission = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const { companyId } = useSelector((t) => t.user.data);

  const searchRef = useRef();
  const [offSet, setOffSet] = useState(0);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onDelete = (id) => {
    setId(id);
    setDeleteModal(true);
  };

  const onEdit = (id) => {
    console.log("🚀 ~ onEdit ~ id:", id);
    setId(id);
    setUpdateModal(true);
  };

  const callRequest = (search = "") => {
    dispatch(
      getEmployeeRequest({
        payloadData: {},
        query: `limit=10&offset=${offSet}&search=${search}&id=${companyId}`,
        responseCallback: (res) => {
          console.log("🚀 ~ useEffect ~ res:", res);
          setIsLoading(false);
          if (res.status) {
            console.log(res, "res");
            setList(employeeManipulator(res.data));
            setTotal(res.count);
          } else {
            console.log(res.errors, "error");
          }
        },
      })
    );
  };

  useEffect(() => {
    callRequest();
  }, [offSet]);

  const handleSearch = (e) => {
    if (searchRef.current) clearTimeout(searchRef.current);

    searchRef.current = setTimeout(() => {
      callRequest(e.target.value);
    }, 300);
  };

  const handlePagination = (e) => {
    console.log(e, "pagination");
    let test = (e - 1) * 10;
    setOffSet(test);
  };

  const renderServiceColumn = useCallback(
    (text) => <CommonTextField text={text} />,
    []
  );

  const renderNameColumn = useCallback(
    (text, { logo }) => (
      <Space>
        <Avatar size={50} src={logo} icon={<img src={Images.avatar1} />} />
        <CommonTextField text={text} />
      </Space>
    ),
    []
  );

  const renderActionColumn = useCallback(
    (_, { id }) => (
      <div className="action-parent">
        <CommonThreeDotMenu
          onEdit={() => onEdit(id)}
          onDelete={() => onDelete(id)}
        />
      </div>
    ),
    []
  );

  const columns = [
    {
      title: "Employee name",
      dataIndex: "fullName",
      key: "fullName",
      width: "220px",
      render: renderNameColumn,
    },
    {
      title: "Role",
      dataIndex: "employeeType",
      key: "employeeType",
      render: renderServiceColumn,
    },
    {
      title: "Email address",
      dataIndex: "email",
      key: "email",
      render: renderServiceColumn,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: renderServiceColumn,
    },
    {
      title: "Company",
      dataIndex: "companyName",
      key: "companyName",
      render: renderServiceColumn,
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: renderActionColumn,
    },
  ];
  return (
    <div className="common-container employee-parent">
      <Row gutter={[10, 10]} className="search-section">
        <Col lg={4} xxl={4}>
          <CommonTextField fontWeight={600} text={`${total} Employees`} />
        </Col>
        <Col lg={8} xxl={8}>
          <CommonInputField
            onChange={handleSearch}
            name="search"
            className="small-input"
            placeholder={"search..."}
            suffix={<img src={Images.searchIcon} />}
          />
        </Col>
        <Col lg={6} xxl={6}>
          <CommonButton
            topClass={"small-but"}
            text={"Employee Request"}
            onClick={() => navigate(EMPLOYEE_REQUEST)}
          />
        </Col>
        <Col lg={6} xxl={6}>
          <CommonButton
            topClass={"small-but"}
            text={"Add Employee"}
            onClick={() => setCreateModal(true)}
          />
        </Col>
      </Row>

      <CommonTable
        total={total}
        handlePagination={handlePagination}
        columns={columns}
        dataSource={list}
        loading={isLoading}
      />

      <CommonModal
        destroyOnClose={true}
        title={
          <CommonHeading
            level={1}
            text={updateModal ? "Update Employee" : "Add Employee"}
          />
        }
        isModalVisible={createModal || updateModal}
        setIsModalVisible={updateModal ? setUpdateModal : setCreateModal}
      >
        <AddEmployee
          id={id}
          setId={setId}
          setList={setList}
          list={list}
          setCreateModal={setCreateModal}
          setUpdateModal={setUpdateModal}
        />
      </CommonModal>
      <CommonModal
        isModalVisible={deleteModal}
        setIsModalVisible={setDeleteModal}
        discription="Do you want to delete customer?"
        // loading={isLoading}
        onConfirm={() => {
          dispatch(
            deleteEmployeeRequest({
              payloadData: {},
              parameter: id,
              responseCallback: (res) => {
                if (res.status) {
                  let listCopy = [...list];
                  let index = listCopy.findIndex((user) => user.id === id);
                  if (index !== -1) {
                    listCopy.splice(index, 1);
                  }
                  setList(listCopy);
                  setDeleteModal(false);
                  setId(null);
                } else {
                  console.log(res.errors, "error");
                }
              },
            })
          );
        }}
      ></CommonModal>
    </div>
  );
};

export default EmployeePermission;
