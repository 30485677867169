import React, { useState } from "react";
import {
  CommonAuthLayout,
  CommonInputField,
  CommonTextField,
} from "../../../components";
import { EMAIL_RULE } from "../../../utils";
import { Form } from "antd";
import { CommonButton } from "../../../components/common";
import { EMAIL_VERIFICATION } from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { forgetPasswordRequest } from "../../../redux/slicers/user";
import { useDispatch } from "react-redux";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  console.log({ location });

  const onFinish = (values) => {
    setLoading(true);
    console.log("🚀 ~ onFinish ~ value:", values);

    const payloadData = {
      email: values.email,
    };

    dispatch(
      forgetPasswordRequest({
        payloadData,
        responseCallback: (res) => {
          console.log("🚀 ~ onFinish ~ res:", res);
          if (res.status) {
            navigate(EMAIL_VERIFICATION, {
              state: { email: values?.email, action: "forget" },
            });
          }
          setLoading(false);
        },
      })
    );
  };

  return (
    <CommonAuthLayout heading={"Forgot Password"} backBut={true}>
      <Form onFinish={onFinish} className="verification-parent">
        <CommonInputField
          name="email"
          type={"email"}
          placeholder={"name@email.com"}
          rules={EMAIL_RULE}
        />
        <CommonButton
          loading={loading}
          topClass="mt-20"
          htmlType="submit"
          text={"Send"}
        />
      </Form>
    </CommonAuthLayout>
  );
};

export default ForgotPassword;
