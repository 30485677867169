import React, { useEffect, useState } from "react";
import {
  CommonArrowBut,
  CommonButton,
  CommonDatePicker,
  CommonDivider,
  CommonHeading,
  CommonInputField,
  CommonModal,
  CommonSelect,
  CommonTextAreaField,
  CommonTextField,
} from "../../../../common";
import { EMAIL_RULE, phoneValidation } from "../../../../../utils";
import { Col, Divider, Form, Row } from "antd";
import "./styles.scss";
import { Images } from "../../../../../theme";
import { useDispatch, useSelector } from "react-redux";
import { addOperationRequest } from "../../../../../redux/slicers/userProfile";
import { WeekDays } from "../../../../../constants";
import dayjs from "dayjs";

const AddBusinesDetail = ({ data }) => {
  const { userProfile } = useSelector((t) => t.userProfile);

  console.log("🚀 ~ AddBusinesDetail ~ data:", data);
  const [detail, setDetail] = useState(false);
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    operatingHoursStartDay,
    operatingHoursEndDay,
    onlineWindowStartDay,
    onlineWindowEndDay,
    earliestAvailability,
  } = data ?? {};

  useEffect(() => {
    if (!onlineWindowEndDay) {
      setDetail(true);
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      operatingHoursStartDay: operatingHoursStartDay
        ? dayjs(operatingHoursStartDay)
        : null,
      operatingHoursEndDay: operatingHoursStartDay
        ? dayjs(operatingHoursEndDay)
        : null,
      onlineWindowStartDay: onlineWindowStartDay,
      onlineWindowEndDay: onlineWindowEndDay,
      earliestAvailability: earliestAvailability,
    });
  }, [data, detail]);

  const onFinish = (values) => {
    setLoading(true);
    const {
      operatingHoursStartDay,
      operatingHoursEndDay,
      onlineWindowStartDay,
      onlineWindowEndDay,
      earliestAvailability,
    } = values;

    const payloadData = {
      operatingHoursStartDay: new Date(operatingHoursStartDay),
      operatingHoursEndDay: new Date(operatingHoursEndDay),
      onlineWindowStartDay: onlineWindowStartDay,
      onlineWindowEndDay: onlineWindowEndDay,
      earliestAvailability: earliestAvailability,
    };

    dispatch(
      addOperationRequest({
        payloadData,
        parameter: userProfile?.companyId,
        responseCallback: (res) => {
          console.log("🚀 ~ operation ~ res:", res);
          setDetail(false);
          setLoading(false);
        },
      })
    );
    // navigate(COMPANY_SETUP);
  };

  return (
    <>
      <CommonArrowBut
        className="edit-icon"
        width={"15px"}
        height={"15px"}
        src={Images.editIcon}
        onClick={() => {
          setId(1);
          setDetail(true);
        }}
      />
      <CommonModal
        destroyOnClose={true}
        title={<CommonHeading fontSize={"24px"} text={id ? "Edit" : "Add"} />}
        isModalVisible={detail}
        setIsModalVisible={setDetail}
      >
        <Form form={form} onFinish={onFinish}>
          <CommonTextField fontWeight={500} text={"Operating Hours"} />
          <CommonDivider />
          <Row gutter={[20, 0]} className="spacing">
            <Col span={24} md={12}>
              <CommonDatePicker
                name="operatingHoursStartDay"
                placeholder={"Start Date"}
              />
            </Col>
            <Col span={24} md={12}>
              <CommonDatePicker
                name="operatingHoursEndDay"
                placeholder={"End Date"}
              />
            </Col>
          </Row>
          <CommonTextField fontWeight={500} text={"Online booking windows"} />
          <CommonDivider />
          <Row gutter={[20, 0]} className="spacing">
            <Col span={24} md={12}>
              <CommonSelect
                options={WeekDays}
                name="onlineWindowStartDay"
                placeholder={"Start Day"}
              />
            </Col>
            <Col span={24} md={12}>
              <CommonSelect
                options={WeekDays}
                name="onlineWindowEndDay"
                placeholder={"End Day"}
              />
            </Col>
          </Row>

          <CommonTextField fontWeight={500} text={"Earliest availability"} />
          <CommonDivider />
          <div className="spacing">
            <CommonSelect
              name={"earliestAvailability"}
              options={[{ label: "Same", value: "Same" }]}
              placeholder={"Select Earliest Availability"}
            />
          </div>

          <div className="but-sec">
            <CommonButton
              htmlType="submit"
              topClass={"small-but"}
              text={"Skip"}
              onClick={() => setDetail(false)}
            />
            <CommonButton
              htmlType="submit"
              topClass={"small-but"}
              text={"Save"}
            />
          </div>
        </Form>
      </CommonModal>
    </>
  );
};

export default AddBusinesDetail;
