import React, { useState } from "react";
import {
  CommonAuthLayout,
  CommonInputField,
  CommonTextField,
} from "../../../components";
import {
  EMAIL_RULE,
  handlePassworMatch,
  passwordValidation,
  phoneValidation,
  validatorField,
} from "../../../utils";
import { Checkbox, Form, Space } from "antd";
import { CommonButton, CommonRadio } from "../../../components/common";
import {
  EMAIL_VERIFICATION,
  LOGIN_ROUTE,
  PROFILE_SETUP,
  USER_ROLE,
  EMPLOYEE_ROLE,
} from "../../../constants";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signupRequest } from "../../../redux/slicers/user";

const CreateWorkerProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    console.log("🚀 ~ onFinish ~ values:", values);

    const {
      email,
      name,
      phoneNumber,
      password,
      companyCode,
      workerType,
      companyName,
    } = values;

    let payloadData;

    if (workerType === EMPLOYEE_ROLE["COMPANY"]) {
      payloadData = {
        email: email,
        phone: phoneNumber,
        companyCode: companyCode,
        fullName: name,
        password: password,
        isInvited: false,
        isAdmin: false,
        isFreelancer: false,
        isWorker: true,
        companyName: companyName,
      };
    } else {
      payloadData = {
        email: email,
        phone: phoneNumber,
        companyCode: "",
        fullName: name,
        password: password,
        isInvited: false,
        isAdmin: false,
        isFreelancer: true,
        isWorker: false,
        companyName: "",
      };
    }

    dispatch(
      signupRequest({
        payloadData,
        responseCallback: (res) => {
          console.log("🚀 ~ onFinish ~ res:", res);
          if (res.status) {
            if (workerType === EMPLOYEE_ROLE["COMPANY"]) {
              navigate(EMAIL_VERIFICATION, {
                state: { email: email, action: USER_ROLE["WORKER"] },
              });
            } else {
              navigate(EMAIL_VERIFICATION, {
                state: { email: email, action: USER_ROLE["FREELANCER"] },
              });
            }
          } else {
            // setLoading(false);
          }
          setLoading(false);
        },
      })
    );
  };

  return (
    <CommonAuthLayout
      heading={"Create your profile"}
      patternPosition={"right"}
      bottomText={
        <CommonTextField
          topClass="bottom-text"
          text={
            <>
              Have An Account?{" "}
              <span
                className="bottom-link"
                onClick={() => navigate(LOGIN_ROUTE)}
              >
                Login Here
              </span>
            </>
          }
        />
      }
    >
      <Form
        initialValues={{ workerType: EMPLOYEE_ROLE["COMPANY"] }}
        onFinish={onFinish}
      >
        <CommonInputField name="name" placeholder={"Full Name"} />
        <CommonInputField
          name="email"
          type={"email"}
          placeholder={"name@email.com"}
          rules={EMAIL_RULE}
        />
        <CommonInputField
          type={"number"}
          name="phoneNumber"
          placeholder={"Phone number"}
          rules={[
            {
              validator: (_, value) => {
                return phoneValidation(_, value);
              },
            },
          ]}
        />
        <CommonRadio
          options={[
            { name: "Company", value: EMPLOYEE_ROLE["COMPANY"] },
            { name: "Individual", value: EMPLOYEE_ROLE["INDIVIDUAL"] },
          ]}
          name={"workerType"}
        />
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.workerType !== currentValues.workerType
          }
        >
          {({ getFieldValue }) =>
            getFieldValue("workerType") === EMPLOYEE_ROLE["COMPANY"] ? (
              <>
                <CommonInputField
                  name="companyName"
                  placeholder={"Company Name"}
                />
                <CommonInputField
                  name="companyCode"
                  placeholder={"Company Code"}
                />
                <CommonInputField
                  name="password"
                  type={"password"}
                  placeholder={"Password"}
                  rules={[
                    {
                      validator: (_, value) => {
                        return passwordValidation(_, value);
                      },
                    },
                  ]}
                />
                <CommonInputField
                  name="ConfirmPassword"
                  type={"password"}
                  placeholder={"Confirm Password"}
                  rules={[
                    {
                      validator: (_, value) => {
                        return handlePassworMatch(
                          _,
                          value,
                          getFieldValue("password")
                        );
                      },
                    },
                  ]}
                />
              </>
            ) : getFieldValue("workerType") === EMPLOYEE_ROLE["INDIVIDUAL"] ? (
              <>
                <CommonInputField
                  name="password"
                  type={"password"}
                  placeholder={"Password"}
                  rules={[
                    {
                      validator: (_, value) => {
                        return passwordValidation(_, value);
                      },
                    },
                  ]}
                />
                <CommonInputField
                  name="confirmPassword"
                  type={"password"}
                  placeholder={"Confirm Password"}
                  rules={[
                    {
                      validator: (_, value) => {
                        return handlePassworMatch(
                          _,
                          value,
                          getFieldValue("password")
                        );
                      },
                    },
                  ]}
                />
              </>
            ) : null
          }
        </Form.Item>

        <CommonButton loading={loading} htmlType="submit" text={"Signup"} />
      </Form>
    </CommonAuthLayout>
  );
};

export default CreateWorkerProfile;
