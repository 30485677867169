import { Dropdown, Menu } from "antd";
import React from "react";
import { Images } from "../../../theme";
import "./styles.scss";

function CommonThreeDotMenu({ onEdit, onDelete }) {
  const items = [
    {
      key: "1",
      label: (
        <span className="group-member-options" onClick={onEdit}>
          <p>Edit</p>
        </span>
      ),
    },
    {
      key: "2",
      label: (
        <span className="group-member-options" onClick={onDelete}>
          <p>Delete</p>
        </span>
      ),
    },
  ];

  return (
    <Dropdown placement="bottomRight" menu={{ items }} trigger={["click"]}>
      <img src={Images.ThreeDots} className="c-p" />
    </Dropdown>
  );
}

export default CommonThreeDotMenu;
