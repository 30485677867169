import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  CommonButton,
  CommonCard,
  CommonHeading,
  CommonInputField,
  CommonModal,
  CommonPagination,
  CommonTable,
  CommonTextField,
  CommonThreeDotMenu,
} from "../../../common";
import { Images } from "../../../../theme";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AddTax from "./AddTax";
import { Flex, Space } from "antd";
import { SERVICE_DETAIL_ROUTE } from "../../../../constants";
import "./styles.scss";
import {
  deleteServiceRequest,
  deleteTaxRequest,
  getServiceRequest,
  getTaxRequest,
} from "../../../../redux/slicers/priceBook";

const TaxRate = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const { companyId } = useSelector((t) => t.user.data);

  const searchRef = useRef();
  const [offSet, setOffSet] = useState(0);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onDelete = (id) => {
    setId(id);
    setDeleteModal(true);
  };

  const onEdit = (id) => {
    console.log("🚀 ~ onEdit ~ id:", id);
    setId(id);
    setUpdateModal(true);
  };

  const callRequest = (search = "") => {
    dispatch(
      getTaxRequest({
        payloadData: {},
        query: `limit=10&offset=${offSet}&search=${search}&id=${companyId}`,
        responseCallback: (res) => {
          console.log("🚀 ~ useEffect ~ res:", res);
          setIsLoading(false);
          if (res.status) {
            console.log(res, "res");
            setList(res.data);
            setTotal(res.count);
          } else {
            console.log(res.errors, "error");
          }
        },
      })
    );
  };

  useEffect(() => {
    callRequest();
  }, [offSet]);

  const handleSearch = (e) => {
    if (searchRef.current) clearTimeout(searchRef.current);

    searchRef.current = setTimeout(() => {
      callRequest(e.target.value);
    }, 300);
  };

  const handlePagination = (e) => {
    console.log(e, "pagination");
    let test = (e - 1) * 10;
    setOffSet(test);
  };

  const renderColumn = useCallback(
    (text) => <CommonTextField className={"light-text"} text={text} />,
    []
  );

  const renderRateColumn = useCallback(
    (text) => <CommonTextField className={"light-text"} text={`${text}%`} />,
    []
  );

  const renderActionColumn = useCallback(
    (_, { id }) => (
      <div className="action-parent">
        <img
          width={"18px"}
          height={"18px"}
          src={Images.editIcon}
          onClick={() => onEdit(id)}
        />
        <img src={Images.deleteIcon} onClick={() => onDelete(id)} />
      </div>
    ),
    []
  );

  const columns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: renderColumn,
      width: "50px",
    },

    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: renderRateColumn,
      width: "50px",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: renderActionColumn,
      width: "50px",
    },
  ];

  return (
    <div className="common-container">
      <div className="search-parent">
        <CommonInputField
          onChange={handleSearch}
          name="search"
          className="small-input"
          placeholder={"search..."}
          suffix={<img src={Images.searchIcon} />}
        />
        <CommonButton
          topClass={"small-but"}
          width={"140px"}
          text={"Add Tax Rates"}
          onClick={() => {
            setCreateModal(true);
            setId(null);
          }}
        />
      </div>

      <CommonTable
        width={400}
        total={total}
        handlePagination={handlePagination}
        columns={columns}
        dataSource={list}
        loading={isLoading}
      />

      <CommonModal
        destroyOnClose={true}
        title={
          <CommonHeading
            level={1}
            text={updateModal ? "Update Tax Rates" : "Add Tax Rates"}
          />
        }
        isModalVisible={createModal || updateModal}
        setIsModalVisible={updateModal ? setUpdateModal : setCreateModal}
      >
        <AddTax
          id={id}
          setId={setId}
          setList={setList}
          list={list}
          setCreateModal={setCreateModal}
          setUpdateModal={setUpdateModal}
        />
      </CommonModal>
      <CommonModal
        isModalVisible={deleteModal}
        setIsModalVisible={setDeleteModal}
        discription="Do you want to delete Tax Rate?"
        // loading={isLoading}
        onConfirm={() => {
          dispatch(
            deleteTaxRequest({
              payloadData: {},
              parameter: id,
              responseCallback: (res) => {
                if (res.status) {
                  let listCopy = [...list];
                  let index = listCopy.findIndex((user) => user.id === id);
                  if (index !== -1) {
                    listCopy.splice(index, 1);
                  }
                  setList(listCopy);
                  setDeleteModal(false);
                  setId(null);
                } else {
                  console.log(res.errors, "error");
                }
              },
            })
          );
        }}
      ></CommonModal>
    </div>
  );
};

export default TaxRate;
