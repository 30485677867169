import React, { useEffect, useState } from "react";
import { Images } from "../../../theme";
import { CommonHeading, CommonTextField, Loader } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { LOGIN_ROUTE } from "../../../constants";
import {
  approvePriceRequest,
  verifyAccountRequest,
} from "../../../redux/slicers/user";
import { useDispatch } from "react-redux";
import { CommonButton } from "../../../components/common";

const PriceApproved = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  console.log("🚀 ~ VerifyAccount ~ params:", params);

  const onVerify = () => {
    setIsLoading(true);
    dispatch(
      approvePriceRequest({
        payloadData: {},
        parameter: params?.id,
        responseCallback: (res) => {
          setIsLoading(false);
          if (res.status) {
            setIsVerify(true);
            setIsLoading(false);
            console.log(res, "res");
          } else {
            console.log(res.errors, "error");
          }
        },
      })
    );
  };

  return (
    <div className="waiting-parent">
      <div className="logo-section">
        <img className="logo-img" src={Images.mainLogo} />
      </div>

      <CommonHeading
        width={"500px"}
        mt={"50px"}
        textAlign={"center"}
        fontWeight={300}
        text={
          "Your request for a price adjustment has been approved by the admin. Please confirm your request."
        }
      />
      <CommonButton
        marginTop={"20px"}
        topClass={"small-but"}
        width={"180px"}
        loading={isLoading}
        disabled={isVerify && true}
        text={isVerify ? "confirmed" : "confirm"}
        onClick={isVerify ? "" : onVerify}
      />
    </div>
  );
};

export default PriceApproved;
