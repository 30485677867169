import React, { useCallback, useState } from "react";
import { Checkbox, Col, Divider, Flex, Form, Row, Space } from "antd";
import TreeTest from "./test";
import {
  CommonButton,
  CommonInputField,
  CommonRadio,
  CommonTextField,
} from "../../../common";
import { useForm } from "antd/es/form/Form";
import { createAdjusterRequest } from "../../../../redux/slicers/priceBook";
import { useDispatch, useSelector } from "react-redux";

const ServiceAdjuster = () => {
  const dispatch = useDispatch();
  const { companyId } = useSelector((t) => t.user.data);

  const findServiceTrue = (data) => {
    if (data === "all") return true;
    let result = data?.filter((t) => t.service).map((d) => d.key);
    return result;
  };

  const [form] = Form.useForm();
  const [resetTree, setResetTree] = useState(() => () => {});

  const onFinish = (values) => {
    console.log("🚀 ~ onFinish ~ values:", values);
    const applyTo = findServiceTrue(form.getFieldValue("applyTo"));
    console.log("🚀 ~ onFinish ~ applyTo:", applyTo);

    const { adjustment, roundOf, amount } = values;

    const payloadData = {
      companyId: companyId,
      isIncrease: adjustment === "increase" ? true : false,
      isDecrease: adjustment === "decrease" ? true : false,
      amount: Number(amount),
      roundOf: roundOf,
      isAll: applyTo === true ? true : false,
      serviceIds: applyTo === true ? [] : applyTo,
    };

    dispatch(
      createAdjusterRequest({
        payloadData,
        responseCallback: (res) => {
          console.log("🚀 ~ useEffect ~ res:", res);
          if (res.status) {
            console.log(res, "res");
            form.resetFields();
            resetTree();
          } else {
            console.log(res.errors, "error");
          }
        },
      })
    );
  };

  const resetTreeFunction = useCallback(
    (reset) => setResetTree(() => reset),
    []
  );

  return (
    <Form
      form={form}
      onFinish={onFinish}
      className="common-container"
      initialValues={{
        roundOf: false,
        adjustment: "increase",
      }}
    >
      <CommonTextField text={"Adjustment"} mb={"10px"} />
      <CommonRadio
        className="radio"
        name={"adjustment"}
        options={[
          {
            name: (
              <CommonTextField className={"light-text"} text={"Increase"} />
            ),
            value: "increase",
          },
          {
            name: (
              <CommonTextField className={"light-text"} text={"decrease"} />
            ),
            value: "decrease",
          },
        ]}
      />

      <CommonTextField text="Amount" mb={"10px"} />

      <Row gutter={[20, 0]} align={"middle"}>
        <Col>
          <CommonInputField type={"number"} name={"amount"} placeholder={"%"} />
        </Col>
        <Col>
          <Space>
            <Form.Item
              name="roundOf"
              //   initialValue={false}
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
            <CommonTextField mb={"15px"} text={"Round to nearest dollar"} />
          </Space>
        </Col>
      </Row>
      <CommonTextField text="Apply To" mb={"10px"} />

      <Form.Item
        name={"applyTo"}
        rules={[{ required: true, message: "Please select the services" }]}
      >
        <TreeTest form={form} resetTree={resetTreeFunction} />
      </Form.Item>

      <CommonButton
        marginTop={"20px"}
        topClass={"small-but"}
        width={"130px"}
        text={"save"}
        htmlType="submit"
      />
    </Form>
  );
};
export default ServiceAdjuster;
