import { take, put, call, fork } from "redux-saga/effects";

import {
  CREATE_EMPLOYEE_REQUEST,
  CREATE_ESTIMATE_REQUEST,
  CREATE_LEAD_SOURCE_REQUEST,
  CREATE_REQUEST_REQUEST,
  CREATE_TAG_REQUEST,
  DELETE_EMPLOYEE_REQUEST,
  DELETE_LEAD_SOURCE_REQUEST,
  DELETE_TAG_REQUEST,
  GET_ALL_CUSTOMER_ESTIMATE_REQUEST,
  GET_ALL_ESTIMATE_REQUEST,
  GET_ALL_TAG_REQUEST,
  GET_ESTIMATE_BY_ID_REQUEST,
  GET_LEAD_SOURCE_REQUEST,
  GET_ORG_MESSAGE_REQUEST,
  GET_TAG_REQUEST,
  MERGE_TAG_REQUEST,
  UPDATE_EMPLOYEE_REQUEST,
  UPDATE_ESTIMATE_REQUEST,
  UPDATE_LEAD_SOURCE_REQUEST,
  UPDATE_TAG_REQUEST,
  UPLOAD_MULTI_IMG_REQUEST,
  callRequest,
} from "../../config/web-service";
import {
  approvedTagRequest,
  createEstimateRequest,
  createTagRequest,
  deleteTagRequest,
  getAllCustomerEstimateRequest,
  getAllEstimateRequest,
  getAllTagRequest,
  getEstimateByIdRequest,
  getMessageOrgRequest,
  getTagRequest,
  mergeTagRequest,
  updateEstimateRequest,
  updateTagRequest,
  uploadMultiImgRequest,
} from "../slicers/general";
import { toastAlert } from "../../utils";
import { ALERT_TYPES } from "../../constants";

function* getTag() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getTagRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, query } = payload;

    try {
      const response = yield call(
        callRequest,
        GET_TAG_REQUEST,
        payloadData,
        "",
        query,
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* createTag() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(createTagRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        CREATE_TAG_REQUEST,
        payloadData,
        "",
        "",
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* updateTag() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(updateTagRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, parameter } = payload;
    try {
      const response = yield call(
        callRequest,
        UPDATE_TAG_REQUEST,
        payloadData,
        parameter,
        "",
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* deleteTag() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(deleteTagRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, parameter } = payload;
    try {
      const response = yield call(
        callRequest,
        DELETE_TAG_REQUEST,
        payloadData,
        parameter,
        "",
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* mergeTag() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(mergeTagRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        MERGE_TAG_REQUEST,
        payloadData,
        "",
        "",
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* getAllTag() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getAllTagRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, parameter, query } = payload;

    try {
      const response = yield call(
        callRequest,
        GET_ALL_TAG_REQUEST,
        payloadData,
        parameter,
        query,
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* getEstimateById() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getEstimateByIdRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, parameter } = payload;

    try {
      const response = yield call(
        callRequest,
        GET_ESTIMATE_BY_ID_REQUEST,
        payloadData,
        parameter,
        "",
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* createEstimate() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(createEstimateRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        CREATE_ESTIMATE_REQUEST,
        payloadData,
        "",
        "",
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* updateEstimate() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(updateEstimateRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, parameter } = payload;
    try {
      const response = yield call(
        callRequest,
        UPDATE_ESTIMATE_REQUEST,
        payloadData,
        parameter,
        "",
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* getAllEstimate() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getAllEstimateRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, query } = payload;

    try {
      const response = yield call(
        callRequest,
        GET_ALL_ESTIMATE_REQUEST,
        payloadData,
        "",
        query,
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* getAllCustomerEstimate() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getAllCustomerEstimateRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, query } = payload;

    try {
      const response = yield call(
        callRequest,
        GET_ALL_CUSTOMER_ESTIMATE_REQUEST,
        payloadData,
        "",
        query,
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* uploadMultiImg() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(uploadMultiImgRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        UPLOAD_MULTI_IMG_REQUEST,
        payloadData,
        "",
        "",
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
        // if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* getMessageOrg() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getMessageOrgRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, parameter } = payload;
    try {
      const response = yield call(
        callRequest,
        GET_ORG_MESSAGE_REQUEST,
        payloadData,
        parameter,
        "",
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

export default function* root() {
  yield fork(getTag);
  yield fork(createTag);
  yield fork(updateTag);
  yield fork(deleteTag);
  yield fork(mergeTag);
  yield fork(getAllTag);
  yield fork(getEstimateById);
  yield fork(createEstimate);
  yield fork(updateEstimate);
  yield fork(getAllEstimate);
  yield fork(uploadMultiImg);
  yield fork(getMessageOrg);
  yield fork(getAllCustomerEstimate);
}
