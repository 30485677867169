import React, { useState } from "react";
import "./styles.scss";
import {
  CommonButton,
  CommonDivider,
  CommonModal,
  CommonTextField,
} from "../../common";
import { useDispatch, useSelector } from "react-redux";
import { logoutRequest } from "../../../redux/slicers/user";
import {
  CUSTOMER_ROUTE,
  DASHBOARD_ROUTE,
  LOGIN_ROUTE,
  SCHEDULE_ROUTE,
  SETTING_ROUTE,
} from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import AddNewItem from "../AddNewItem";
import { Images } from "../../../theme";

export const MENU_LIST = [
  {
    title: "Dashboard",
    src: <Images.DashboardIcon />,
    route: DASHBOARD_ROUTE,
  },
  {
    title: "Company",
    src: <Images.ScheduleIcon />,
    route: SCHEDULE_ROUTE,
  },
  {
    title: "Customers",
    src: <Images.CustomerIcon />,
    route: CUSTOMER_ROUTE,
  },
];

const Sidebar = ({ isDrawer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useSelector((t) => t.user);
  const location = useLocation();
  const [logout, setLogout] = useState(false);

  if (!data?.isFreelancer && !MENU_LIST.find((t) => t.title === "Settings")) {
    MENU_LIST.push({
      title: "Settings",
      src: <Images.SettingIcon />,
      route: SETTING_ROUTE,
    });
  }

  return (
    <div className={"sidebar-parent"}>
      {!isDrawer && <img src={Images.mainLogo} className="logo" />}
      <AddNewItem />
      <CommonDivider className={"sidebar-divider"} />
      <div className="sidebar-content">
        <div className="list">
          {MENU_LIST.map((t) => (
            <div
              key={Math.random()}
              className={`${
                location.pathname === t.route ? "active" : ""
              } list-item`}
              onClick={() => navigate(t?.route)}
            >
              {t.src}
              <CommonTextField text={t.title} className={"light-text"} />
            </div>
          ))}
        </div>
        <CommonButton
          classname={"logout-btn"}
          text={"Logout"}
          onClick={() => setLogout(!logout)}
        />
      </div>
      <CommonModal
        isModalVisible={logout}
        setIsModalVisible={setLogout}
        discription="Do you want to logout?"
        onConfirm={() => {
          dispatch(
            logoutRequest({
              payloadData: {},
              responseCallback: (res) => {
                if (res.status) {
                  changeRoute(LOGIN_ROUTE);
                  console.log(res.status, "res");
                } else {
                  console.log(res.errors, "error");
                }
              },
            })
          );
        }}
      ></CommonModal>
    </div>
  );
};

export default Sidebar;
