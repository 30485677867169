import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CommonButton, CommonTextField } from "../../../../common";
import { Images } from "../../../../../theme";
import {
  addMaterialServices,
  updateMaterialServices,
} from "../../../../../redux/slicers/priceBook";
import {
  ADD_ESTIMATE_ROUTE,
  ESTIMATE_DETAIL_ROUTE,
} from "../../../../../constants";

const MaterialAddCard = ({ data }) => {
  const navigate = useNavigate();
  const {
    id,
    image,
    name,
    description,
    serviceCost,
    servicePrice,
    isTaxable,
    partNumber,
  } = data;

  const [count, setCount] = useState(0);
  const [update, setUpdate] = useState(false);

  const { addAllEstimateData } = useSelector((t) => t.priceBook);
  const { material } = addAllEstimateData;

  console.log("🚀 ~ MaterialAddCard ~ material:", material);
  const dispatch = useDispatch();

  const show = () => {
    const findData = material?.find((t) => t.materialId === id);
    console.log("🚀 ~ show ~ findData:", findData);
    if (findData) {
      setCount(Number(findData?.qty) || 0);
      setUpdate(true);
    }
  };

  useEffect(() => {
    show();
  }, []);

  return (
    <div className="common-add-card-parent">
      <img src={image} className="cover-img" />
      <div className="card-content">
        <CommonTextField text={name} fontWeight={500} />
        <CommonTextField text={description} />
        <div className="total-cost flex-between">
          <CommonTextField text={"Service total"} />
          <CommonTextField text={`$${servicePrice}`} />
        </div>
        <div className="counter">
          <div
            className="count-icon"
            onClick={() => {
              if (count === 0) {
                return;
              }
              setCount((pre) => pre - 1);
            }}
          >
            <img src={Images.minusIcon} />
          </div>
          <Input value={count} onChange={(e) => setCount(e.target.value)} />
          <div
            className="count-icon"
            onClick={() => setCount((pre) => pre + 1)}
          >
            <img src={Images.addIcon} />
          </div>
        </div>
        <div className="total-cost flex-between">
          <CommonTextField text={"Service total"} />
          <CommonTextField text={`$${servicePrice * count}`} />
        </div>
        <CommonButton
          topClass={"small-but"}
          text={update ? "Update" : "Add Item"}
          onClick={() => {
            setUpdate(true);

            if (update) {
              dispatch(
                updateMaterialServices({
                  id: id,
                  qty: count,
                })
              );
            } else {
              dispatch(
                addMaterialServices({
                  materialId: id,
                  id: id,
                  taxable: isTaxable,
                  materialName: name,
                  qty: count,
                  unitPrice: servicePrice,
                  description: description,
                  unitCost: serviceCost,
                  partNumber: partNumber,
                })
              );
            }

            if (addAllEstimateData?.customerProfile?.id) {
              navigate(
                `${ESTIMATE_DETAIL_ROUTE}?&id=${addAllEstimateData?.estimateId}`
              );
            } else {
              navigate(ADD_ESTIMATE_ROUTE);
            }
          }}
        />
      </div>
    </div>
  );
};

export default MaterialAddCard;
