import React, { useEffect, useRef, useState } from "react";
import {
  CommonButton,
  CommonCard,
  CommonHeading,
  CommonInputField,
  CommonModal,
  CommonPagination,
} from "../../../../common";
import { Images } from "../../../../../theme";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Flex, Row } from "antd";
import {
  PRICE_BOOK_ROUTE,
  MATERIAL_PRICE_BOOK_LIST_ROUTE,
} from "../../../../../constants";
import { getMaterialCategoryRequest } from "../../../../../redux/slicers/priceBook";
import Loader from "../../../../loader";

const Category = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const industryName = params.get("name");
  const industryId = params.get("id");

  const searchRef = useRef();
  const [offSet, setOffSet] = useState(0);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const callRequest = (search = "") => {
    dispatch(
      getMaterialCategoryRequest({
        payloadData: {},
        query: `limit=10&offset=${offSet}&search=${search}&id=${industryId}`,
        responseCallback: (res) => {
          console.log("🚀 ~ useEffect ~ res:", res);
          setIsLoading(false);
          if (res.status) {
            console.log(res, "res");
            setList(res.data);
            setTotal(res.count);
          } else {
            console.log(res.errors, "error");
          }
        },
      })
    );
  };

  useEffect(() => {
    callRequest();
  }, [offSet]);

  const handleSearch = (e) => {
    if (searchRef.current) clearTimeout(searchRef.current);

    searchRef.current = setTimeout(() => {
      callRequest(e.target.value);
    }, 300);
  };

  const handlePagination = (e) => {
    console.log(e, "pagination");
    let test = (e - 1) * 10;
    setOffSet(test);
  };

  if (isLoading) return <Loader />;

  return (
    <div className="common-container">
      <div className="flex-between">
        <Flex align="center" gap={10}>
          <img
            className="c-p"
            src={Images.backIconBlack}
            width={"8px"}
            onClick={() => navigate(-1)}
          />
          <CommonHeading text={industryName} />
        </Flex>

        <div className="search-parent">
          <CommonInputField
            onChange={handleSearch}
            name="search"
            className="small-input"
            placeholder={"search..."}
            suffix={<img src={Images.searchIcon} />}
          />
        </div>
      </div>

      <Row gutter={[20, 20]} className="mt-20">
        {list.map(({ image, name, id }) => (
          <Col key={id} xs={24} sm={12} md={12} lg={8} xxl={8}>
            <CommonCard
              onClick={() => {
                const path = MATERIAL_PRICE_BOOK_LIST_ROUTE;
                navigate(`${path}?&name=${name}&type=service&id=${id}`);
              }}
              src={image}
              name={name}
              id={id}
              showIcon={false}
            />
          </Col>
        ))}
      </Row>

      <CommonPagination total={total} handlePagination={handlePagination} />
    </div>
  );
};

export default Category;
