import { Form, Radio } from "antd";
import React, { useState } from "react";
import "./styles.scss";

const CommonRadio = ({ name, options, className }) => {
  return (
    <Form.Item name={name}>
      <Radio.Group className={className ? className : "Common-radio"}>
        {options.map((t) => (
          <Radio key={Math.random()} value={t.value}>
            {t.name}
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

export default CommonRadio;
