import React, { useEffect, useState } from "react";
import {
  CommonArrowBut,
  CommonDivider,
  CommonHeading,
  CommonModal,
  CommonTextField,
} from "../../../common";
import { Col, Row, Space } from "antd";
import { Images } from "../../../../theme";
import "./style.scss";
import {
  deleteStockRequest,
  getCustomerByIdRequest,
} from "../../../../redux/slicers/customer";
import { useDispatch } from "react-redux";
import Loader from "../../../loader";
import { customerManipulator } from "../../../../data-manipulator/user";
import CreateCustomer from "../../customer/CreateCustomer";
import { CUSTOMER_ROUTE } from "../../../../constants";
import { useNavigate, useParams } from "react-router-dom";

const ProfileDetail = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [customerList, setCustomerList] = useState([]);
  const [customerUpdateModal, setCustomerUpdateModal] = useState(false);
  const [CustomerId, setCustomerId] = useState(null);
  const [deleteCustomer, setDeleteCustomer] = useState(false);
  const navigate = useNavigate();
  const { id: paramId } = useParams();

  console.log("🚀 ~ ProfileDetail ~ customerList:", customerList);

  const {
    id,
    fullName,
    lastName,
    displayName,
    email,
    mobilePhone,
    workPhone,
    role,
    isHomeOwner,
    isBusinessOwner,
    streetAddress,
    city,
    unit,
    state,
    zipCode,
    addressNote,
    addresses,
    homePhone,
    customerType,
  } = customerList[0] ?? {};

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getCustomerByIdRequest({
        payloadData: {},
        parameter: paramId,
        responseCallback: (res) => {
          setIsLoading(false);
          if (res.status) {
            setCustomerList(customerManipulator([res.data]));
          } else {
            console.log(res.errors, "error");
          }
        },
      })
    );
  }, [paramId]);

  if (isLoading) return <Loader />;

  return (
    <>
      <div className="common-container profile-detail">
        <div className="profile-heading">
          <CommonHeading level={1} text={"Customer Details"} mb={"10px"} />
          <Space size={3}>
            <CommonArrowBut
              className="delete-icon"
              width={"18px"}
              height={"18px"}
              src={Images.deleteIcon}
              onClick={() => {
                setCustomerId(id);
                setDeleteCustomer(true);
              }}
            />
            <CommonArrowBut
              width={"18px"}
              height={"18px"}
              src={Images.editIcon}
              onClick={() => {
                setCustomerId(id);
                setCustomerUpdateModal(true);
              }}
            />
          </Space>
        </div>
        <Row gutter={[20, 20]}>
          <Col lg={12} md={12}>
            <CommonTextField color={"#475D68"} text={"Full Name"} />
            <CommonTextField
              text={fullName}
              color={"#45494C80"}
              className={"text-16"}
            />
          </Col>
          <Col lg={12} md={12}>
            <CommonTextField color={"#475D68"} text={"Email Address"} />
            <CommonTextField
              text={email}
              color={"#45494C80"}
              className={"text-16"}
            />
          </Col>
          <Col lg={8} md={8}>
            <CommonTextField color={"#475D68"} text={"Mobile Phone"} />
            <CommonTextField
              text={mobilePhone}
              color={"#45494C80"}
              className={"text-16"}
            />
          </Col>
          <Col lg={8} md={8}>
            <CommonTextField color={"#475D68"} text={"Home Phone"} />
            <CommonTextField
              text={homePhone}
              color={"#45494C80"}
              className={"text-16"}
            />
          </Col>
          <Col lg={8} md={8}>
            <CommonTextField color={"#475D68"} text={"Work Phone"} />
            <CommonTextField
              text={workPhone}
              color={"#45494C80"}
              className={"text-16"}
            />
          </Col>

          <Col span={24}>
            <Space align="start">
              <img src={Images.checkIcon} width={"20px"} />
              <CommonTextField
                text={customerType}
                fontWeight={500}
                color={"#21272ACC"}
              />
            </Space>
          </Col>
        </Row>

        <CommonDivider className={"customer-divider"} />

        <CommonTextField
          className={"text-18"}
          fontWeight={600}
          text={"Address"}
          mb={"10px"}
          color={"#21272ACC"}
        />
        <Row gutter={[20, 20]}>
          <Col lg={12} md={12}>
            <CommonTextField color={"#475D68"} text={"Street"} />
            <CommonTextField
              text={streetAddress}
              color={"#45494C80"}
              className={"text-16"}
            />
          </Col>
          <Col lg={12} md={12}>
            <CommonTextField color={"#475D68"} text={"Unit"} />
            <CommonTextField
              text={unit}
              color={"#45494C80"}
              className={"text-16"}
            />
          </Col>
          <Col lg={8} md={8}>
            <CommonTextField color={"#475D68"} text={"City"} />
            <CommonTextField
              text={city}
              color={"#45494C80"}
              className={"text-16"}
            />
          </Col>

          <Col lg={8} md={8}>
            <CommonTextField color={"#475D68"} text={"State"} />
            <CommonTextField
              text={state}
              color={"#45494C80"}
              className={"text-16"}
            />
          </Col>

          <Col lg={8} md={8}>
            <CommonTextField color={"#475D68"} text={"Zip"} />
            <CommonTextField
              text={zipCode}
              color={"#45494C80"}
              className={"text-16"}
            />
          </Col>

          <Col span={24}>
            <CommonTextField color={"#475D68"} text={"Address Notes"} />
            <CommonTextField
              text={addressNote}
              color={"#45494C80"}
              className={"text-16"}
            />
          </Col>
        </Row>
      </div>

      <CommonModal
        destroyOnClose={true}
        title={<CommonHeading fontSize={"24px"} text={"Update Customer"} />}
        isModalVisible={customerUpdateModal}
        setIsModalVisible={setCustomerUpdateModal}
      >
        <CreateCustomer
          stockId={CustomerId}
          setStockId={setCustomerId}
          setStockList={setCustomerList}
          stockList={customerList}
          setStockUpdateModal={setCustomerUpdateModal}
        />
      </CommonModal>
      <CommonModal
        isModalVisible={deleteCustomer}
        setIsModalVisible={setDeleteCustomer}
        discription="Do you want to delete customer?"
        // loading={isLoading}
        onConfirm={() => {
          dispatch(
            deleteStockRequest({
              payloadData: {},
              parameter: CustomerId,
              responseCallback: (res) => {
                if (res.status) {
                  let userCopy = [...customerList];
                  let index = userCopy.findIndex(
                    (user) => user.id === CustomerId
                  );
                  if (index !== -1) {
                    userCopy.splice(index, 1);
                  }
                  setCustomerList(userCopy);
                  setDeleteCustomer(false);
                  setCustomerId(null);
                  navigate(CUSTOMER_ROUTE);
                } else {
                  console.log(res.errors, "error");
                }
              },
            })
          );
        }}
      ></CommonModal>
    </>
  );
};

export default ProfileDetail;
