import React, { useCallback, useEffect, useState } from "react";
import {
  CommonInputField,
  CommonTextField,
  CompanyList,
  EstimateCustomer,
  LineItem,
} from "../../../components";
import "./style.scss";
import { CommonButton, CommonDivider } from "../../../components/common";
import { Checkbox, Col, Divider, Form, Row, Space } from "antd";
import { phoneValidation } from "../../../utils";
import { Images } from "../../../theme";
import {
  createEstimateRequest,
  getMessageOrgRequest,
  uploadMultiImgRequest,
} from "../../../redux/slicers/general";
import { useDispatch, useSelector } from "react-redux";
import { DASHBOARD_ROUTE } from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";

const AddEstimate = () => {
  const dispatch = useDispatch();
  const { companyId } = useSelector((t) => t.user.data);
  const { services, materials, addAllEstimateData } = useSelector(
    (t) => t.priceBook
  );
  const [selectedTags, setSelectedTags] = useState([]);
  const [file, setFile] = useState([]);

  const [selectedSource, setSelectedSource] = useState([]);
  const [tax, setTax] = useState([]);
  const location = useLocation();

  const navigate = useNavigate();

  console.log("redux estimate", services);

  const [form] = Form.useForm();

  const test = useCallback(() => {
    const services = form.getFieldValue("services");
    const material = form.getFieldValue("material");
    console.log("🚀 ~ test ~ services:", services, material);

    const calculateTotal = (array, qtyKey, priceKey) => {
      let total = 0;
      for (const item of array) {
        const qty = item?.[qtyKey] || 0;
        const unitPrice = item?.[priceKey] || 0;
        total += qty * unitPrice;
      }
      return total;
    };

    const totalServices = calculateTotal(services, "qty", "unitPrice");
    const totalMaterials = calculateTotal(material, "qty", "unitPrice");
    return totalServices + totalMaterials;
  }, []);

  const taxCalculate = useCallback(() => {
    const services = form.getFieldValue("services");
    const material = form.getFieldValue("material");
    const taxRate = form.getFieldValue("taxRate");
    console.log("🚀 ~ taxCalculate ~ taxRate:", taxRate);

    const calculateTotal = (array, qtyKey, priceKey) => {
      let total = 0;
      for (const item of array) {
        const qty = item?.[qtyKey] || 0;
        const unitPrice = item?.[priceKey] || 0;
        const itemTotal = qty * unitPrice;
        const isTaxable = item?.taxable || false;
        total += itemTotal;
        if (isTaxable && taxRate) {
          total += (itemTotal / 100) * taxRate;
        }
      }
      return total;
    };

    const totalServices = calculateTotal(services, "qty", "unitPrice");
    const totalMaterials = calculateTotal(material, "qty", "unitPrice");
    return totalServices + totalMaterials;
  }, []);

  const onFinish = (values) => {
    console.log(values, "values");

    const payloadData = {
      companyId: companyId,
      customerId: values.customerId,
      estimateStartTime: new Date(values.estimateStartTime),
      estimateEndTime: new Date(values.estimateEndTime),
      subTotal: `${taxCalculate()}`, //backend want string
      taxRate: values.taxRate,
      totalAmount: `${test()}`,
      jobType: values.jobType,
      businessUnit: values.businessUnit,
      note: values?.privateNote,
      messageOrg: values?.orgMessage,
      services: values?.services.map((t) => {
        return {
          ...t,
          qty: Number(t?.qty),
          unitPrice: Number(t?.unitPrice),
          unitCost: Number(t?.unitCost),
        };
      }),

      materials: values?.material.map((t) => {
        return {
          ...t,
          qty: Number(t?.qty),
          unitPrice: Number(t?.unitPrice),
          unitCost: Number(t?.unitCost),
        };
      }),
      tags: selectedTags?.map((t) => {
        return {
          id: t.value,
        };
      }),
      sources: selectedSource?.map((t) => {
        return {
          id: t.value,
        };
      }),
    };

    const formData = new FormData();

    file.map((t) => formData.append("files", t.originFileObj));

    let hasData = false;
    for (let pair of formData.entries()) {
      hasData = true;
      break;
    }

    if (!hasData) {
      dispatch(
        createEstimateRequest({
          payloadData: {
            ...payloadData,
            images: [],
          },
          responseCallback: (res) => {
            console.log("🚀 ~ useEffect ~ res:", res);
            if (res.status) {
              console.log(res, "res");
              navigate(DASHBOARD_ROUTE);
            } else {
              console.log(res.errors, "error");
            }
          },
        })
      );
    } else {
      dispatch(
        uploadMultiImgRequest({
          payloadData: formData,
          responseCallback: (res) => {
            console.log("🚀 ~ useEffect ~ res:", res);
            if (res.status) {
              dispatch(
                createEstimateRequest({
                  payloadData: {
                    ...payloadData,
                    images: res.data.map((t) => {
                      return {
                        key: t.key,
                      };
                    }),
                  },
                  responseCallback: (res) => {
                    console.log("🚀 ~ useEffect ~ res:", res);
                    if (res.status) {
                      console.log(res, "res");
                      navigate(DASHBOARD_ROUTE);
                    } else {
                      console.log(res.errors, "error");
                    }
                  },
                })
              );
            } else {
              console.log(res.errors, "error");
            }
          },
        })
      );
    }
  };

  useEffect(() => {
    console.log(addAllEstimateData, "addAllEstimateData");

    if (addAllEstimateData && Object.keys(addAllEstimateData)?.length !== 0) {
      form.setFieldsValue({
        companyId: companyId,
        customerId: addAllEstimateData?.customerId,
        estimateStartTime: addAllEstimateData?.estimateStartTime
          ? dayjs(addAllEstimateData?.estimateStartTime)
          : null,
        estimateEndTime: addAllEstimateData?.estimateEndTime
          ? dayjs(addAllEstimateData?.estimateEndTime)
          : null,
        taxRate: addAllEstimateData?.taxRate,
        jobType: addAllEstimateData?.jobType,
        businessUnit: addAllEstimateData?.businessUnit,
        privateNote: addAllEstimateData?.privateNote,
        orgMessage: addAllEstimateData?.orgMessage,
        services:
          addAllEstimateData?.services.length === 2
            ? addAllEstimateData?.services?.filter(
                (obj) => Object.keys(obj).length > 1
              )
            : addAllEstimateData?.services,
        material:
          addAllEstimateData?.material.length === 2
            ? addAllEstimateData?.material?.filter(
                (obj) => Object.keys(obj).length > 1
              )
            : addAllEstimateData?.material,
        imgUpload: addAllEstimateData?.imgUpload,
      });
      setFile(addAllEstimateData?.file);
      setSelectedTags(addAllEstimateData?.selectedTags);
      setSelectedSource(addAllEstimateData?.selectedSource);
    }

    if (addAllEstimateData == null) {
      dispatch(
        getMessageOrgRequest({
          payloadData: {},
          parameter: companyId,
          responseCallback: (res) => {
            console.log("🚀 ~ useEffect ~ res:", res);
            if (res.status) {
              form.setFieldsValue({
                orgMessage: res.data.messageInvoiceReceiptEstimate,
              });
            } else {
              console.log(res.errors, "error");
            }
          },
        })
      );
    }

    return () => {
      console.log(location, "location");
    };
  }, [addAllEstimateData]);

  return (
    <Form
      form={form}
      initialValues={{
        services: services.length !== 0 ? services : [{}],
        material: materials.length !== 0 ? materials : [{}],
      }}
      onFinish={onFinish}
      onFinishFailed={(error) => {
        console.log(error);
      }}
    >
      <div className="add-estimate-parent">
        <div className="customer-section">
          <EstimateCustomer
            setFile={setFile}
            file={file}
            form={form}
            setSelectedTags={setSelectedTags}
            selectedTags={selectedTags}
            setSelectedSource={setSelectedSource}
            selectedSource={selectedSource}
          />
        </div>
        <div className="right-section">
          <LineItem
            file={file}
            selectedSource={selectedSource}
            selectedTags={selectedTags}
            form={form}
            test={test}
            taxCalculate={taxCalculate}
            setTax={setTax}
          />
        </div>
      </div>
    </Form>
  );
};

export default AddEstimate;
