import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Upload, message } from "antd";
import { Images } from "../../../../../theme";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const EstimateUpload = ({ file, setFile, children }) => {
  const [loading, setLoading] = useState(false);

  const handleChange = ({ fileList }) => {
    setLoading(true);

    // Filter out duplicate files based on the uid
    const uniqueFileList = fileList?.filter(
      (newFile) =>
        !file?.some((existingFile) => existingFile?.uid === newFile?.uid)
    );

    setFile((pre) => [...pre, ...uniqueFileList]);

    uniqueFileList.forEach((t) => {
      getBase64(t.originFileObj, () => {
        setLoading(false);
      });
    });

    if (fileList.length === 0) {
      setLoading(false);
    }
  };

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("You can only upload image files!");
    }
    return isImage;
  };

  return (
    <>
      <Upload
        listType="picture"
        name="avatar"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        multiple={true}
        fileList={file}
      >
        {children}
      </Upload>
    </>
  );
};

export default EstimateUpload;
