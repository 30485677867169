import React from "react";
import { CommonDivider, CommonHeading, CommonTextField } from "../../../common";
import { Col, Row } from "antd";

const ProfileSummary = () => {
  return (
    <div className="common-container">
      <CommonTextField fontWeight={500} text={"Summary"} />
      <Row gutter={[20, 20]}>
        <Col>
          <CommonTextField className={"light-text"} text={"Created"} />
          <CommonTextField className={"light-text"} text={"01/11/2024"} />
        </Col>

        <Col>
          <CommonTextField className={"light-text"} text={"Lifetime value"} />
          <CommonTextField className={"light-text"} text={"$0.00"} />
        </Col>

        <Col>
          <CommonTextField
            className={"light-text"}
            text={"Outstanding balance"}
          />
          <CommonTextField className={"light-text"} text={"$0.00"} />
        </Col>
      </Row>
      <CommonTextField mt={"15px"} fontWeight={500} text={"Payment method"} />
      <CommonTextField
        className={"theme-text"}
        fontWeight={500}
        text={"+Add Credit Card"}
      />
      <CommonDivider className={"summary-divider"} />
      <CommonTextField fontWeight={500} text={"Customer Tags"} />
    </div>
  );
};

export default ProfileSummary;
