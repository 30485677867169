import React, { useState } from "react";
import "./styles.scss";
import { CommonTextField } from "../../../components";
import { Images } from "../../../theme";
import { Col, Divider, Drawer, Row, Space } from "antd";
import { CommonButton, CommonHeading } from "../../../components/common";
import { LOGIN_ROUTE, SELECT_USER } from "../../../constants";
import { useNavigate } from "react-router-dom";
import Slider from "./Slider";

const serviceList = [
  { src: Images.serviceIcon1, name: "Construction" },
  { src: Images.serviceIcon2, name: "Renovation" },
  { src: Images.serviceIcon3, name: "Consultation" },
  { src: Images.serviceIcon4, name: "Repair Services" },
  { src: Images.serviceIcon5, name: "Architecture" },
  { src: Images.serviceIcon6, name: "Electric" },
];

const headerList = [
  { name: "Home" },
  { name: "About us" },
  { name: "Services" },
  { name: "Contact Us" },
];

const Home = () => {
  const [sidebar, setSidebar] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <section className="home-wrapper" id={"top"}>
        <div className="drawerIcon">
          <img
            onClick={() => setSidebar(!sidebar)}
            width={"15px"}
            height={"15px"}
            src={Images.drawerIcon}
          />
          <img src={Images.mainLogo} width={"150px"} height={"40px"} />
        </div>
        <Drawer
          placement="left"
          title={<CommonTextField fontSize={"20px"} text={"Flow Forge"} />}
          onClose={() => setSidebar(!sidebar)}
          open={sidebar}
        >
          <div className="sidebar-section">
            <div className="header-content">
              {headerList.map((t) => (
                <CommonTextField
                  className={t.name === "Home" ? "theme-text" : ""}
                  fontSize={"18px"}
                  text={t.name}
                />
              ))}
            </div>
            <div className="buttton-sec">
              <CommonButton text={"Login"} />
              <CommonButton text={"Register"} />
            </div>
          </div>
        </Drawer>
        <header className="header-section">
          <img src={Images.mainLogo} width={"200px"} height={"40px"} />
          <div className="header-content">
            {headerList.map((t) => (
              <CommonTextField
                className={t.name === "Home" ? "theme-text" : ""}
                fontSize={"18px"}
                text={t.name}
              />
            ))}
          </div>
          <div className="buttton-sec">
            <CommonButton
              width={"130px"}
              onClick={() => navigate(LOGIN_ROUTE)}
              text={"Login"}
            />
            <CommonButton
              width={"130px"}
              onClick={() => navigate(SELECT_USER)}
              text={"Register"}
            />
          </div>
        </header>
        <div className="hero-section">
          <div className="hero-content">
            <CommonHeading
              className="hero-heading"
              text={"Building things is our mission."}
            />
          </div>
          <Slider
            items={[
              {
                title: "Feature Projects",
                description: "The National University of Architecture",
              },
              {
                title: "Feature Projects",
                description: "The National University of Business",
              },
              {
                title: "Feature Projects",
                description: "The National University of Software Engineering",
              },
            ]}
          />
        </div>

        {/* for mobile */}
        <div className="slider-mobile">
          <Slider
            items={[
              {
                title: "Feature Projects",
                description: "The National University of Architecture",
              },
              {
                title: "Feature Projects",
                description: "The National University of Business",
              },
              {
                title: "Feature Projects",
                description: "The National University of Software Engineering",
              },
            ]}
          />
        </div>

        <div className="reputation-section">
          <CommonTextField
            lineHeight={"35px"}
            fontWeight={700}
            fontSize={"36px"}
            mb={"50px"}
            text={"Our Reputation"}
          />
          <Row gutter={[60, 30]}>
            {new Array(3).fill(0).map((t, i) => (
              <Col lg={8} md={12} sm={24}>
                <div className="reputaion-card">
                  <Space size={20} direction="vertical">
                    <img src={i === 2 ? Images.designIcon : Images.headPhone} />
                    <CommonTextField
                      text={"Best Services"}
                      fontWeight={700}
                      fontSize={"20px"}
                    />
                    <CommonTextField
                      fontSize={"16px"}
                      className={"light-text"}
                      text={
                        "Nullam senectus porttitor in eget. Eget rutrum leo interdum."
                      }
                    />
                  </Space>
                </div>
              </Col>
            ))}
          </Row>
        </div>
        <div className="about-section">
          <div className="img-container">
            <img src={Images.aboutImg} />
          </div>
          <div className="about-container">
            <div className="about">
              <CommonTextField
                text={"About us"}
                fontSize={"36px"}
                fontWeight={700}
                mb={"40px"}
              />
              <CommonTextField
                mb={"20px"}
                text={
                  "For more than 30 years we have been delivering world-class construction and we’ve built many lasting relationships along the way."
                }
              />
              <CommonTextField
                text={
                  "We’ve matured into an industry leader and trusted resource for those seeking quality, innovation and reliability when building in the U.S."
                }
              />
            </div>
          </div>
        </div>
        <div className="services-section">
          <CommonTextField
            fontWeight={700}
            fontSize={"36px"}
            mb={"50px"}
            text={"Services"}
          />
          <Row gutter={[50, 50]}>
            {serviceList?.map((t, i) => (
              <Col lg={8} md={12} sm={24} xs={24}>
                <div
                  className={`reputaion-card ${
                    (i + 1) % 2 === 0 ? "active" : "unActive"
                  }`}
                >
                  <Space direction="vertical">
                    <img src={t.src} />
                    <Divider />
                    <CommonTextField
                      className={"theme-text"}
                      text={t.name}
                      fontWeight={700}
                      fontSize={"20px"}
                    />
                  </Space>
                </div>
              </Col>
            ))}
          </Row>
        </div>
        <div className="experience-section-parent">
          <Row gutter={[90, 30]} className="exprience-section">
            <Col span={24} lg={14} xxl={16} className="card-section">
              <img src={Images.experience} />
            </Col>
            <Col span={24} lg={10} xxl={8} className="content-section">
              <CommonTextField
                className={"experience-heading"}
                text={"30 Years Experience"}
              />
              <CommonTextField
                className={"text"}
                fontSize="20px"
                lineHeight={"25px"}
                text={
                  "Our company has been the leading provided construction services to clients throughout the USA since 1988."
                }
              />
              <button className="but-sub">
                <a href="#top"> Contact Us</a>
              </button>
            </Col>
          </Row>
        </div>
        <div className="consultation-section">
          <div className="consultation-container">
            <div>
              <CommonTextField
                fontWeight={700}
                mb={"40px"}
                topClass={"consaltant-heading"}
                text="Free consultation with exceptional quality"
              />
              <Space>
                <CommonTextField
                  lineHeight={"30px"}
                  fontSize={"24px"}
                  text={"Just one call away: "}
                />
                <a href="tel:+84 1102 2703">
                  <CommonTextField
                    lineHeight={"30px"}
                    fontSize={"24px"}
                    text={"+84 1102 2703 "}
                  />
                </a>
              </Space>
            </div>
            <div className="get-container">
              <CommonTextField
                fontSize={"18px"}
                text={<a href="#top">Get your consultation</a>}
              />
            </div>
          </div>
        </div>
        <div className="form-section">
          <CommonTextField
            fontWeight={700}
            fontSize={"36px"}
            lineHeight={"35px"}
            text={"What can us do for you?"}
            mb={"20px"}
          />
          <CommonTextField
            topClass={"form-text"}
            textAlign={"center"}
            mb={"30px"}
            fontSize={"20px"}
            lineHeight={"25px"}
            text={
              "We are ready to work on a project of any complexity, whether it’s commercial or residential."
            }
          />
          <div className="form-container">
            <form>
              <Row gutter={[20, 20]}>
                <Col span={24} md={12} lg={12}>
                  <input required={true} type="text" placeholder="Your Name*" />
                </Col>
                <Col span={24} md={12} lg={12}>
                  <input type="email" required={true} placeholder="Email*" />
                </Col>
                <Col span={24} md={12} lg={12}>
                  <input required={true} placeholder="Reason for Contacting*" />
                </Col>
                <Col span={24} md={12} lg={12}>
                  <input type="number" required={true} placeholder="phone" />
                </Col>
                <Col span={24} lg={24}>
                  <textarea required={true} placeholder="Message" rows={10} />
                </Col>
                <Col>
                  <CommonTextField
                    fontSize={"16px"}
                    text={"*indicates a required field"}
                  />
                </Col>
                <button type="submit" className="but-sub c-p">
                  Submit
                </button>
              </Row>
            </form>
          </div>
        </div>
        <footer className="footer-section">
          <Row className="footer-grid" gutter={[20, 50]}>
            <Col span={24} lg={14}>
              <div className="detail-parent">
                <Space size={20} direction="vertical">
                  <CommonTextField
                    fontSize={"20px"}
                    fontWeight={500}
                    className={"theme-text"}
                    text={"Address:"}
                  />
                  <CommonTextField
                    fontSize={"20px"}
                    fontWeight={500}
                    className={"theme-text"}
                    text={"Phone:"}
                  />
                  <CommonTextField
                    fontSize={"20px"}
                    fontWeight={500}
                    className={"theme-text"}
                    text={"Email:"}
                  />
                </Space>
                <Space size={20} direction="vertical">
                  <a target="_blank" href="https://www.google.com/maps">
                    <CommonTextField
                      fontSize={"20px"}
                      text="6391 Elgin St. Celina, Delaware 10299"
                    />
                  </a>
                  <a href="tel:+84 1102 2703">
                    <CommonTextField fontSize={"20px"} text="+84 1102 2703" />
                  </a>
                  <a href="mailto:hello@thebox.com">
                    <CommonTextField
                      fontSize={"20px"}
                      text="hello@thebox.com"
                    />
                  </a>
                </Space>
              </div>
              <img src={Images.mainLogo} width={"300px"} />
            </Col>
            <Col span={24} lg={10}>
              <div>
                <CommonTextField
                  fontSize={"20px"}
                  fontWeight={500}
                  className={"theme-text"}
                  text={"Newsletter:"}
                  mb={"20px"}
                />
                <form>
                  <Space size={15} wrap={true}>
                    <input type="text" required={true} />
                    <button className="but-sub c-p">Subscribe</button>
                  </Space>
                </form>
              </div>
              <div>
                <CommonTextField
                  fontSize={"20px"}
                  fontWeight={500}
                  className={"theme-text"}
                  text={"Social:"}
                  mt={"35px"}
                  mb={"15px"}
                />
                <Space>
                  <a target="_blank" href="https://www.facebook.com/">
                    <img src={Images.fbIcon} />
                  </a>

                  <a target="_blank" href="https://www.linkedin.com/">
                    <img src={Images.linkdinIcon} />
                  </a>
                  <a target="_blank" href="https://twitter.com/">
                    <img src={Images.twitterIcon} />
                  </a>
                </Space>
              </div>
            </Col>
          </Row>
        </footer>
        <div className="reserved-section">
          <CommonTextField
            fontSize={"16px"}
            text={"Flow Forge CRM © 2024. All Rights Reserved"}
          />
        </div>
      </section>
    </>
  );
};

export default Home;
