import React, { useEffect, useState } from "react";
import { CommonArrowBut, CommonTextField } from "../../../common";
import { Images } from "../../../../theme";
import { Col, Row, Space } from "antd";
import AddProfileDetail from "./AddProfileDetail";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileRequest } from "../../../../redux/slicers/user";

const ProfileSection = () => {
  const [showMore, setShowMore] = useState(false);
  const { userProfile } = useSelector((t) => t.userProfile);
  console.log("🚀 ~ ProfileSection ~ userProfile:", userProfile);

  const {
    businessName,
    supportEmail,
    licenseNumber,
    logo,
    website,
    legalEntityName,
    industry,
    address,
    companyDescription,
    termAndCondition,
    companyId,
    businessPhone,
    messageInvoiceReceiptEstimate,
    companyCode,
  } = userProfile ?? {};

  return (
    <>
      <Space size={20} direction="vertical" className="profile-section">
        <div className="common-container">
          <CommonTextField fontWeight={500} mb={"20px"} text={"Logo"} />
          {logo ? (
            <img src={logo} width={"250px"} height={"150px"} />
          ) : (
            <img src={Images.mainLogo} width={"200px"} height={"50px"} />
          )}
        </div>
        <div className="common-container">
          <CommonTextField
            fontWeight={500}
            mb={"15px"}
            text={"Business Information"}
          />
          <Row gutter={[20, 20]}>
            <Col sm={12} xs={24} md={12} xl={8} lg={12}>
              <CommonTextField
                className={"light-text"}
                text={"Business Name"}
              />
              <CommonTextField text={businessName} fontWeight={500} />
            </Col>
            <Col sm={12} xs={24} md={12} xl={8} lg={12}>
              <CommonTextField className={"light-text"} text={"Company Code"} />
              <CommonTextField text={companyCode} fontWeight={500} />
            </Col>
            <Col sm={12} xs={24} md={12} xl={8} lg={12}>
              <CommonTextField className={"light-text"} text={"Address"} />
              <CommonTextField text={address} fontWeight={500} />
            </Col>
            <Col sm={12} xs={24} md={12} xl={8} lg={12}>
              <CommonTextField
                className={"light-text"}
                text={"Support Email"}
              />
              <CommonTextField text={supportEmail} fontWeight={500} />
            </Col>
            <Col sm={12} xs={24} md={12} xl={8} lg={12}>
              <CommonTextField
                className={"light-text"}
                text={"License number"}
              />
              <CommonTextField text={licenseNumber} fontWeight={500} />
            </Col>
            <Col sm={12} xs={24} md={12} xl={8} lg={12}>
              <CommonTextField
                className={"light-text"}
                text={"Business phone"}
              />
              <CommonTextField text={businessPhone} fontWeight={500} />
            </Col>
            <Col sm={12} xs={24} md={12} xl={8} lg={12}>
              <CommonTextField className={"light-text"} text={"Website"} />
              <CommonTextField text={website} fontWeight={500} />
            </Col>
            <Col sm={12} xs={24} md={12} xl={8} lg={12}>
              <CommonTextField
                className={"light-text"}
                text={"Legal entity name"}
              />
              <CommonTextField text={legalEntityName} fontWeight={500} />
            </Col>
            <Col sm={12} xs={24} md={12} xl={8} lg={12}>
              <CommonTextField className={"light-text"} text={"Industry"} />
              <CommonTextField text={industry} fontWeight={500} />
            </Col>
          </Row>
        </div>
        <div className="common-container">
          <CommonTextField
            fontWeight={500}
            mb={"15px"}
            text={"Company Description"}
          />
          <CommonTextField
            mb={"20px"}
            className={"light-text"}
            text={companyDescription}
          />
        </div>
        <div className="common-container">
          <CommonTextField
            fontWeight={500}
            mb={"15px"}
            text={"Message on invoice, receipt, and estimate"}
          />
          <CommonTextField
            mb={"20px"}
            className={"light-text"}
            text={messageInvoiceReceiptEstimate}
          />

          {/* {showMore ? (
            <>
              <CommonTextField
                mt={"20px"}
                className={"light-text"}
                text="Rooter-Man of NJ is your local plumbing and drain cleaning company. We strive to provide the best customer service expe rience you will find. Whether your service is as simple as routine preventative maintenance, or is as urgent and complicat ed as emergency plumbing repairs, Rooter-Man of NJ is here to solve your plumbing, sewer, and drain problems."
              />
              <CommonTextField
                text={"Show less"}
                className={"theme-text c-p"}
                onClick={() => setShowMore(!showMore)}
              />
            </>
          ) : (
            <>
              <CommonTextField text={"..."} />
              <CommonTextField
                text={"Show More"}
                className={"theme-text c-p"}
                onClick={() => setShowMore(!showMore)}
              />
            </>
          )} */}
        </div>
        <div className="common-container">
          <CommonTextField
            fontWeight={500}
            mb={"20px"}
            text={"Terms and conditions"}
          />
          <CommonTextField className={"light-text"} text={termAndCondition} />
        </div>
      </Space>
      <AddProfileDetail data={userProfile} />
    </>
  );
};

export default ProfileSection;
