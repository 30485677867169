import React from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

const CommonArrowBut = ({
  src,
  sizeClass,
  className,
  width,
  height,
  onClick,
}) => {
  return (
    <div
      className={`arrow-but-parent ${
        sizeClass ? sizeClass : "large"
      } ${className}`}
      onClick={onClick}
    >
      <img
        className="back-img"
        src={src}
        style={{ width: width, height: height }}
      />
    </div>
  );
};

export default CommonArrowBut;
