import React, { useState } from "react";
import { CommonAuthLayout, CommonInputField } from "../../../components";
import { EMAIL_RULE, validatorField } from "../../../utils";
import { Form } from "antd";
import { CommonButton, ImageUploader } from "../../../components/common";
import { useLocation, useNavigate } from "react-router-dom";
import { Images } from "../../../theme";
import { useDispatch } from "react-redux";
import {
  createCompanyRequest,
  uploadImgRequest,
} from "../../../redux/slicers/user";
import { SETTING_ROUTE } from "../../../constants";

const CompanySetup = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { userID } = location.state;

  const onFinish = (values) => {
    setLoading(true);
    console.log("🚀 ~ onFinish ~ values:", file);
    const { businessName, supportEmail, licenseNumber } = values;

    const payloadData = {
      businessName: businessName,
      userId: userID,
      logo: "Shoe Making",
      licenseNumber: licenseNumber,
      supportEmail: supportEmail,
    };

    const formData = new FormData();
    formData.append("file", file);

    dispatch(
      uploadImgRequest({
        payloadData: formData,
        responseCallback: (res) => {
          if (res.status)
            dispatch(
              createCompanyRequest({
                payloadData: { ...payloadData, logo: res.key },
                responseCallback: (res) => {
                  if (res.status) {
                    navigate(SETTING_ROUTE);
                  }
                  console.log("🚀 ~ onFinish ~ res:", res);
                  setLoading(false);
                },
              })
            );
        },
      })
    );
  };

  return (
    <CommonAuthLayout heading={"Setup Your Company"} patternPosition={"right"}>
      <Form onFinish={onFinish}>
        <div className="uplaod-setup-account">
          <ImageUploader profileImage={""} setFile={setFile} file={file} />
        </div>
        <CommonInputField
          name="businessName"
          placeholder={"Business Name"}
          rules={[
            {
              validator: (_, value) => {
                return validatorField(_, value, 3, 80);
              },
            },
          ]}
        />
        <CommonInputField
          name="supportEmail"
          type={"email"}
          placeholder={"Support Email"}
          rules={EMAIL_RULE}
        />
        <CommonInputField
          name="licenseNumber"
          placeholder={"License Number"}
          type={"number"}
          rules={[
            {
              validator: (_, value) => {
                return validatorField(_, value, 3, 80);
              },
            },
          ]}
        />

        <CommonButton loading={loading} htmlType="submit" text={"Continue"} />
      </Form>
    </CommonAuthLayout>
  );
};

export default CompanySetup;
