import React, { useEffect } from "react";

import { Modal, Space } from "antd";
import "./styles.scss";
import CommonTextField from "../TextField";
import CommonButton from "../CommonButton";
import CommonHeading from "../CommonHeading";

const CommonModal = ({
  setIsModalVisible,
  isModalVisible,
  children,
  width,
  title,
  discription,
  onConfirm,
  loading,
  className = "",
  destroyOnClose,
}) => {
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Modal
        destroyOnClose={destroyOnClose}
        className={`common-modal ${
          discription && "confirmation-modal"
        } ${className}`}
        footer={null}
        width={width}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        title={title}
        centered
      >
        {discription ? (
          <>
            <CommonHeading text={"Are you sure?"} />
            <Space size={20} direction="vertical" className="confirm-content">
              <CommonTextField text={discription} />
              <div className="button-section">
                <CommonButton
                  text={"Confirm"}
                  onClick={onConfirm}
                  loading={loading}
                />
                <CommonButton onClick={handleOk} text={"Not Now"} />
              </div>
            </Space>
          </>
        ) : (
          children
        )}
      </Modal>
    </div>
  );
};

export default CommonModal;
