import React from "react";
import "./styles.scss";
import CommonTextField from "../TextField";

const CommonList = ({ list, onList, activeComponent, heading }) => {
  return (
    <div className="company-list-parent">
      <CommonTextField className={"light-text"} text={heading} />
      {list.map((t) => (
        <div
          className={`list-item ${activeComponent === t.route ? "active" : ""}`}
          key={t.route}
        >
          <CommonTextField text={t.name} onClick={() => onList(t.route)} />
        </div>
      ))}
    </div>
  );
};

export default CommonList;
