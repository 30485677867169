import React, { useEffect } from "react";
import { Header, Sidebar } from "../../components";
import "./styles.scss";
import { addAllEstimateData } from "../../redux/slicers/priceBook";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  MATERIAL_PRICE_BOOK_LIST_ROUTE,
  SERVICE_PRICE_BOOK_LIST_ROUTE,
} from "../../constants";

const PrivateSharedLayout = ({ children }) => {
  const location = useLocation();
  console.log("🚀 ~ PrivateSharedLayout ~ location:", location);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      ![SERVICE_PRICE_BOOK_LIST_ROUTE, MATERIAL_PRICE_BOOK_LIST_ROUTE].includes(
        location.pathname
      )
    ) {
      dispatch(addAllEstimateData(null));
    }

    console.log(location, "location");
  }, [location]);

  return (
    <section className="private-wrapper">
      <Header />
      <Sidebar />
      <div className="private-content">{children}</div>
    </section>
  );
};

export default PrivateSharedLayout;
