import React, { useCallback, useEffect, useState } from "react";
import {
  CommonInputField,
  CommonTextField,
  CompanyList,
  Loader,
} from "../../../components";
import "./style.scss";
import { CommonButton, CommonDivider } from "../../../components/common";
import { Avatar, Checkbox, Col, Divider, Form, Row, Space } from "antd";
import { phoneValidation } from "../../../utils";
import { Images } from "../../../theme";
import {
  createEstimateRequest,
  getEstimateByIdRequest,
  updateEstimateRequest,
  uploadMultiImgRequest,
} from "../../../redux/slicers/general";
import { useDispatch, useSelector } from "react-redux";
import { DASHBOARD_ROUTE } from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import EstimateCustomer from "../../../components/private/EstimateDetail/EstimateCustomer";
import LineItem from "../../../components/private/EstimateDetail/LineItem";

const EstimateDetail = () => {
  const dispatch = useDispatch();
  const { companyId } = useSelector((t) => t.user.data);
  const { services, materials, addAllEstimateData } = useSelector(
    (t) => t.priceBook
  );
  const [recentActivity, setRecentActivity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedSource, setSelectedSource] = useState([]);
  const [customerProfile, setCustomerProfile] = useState(null);
  const [file, setFile] = useState();
  console.log("🚀 ~ EstimateDetail ~ file:", file);
  const [tax, setTax] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const estimateId = params.get("id");

  const [form] = Form.useForm();

  console.log("🚀 ~ AddEstimate ~ estimateId:", estimateId);

  const test = useCallback(() => {
    const services = form.getFieldValue("services");
    const material = form.getFieldValue("material");

    const calculateTotal = (array, qtyKey, priceKey) => {
      let total = 0;
      for (const item of array) {
        const qty = item?.[qtyKey] || 0;
        const unitPrice = item?.[priceKey] || 0;
        total += qty * unitPrice;
      }
      return total;
    };

    const totalServices = calculateTotal(services, "qty", "unitPrice");
    const totalMaterials = calculateTotal(material, "qty", "unitPrice");
    return totalServices + totalMaterials;
  }, []);

  const taxCalculate = useCallback(() => {
    const services = form.getFieldValue("services");
    const material = form.getFieldValue("material");
    const taxRate = form.getFieldValue("taxRate");
    console.log("🚀 ~ taxCalculate ~ taxRate:", taxRate);

    const calculateTotal = (array, qtyKey, priceKey) => {
      let total = 0;
      for (const item of array) {
        const qty = item?.[qtyKey] || 0;
        const unitPrice = item?.[priceKey] || 0;
        const itemTotal = qty * unitPrice;
        const isTaxable = item?.taxable || false;
        total += itemTotal;
        if (isTaxable && taxRate) {
          total += (itemTotal / 100) * taxRate;
        }
      }
      return total;
    };

    const totalServices = calculateTotal(services, "qty", "unitPrice");
    const totalMaterials = calculateTotal(material, "qty", "unitPrice");
    return totalServices + totalMaterials;
  }, []);

  const onFinish = (values) => {
    console.log(values, "values");

    const payloadData = {
      companyId: companyId,
      customerId: customerProfile.id,
      estimateStartTime: new Date(values.estimateStartTime),
      estimateEndTime: new Date(values.estimateEndTime),
      subTotal: `${taxCalculate()}`, //backend want string
      taxRate: values?.taxRate,
      totalAmount: `${test()}`,
      jobType: values.jobType,
      businessUnit: values.businessUnit,
      note: values?.privateNote,
      messageOrg: values?.orgMessage,
      services: values?.services.map((t) => {
        return {
          ...t,
          qty: Number(t?.qty),
          unitPrice: Number(t?.unitPrice),
          unitCost: Number(t?.unitCost),
        };
      }),

      materials: values?.material.map((t) => {
        return {
          ...t,
          qty: Number(t?.qty),
          unitPrice: Number(t?.unitPrice),
          unitCost: Number(t?.unitCost),
        };
      }),

      materials: values?.material,
      tags: selectedTags?.map((t) => {
        return {
          id: t.value,
        };
      }),
      sources: selectedSource?.map((t) => {
        return {
          id: t.value,
        };
      }),
    };

    const formData = new FormData();

    const filterFile = file.filter((t) => !t.key);
    const filterKey = file.filter((t) => t.key);
    console.log("🚀 ~ onFinish ~ filterKey:", filterKey);

    filterFile?.map((t) => formData.append("files", t.originFileObj));

    let hasData = false;
    for (let pair of formData.entries()) {
      hasData = true;
      break;
    }

    if (!hasData) {
      console.log("hello");
      dispatch(
        updateEstimateRequest({
          payloadData: {
            ...payloadData,
            images: [],
          },
          parameter: estimateId,
          responseCallback: (res) => {
            console.log("🚀 ~ useEffect ~ res:", res);
            if (res.status) {
              console.log(res, "res");
              navigate(DASHBOARD_ROUTE);
            } else {
              console.log(res.errors, "error");
            }
          },
        })
      );
    } else {
      dispatch(
        uploadMultiImgRequest({
          payloadData: formData,
          responseCallback: (res) => {
            console.log("🚀 ~ useEffect ~ res:", res);
            if (res.status) {
              dispatch(
                updateEstimateRequest({
                  payloadData: {
                    ...payloadData,
                    images: [
                      ...filterKey.map((t) => ({ key: t.imageKey })), // Spread existing keys
                      ...res.data.map((t) => ({ key: t.key })), // Spread response keys as objects
                    ],
                  },
                  parameter: estimateId,
                  responseCallback: (res) => {
                    console.log("🚀 ~ useEffect ~ res:", res);
                    if (res.status) {
                      console.log(res, "res");
                      navigate(DASHBOARD_ROUTE);
                    } else {
                      console.log(res.errors, "error");
                    }
                  },
                })
              );
            } else {
              console.log(res.errors, "error");
            }
          },
        })
      );
    }
  };

  useEffect(() => {
    if (estimateId && !addAllEstimateData?.update) {
      setLoading(true);
      dispatch(
        getEstimateByIdRequest({
          payloadData: {},
          parameter: estimateId,
          responseCallback: (res) => {
            console.log("🚀 ~ useEffect ~ res:", res);
            form.setFieldsValue({
              companyId: companyId,
              customerId: res.data.customerId,
              // estimateStartTime: new Date(res.data.estimateStartTime),
              // estimateEndTime: new Date(values.estimateEndTime),
              // subTotal: `${taxCalculate()}`, //backend want string
              taxRate: res.data.taxRate,
              // totalAmount: `${test()}`,
              jobType: res.data.jobType,
              businessUnit: res.data.businessUnit,
              privateNote: res?.data?.note,
              orgMessage: res?.data?.messageOrg,
              services: res?.data?.services,
              material: res?.data?.materials,
              imgUpload: res?.data?.images,
            });

            setCustomerProfile(res?.data?.customerProfile);
            setSelectedTags(
              res?.data?.tags.map((t) => ({
                label: t.name,
                value: t.id,
              }))
            );
            setSelectedSource(
              res?.data?.sources.map((t) => ({
                label: t.name,
                value: t.id,
              }))
            );
            setRecentActivity(res?.data?.logs);
            setFile(res.data?.images);

            setLoading(false);
          },
        })
      );
    }

    if (addAllEstimateData && Object.keys(addAllEstimateData)?.length !== 0) {
      form.setFieldsValue({
        companyId: companyId,
        customerId: addAllEstimateData?.customerId,
        estimateStartTime: addAllEstimateData?.estimateStartTime
          ? dayjs(addAllEstimateData?.estimateStartTime)
          : null,
        estimateEndTime: addAllEstimateData?.estimateEndTime
          ? dayjs(addAllEstimateData?.estimateEndTime)
          : null,
        taxRate: addAllEstimateData?.taxRate,
        jobType: addAllEstimateData?.jobType,
        businessUnit: addAllEstimateData?.businessUnit,
        privateNote: addAllEstimateData?.privateNote,
        orgMessage: addAllEstimateData?.orgMessage,
        services: addAllEstimateData?.services,
        material: addAllEstimateData?.material,
        imgUpload: addAllEstimateData?.imgUpload,
      });
      setSelectedTags(addAllEstimateData?.selectedTags);
      setSelectedSource(addAllEstimateData?.selectedSource);
      setCustomerProfile(addAllEstimateData.customerProfile);
      setFile(addAllEstimateData?.file);
      setRecentActivity(addAllEstimateData.recentActivity);
    }

    return () => {
      console.log(location, "location");
    };
  }, []);

  if (loading) return <Loader />;

  return (
    <Form
      form={form}
      initialValues={{
        services: services.length !== 0 ? services : [{}],
        material: materials.length !== 0 ? materials : [{}],
      }}
      onFinish={onFinish}
      onFinishFailed={(error) => {
        console.log(error);
      }}
    >
      <div className="add-estimate-parent">
        <div className="customer-section">
          <EstimateCustomer
            setFile={setFile}
            file={file}
            customerProfile={customerProfile}
            form={form}
            setSelectedTags={setSelectedTags}
            selectedTags={selectedTags}
            setSelectedSource={setSelectedSource}
            selectedSource={selectedSource}
          />
        </div>
        <div className="right-section">
          <LineItem
            recentActivity={recentActivity}
            estimateId={estimateId}
            customerProfile={customerProfile}
            selectedSource={selectedSource}
            selectedTags={selectedTags}
            form={form}
            test={test}
            taxCalculate={taxCalculate}
            setTax={setTax}
            file={file}
          />
          <div className="mt-20 common-container">
            <CommonTextField
              className={"text-18"}
              fontWeight={600}
              text={"Recent activity"}
            />
            {recentActivity?.map((t, key) => (
              <div key={key} className="recent-activity-card">
                <div className="flex-between">
                  <Space>
                    <Avatar
                      size={50}
                      src={Images.avatar}
                      icon={<img src={Images.avatar1} />}
                    />
                    <Space size={0} direction="vertical">
                      <CommonTextField fontWeight={500} text={t.fullName} />
                      <CommonTextField color={"#45494C80"} text={"Job #1568"} />
                    </Space>
                  </Space>
                  <CommonTextField
                    color={"#45494C80"}
                    text={
                      moment(t.createdAt).format("DD-MM-YYYY") +
                      " | " +
                      moment(t.createdAt).format("hh:mm a")
                    }
                  />
                </div>
                <Space className="mt-10">
                  <CommonTextField
                    className={"light-text text-16"}
                    fontWeight={500}
                    text={"Activity:"}
                  />
                  <CommonTextField color={"#45494C80"} text={t.log} />
                </Space>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Form>
  );
};

export default EstimateDetail;
