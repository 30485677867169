import React from "react";
import CommonTextField from "../TextField";
import "./styles.scss";
import CommonThreeDotMenu from "../CommonThreeDotMenu";

const CommonCard = ({
  onClick,
  src = Images.industryImg,
  name,
  onEdit,
  onDelete,
  id,
  showIcon = true,
}) => {
  return (
    <div className="common-card-parent">
      <img src={src} className="cover-img" onClick={onClick} />
      <div className="card-content">
        <CommonTextField text={name} fontWeight={500} />
        {showIcon && (
          <CommonThreeDotMenu
            onEdit={() => onEdit(id)}
            onDelete={() => onDelete(id)}
          />
        )}
      </div>
    </div>
  );
};

export default CommonCard;
