import { take, put, call, fork } from "redux-saga/effects";

import {
  APPROVED_EMPLOYEE_REQUEST,
  CREATE_CUSTOMER_REQUEST,
  CREATE_EMPLOYEE_REQUEST,
  DELETE_CUSTOMER_REQUEST,
  DELETE_EMPLOYEE_REQUEST,
  GET_CUSTOMER_BY_ID_REQUEST,
  GET_CUSTOMER_REQUEST,
  GET_EMPLOYEE_REQUEST,
  GET_UNVERIFIED_EMPLOYEE_REQUEST,
  REJECT_EMPLOYEE_REQUEST,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_EMPLOYEE_REQUEST,
  callRequest,
} from "../../config/web-service";
import {
  approvedEmployeeRequest,
  createEmployeeRequest,
  deleteEmployeeRequest,
  getCompanyListRequest,
  getCustomerByIdRequest,
  getEmployeeRequest,
  getUnverifiedEmployeeRequest,
  rejectEmployeeRequest,
  updateEmployeeRequest,
} from "../slicers/employee";
import { toastAlert } from "../../utils";
import { ALERT_TYPES } from "../../constants";

function* getEmployee() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getEmployeeRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, query } = payload;

    try {
      const response = yield call(
        callRequest,
        GET_EMPLOYEE_REQUEST,
        payloadData,
        "",
        query,
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* createEmployee() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(createEmployeeRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        CREATE_EMPLOYEE_REQUEST,
        payloadData,
        "",
        "",
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* updateEmployee() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(updateEmployeeRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, parameter } = payload;
    try {
      const response = yield call(
        callRequest,
        UPDATE_EMPLOYEE_REQUEST,
        payloadData,
        parameter,
        "",
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* deleteEmployee() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(deleteEmployeeRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, parameter } = payload;
    try {
      const response = yield call(
        callRequest,
        DELETE_EMPLOYEE_REQUEST,
        payloadData,
        parameter,
        "",
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* getUnverifiedEmployee() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getUnverifiedEmployeeRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, query } = payload;

    try {
      const response = yield call(
        callRequest,
        GET_UNVERIFIED_EMPLOYEE_REQUEST,
        payloadData,
        "",
        query,
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* approvedEmployee() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(approvedEmployeeRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, parameter } = payload;
    try {
      const response = yield call(
        callRequest,
        APPROVED_EMPLOYEE_REQUEST,
        payloadData,
        parameter,
        "",
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* rejectEmployee() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(rejectEmployeeRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback, parameter } = payload;
    try {
      const response = yield call(
        callRequest,
        REJECT_EMPLOYEE_REQUEST,
        payloadData,
        parameter,
        "",
        {}
      );

      if (response.status) {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

export default function* root() {
  yield fork(getEmployee);
  yield fork(createEmployee);
  yield fork(updateEmployee);
  yield fork(deleteEmployee);
  yield fork(getUnverifiedEmployee);
  yield fork(approvedEmployee);
  yield fork(rejectEmployee);
}
