import React, { useEffect, useState } from "react";
import { Images } from "../../../theme";
import { CommonHeading, CommonTextField, Loader } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { LOGIN_ROUTE } from "../../../constants";
import { verifyAccountRequest } from "../../../redux/slicers/user";
import { useDispatch } from "react-redux";

const VerifyAccount = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isVerify, setIsVerify] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  console.log("🚀 ~ VerifyAccount ~ params:", params);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      verifyAccountRequest({
        payloadData: {},
        parameter: params?.id,
        responseCallback: (res) => {
          setIsLoading(false);
          if (res.status) {
            setIsVerify(true);
            console.log(res, "res");
          } else {
            console.log(res.errors, "error");
          }
        },
      })
    );
  }, [params?.id]);

  if (isLoading) return <Loader />;

  return (
    <div className="waiting-parent">
      <div className="logo-section">
        <img className="logo-img" src={Images.mainLogo} />
      </div>
      {isVerify ? (
        <>
          <CommonHeading
            mt={"50px"}
            textAlign={"center"}
            fontWeight={300}
            text={"Your Account Is Verified"}
          />
          <CommonTextField
            topClass="bottom-text"
            text={
              <>
                please login:{" "}
                <a
                  className="bottom-link"
                  onClick={() => navigate(LOGIN_ROUTE)}
                >
                  Login Here
                </a>
              </>
            }
          />
        </>
      ) : (
        <CommonHeading
          mt={"50px"}
          textAlign={"center"}
          fontWeight={300}
          text={"Your Account Is Not Verified"}
        />
      )}
    </div>
  );
};

export default VerifyAccount;
