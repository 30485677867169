import { createSlice } from "@reduxjs/toolkit";

const UserReducer = createSlice({
  name: "userProfile",
  initialState: {
    userProfile: null,
    serviceArea: null,
    operationTime: null,
  },
  reducers: {
    addServiceAreaRequest() {},
    addServiceAreaSuccess(state, action) {
      console.log("🚀 ~ addServiceAreaSuccess ~ action:", action);
      state.serviceArea = [...state.serviceArea, action.payload];
    },

    addOperationRequest() {},
    addOperationSuccess(state, action) {
      console.log("🚀 ~ addOperationSuccess ~ action:", action);
      state.operationTime = action.payload;
    },

    getUserProfileRequest() {},
    getUserProfileSuccess(state, action) {
      console.log("🚀 ~ getUserProfileSuccess ~ action:", action);
      state.userProfile = action.payload.profile;
      state.serviceArea = action.payload.services;
      state.operationTime = action.payload.operationTime;
    },

    updateUserProfileRequest() {},
    updateUserProfileSuccess(state, action) {
      state.userProfile = action.payload;
    },

    getProfilePercentageRequest() {},
  },
});

export const {
  getUserProfileRequest,
  getUserProfileSuccess,
  updateUserProfileRequest,
  updateUserProfileSuccess,
  addServiceAreaRequest,
  addServiceAreaSuccess,
  addOperationRequest,
  addOperationSuccess,
  getProfilePercentageRequest,
} = UserReducer.actions;

export default UserReducer.reducer;
