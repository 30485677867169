import {
  CompanySetup,
  Dashboard,
  EmailVerification,
  ForgotPassword,
  Home,
  Login,
  ResetPassword,
  SelectUser,
  CreateOrgProfile,
  CreateWorkerProfile,
  ProfileSetup,
  Setting,
  WaitingScreen,
  InviteWorker,
  InviteCustomer,
  InviteUser,
  Schedule,
  Customer,
  CustomerDetail,
  AddEstimate,
  VerifyAccount,
  EmployeeRequest,
  PriceBook,
  ServiceDetail,
  ServicePriceBook,
  PriceApproved,
  MaterialPriceBook,
  EstimateDetail,
  MaterialDetail,
} from "../modules";
import customer from "../redux/slicers/customer";

export const ALERT_TIMEOUT = 3000;
export const DEV_ENV = "dev";
export const PROD_ENV = "prod";
export const APP_ENV = DEV_ENV;
export const API_LOG = APP_ENV === DEV_ENV;
export const API_TIMEOUT = 30000;

// DATE FORMATS
export const DATE_FORMAT1 = "MMM DD, YYYY";
export const DATE_FORMAT2 = "DD, MMM YY";
export const DATE_FORMAT3 = "YYYY-MM-DD";
export const DATE_FORMAT4 = "DD-MM-YYYY";
export const TIME_FORMAT1 = "hh:mma";
export const TIME_FORMAT2 = "hh:mm";
export const TIME_FORMAT3 = "hh";
export const DATE_TIME = "Do MM YYYY, hh:mm a";

// VALIDATION REGEX
export const ALLOW_ALPHABETS_REGEX = new RegExp(/^[a-zA-Z\s]*$/); // allow only alphabets and white spaces
export const ALLOW_ALPHANUM_REGEX = new RegExp(/^[a-zA-Z0-9]*$/); // allow only alphabets and numbers

// ACCESS TYPES
export const ACCESS_TYPES = {
  AUTH: "auth",
  PRIVATE: "private",
  PUBLIC: "public",
};

// REACT TOASTIFY ALERT CONFIGURATION
export const ALERT_POSITIONS = {
  TOP_Right: "top-right",
  TOP_LEFT: "top-left",
  TOP_CENTER: "top-center",
  BOTTOM_RIGHT: "bottom-right",
  BOTTOM_LEFT: "bottom-left",
  BOTTOM_CENTER: "bottom-center",
};
export const ALERT_THEMES = {
  DARK: "dark",
  COLORED: "colored",
  LIGHT: "light",
};
export const ALERT_TYPES = {
  INFO: "info",
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  DEFAULT: "default",
};

// PUBLIC ROUTES
export const HOME_ROUTE = "/";
export const LANDING_PAGE_ROUTE = "/main";

// AUTH ROUTES
export const LOGIN_ROUTE = "/login";
export const CREATE_ORG_PROFILE = "/organization-profile";
export const EMAIL_VERIFICATION = "/email-verification";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
export const COMPANY_SETUP = "/company-setup";
export const SELECT_USER = "/select-user";
export const CREATE_WORKER_PROFILE = "/worker-profile";
export const PROFILE_SETUP = "/profile-setup";
export const INVITE_WORKER = "/invite-worker";
export const INVITE_CUSTOMER = "/invite-customer";
export const INVITE_USER_ROUTE = "/invite";

export const WAITING_SCREEN = "/waiting";
export const VERIFY_SCREEN = "/verifyAccount/:id";
export const PRICE_APPROVE_SCREEN = "/price-approve/:id";

// PRIVATE ROUTES
export const DASHBOARD_ROUTE = "/dashboard";
export const SCHEDULE_ROUTE = "/schedule";
export const CUSTOMER_ROUTE = "/customer";
export const CUSTOMER_DETAIL_ROUTE = "/customer/:id";
export const ADD_ESTIMATE_ROUTE = "/add-estimate";
export const ESTIMATE_DETAIL_ROUTE = "/estimate";
export const SETTING_ROUTE = "/setting";
export const EMPLOYEE_REQUEST = "/employee-request";
export const PRICE_BOOK_ROUTE = "/price-book/:name";
export const SERVICE_DETAIL_ROUTE = "/price-book/service/:id";
export const MATERIAL_DETAIL_ROUTE = "/price-book/material/:id";
export const SERVICE_PRICE_BOOK_LIST_ROUTE = "/price-book/services/list";
export const MATERIAL_PRICE_BOOK_LIST_ROUTE = "/price-book/material/list";

export const ROUTES = [
  // PUBLIC ROUTES

  {
    route: HOME_ROUTE,
    title: "Home",
    description: "",
    access: ACCESS_TYPES.PUBLIC,
    component: <Home />,
  },
  // AUTH ROUTES
  {
    route: LOGIN_ROUTE,
    title: "Login",
    description: "",
    access: ACCESS_TYPES.AUTH,
    component: <Login />,
  },
  {
    route: CREATE_ORG_PROFILE,
    title: "Sign Up",
    description: "",
    access: ACCESS_TYPES.AUTH,
    component: <CreateOrgProfile />,
  },
  {
    route: EMAIL_VERIFICATION,
    title: "Email Verification",
    description: "",
    access: ACCESS_TYPES.AUTH,
    component: <EmailVerification />,
  },
  {
    route: FORGOT_PASSWORD,
    title: "Forgot Password",
    description: "",
    access: ACCESS_TYPES.AUTH,
    component: <ForgotPassword />,
  },
  {
    route: RESET_PASSWORD,
    title: "Reset Password",
    description: "",
    access: ACCESS_TYPES.AUTH,
    component: <ResetPassword />,
  },
  {
    route: COMPANY_SETUP,
    title: "Company Setup",
    description: "",
    access: ACCESS_TYPES.AUTH,
    component: <CompanySetup />,
  },
  {
    route: SELECT_USER,
    title: "Select User",
    description: "",
    access: ACCESS_TYPES.AUTH,
    component: <SelectUser />,
  },
  {
    route: CREATE_WORKER_PROFILE,
    title: "Create Worker Profile",
    description: "",
    access: ACCESS_TYPES.AUTH,
    component: <CreateWorkerProfile />,
  },
  {
    route: PROFILE_SETUP,
    title: "Profile Setup",
    description: "",
    access: ACCESS_TYPES.AUTH,
    component: <ProfileSetup />,
  },
  {
    route: INVITE_WORKER,
    title: "Invite Worker",
    description: "",
    access: ACCESS_TYPES.AUTH,
    component: <InviteWorker />,
  },
  {
    route: INVITE_CUSTOMER,
    title: "Invite Customer",
    description: "",
    access: ACCESS_TYPES.AUTH,
    component: <InviteCustomer />,
  },
  {
    route: INVITE_USER_ROUTE,
    title: "Invite User",
    description: "",
    access: ACCESS_TYPES.AUTH,
    component: <InviteUser />,
  },
  {
    route: WAITING_SCREEN,
    title: "Profile Setup",
    description: "",
    access: ACCESS_TYPES.AUTH,
    component: <WaitingScreen />,
  },
  {
    route: VERIFY_SCREEN,
    title: "Verify Account",
    description: "",
    access: ACCESS_TYPES.PUBLIC,
    component: <VerifyAccount />,
  },
  {
    route: PRICE_APPROVE_SCREEN,
    title: "Verify Account",
    description: "",
    access: ACCESS_TYPES.PUBLIC,
    component: <PriceApproved />,
  },
  // PRIVATE ROUTE
  {
    route: DASHBOARD_ROUTE,
    title: "Dashboard",
    description: "",
    access: ACCESS_TYPES.PRIVATE,
    component: <Dashboard />,
  },
  {
    route: SCHEDULE_ROUTE,
    title: "Schedule",
    description: "",
    access: ACCESS_TYPES.PRIVATE,
    component: <Schedule />,
  },
  {
    route: CUSTOMER_ROUTE,
    title: "Customer",
    description: "",
    access: ACCESS_TYPES.PRIVATE,
    component: <Customer />,
  },
  {
    route: CUSTOMER_DETAIL_ROUTE,
    title: "Customer Detail",
    description: "",
    access: ACCESS_TYPES.PRIVATE,
    component: <CustomerDetail />,
  },
  {
    route: ADD_ESTIMATE_ROUTE,
    title: "add Estimate",
    description: "",
    access: ACCESS_TYPES.PRIVATE,
    component: <AddEstimate />,
  },
  {
    route: ESTIMATE_DETAIL_ROUTE,
    title: "Estimate Detail",
    description: "",
    access: ACCESS_TYPES.PRIVATE,
    component: <EstimateDetail />,
  },
  {
    route: SETTING_ROUTE,
    title: "Setting",
    description: "",
    access: ACCESS_TYPES.PRIVATE,
    component: <Setting />,
  },
  {
    route: EMPLOYEE_REQUEST,
    title: "Employee Request",
    description: "",
    access: ACCESS_TYPES.PRIVATE,
    component: <EmployeeRequest />,
  },
  {
    route: PRICE_BOOK_ROUTE,
    title: "Price Book",
    description: "",
    access: ACCESS_TYPES.PRIVATE,
    component: <PriceBook />,
  },
  {
    route: SERVICE_DETAIL_ROUTE,
    title: "Service Detail",
    description: "",
    access: ACCESS_TYPES.PRIVATE,
    component: <ServiceDetail />,
  },
  {
    route: MATERIAL_DETAIL_ROUTE,
    title: "Material Detail",
    description: "",
    access: ACCESS_TYPES.PRIVATE,
    component: <MaterialDetail />,
  },

  {
    route: SERVICE_PRICE_BOOK_LIST_ROUTE,
    title: "Price Book List",
    description: "",
    access: ACCESS_TYPES.PRIVATE,
    component: <ServicePriceBook />,
  },
  {
    route: MATERIAL_PRICE_BOOK_LIST_ROUTE,
    title: "Material Book List",
    description: "",
    access: ACCESS_TYPES.PRIVATE,
    component: <MaterialPriceBook />,
  },
];

export const EMPLOYEE_ROLE = {
  COMPANY: "Company",
  INDIVIDUAL: "individual",
};

export const CUSTOMER_ROLE = {
  HOMEOWNER: "Home Owner",
  BUSINESS: "Business Owner",
};

export const USER_ROLE = {
  ADMIN: "admin",
  FREELANCER: "Freelancer",
  WORKER: "worker",
};

export const EMPLOYEE_TYPE = {
  TECHNICIAN: "technician",
  ADMIN: "admin",
  OFFICE: "office",
};

export const EmployeeTypeOption = [
  { label: "Technician", value: "TECHNICIAN" },
  { label: "Admin", value: "ADMIN" },
  { label: "Office", value: "OFFICE" },
];

export const employeeTableData = [
  {
    id: "1",
    fullName: "John",
    employeeRole: "TECHNICIAN",
    email: "abc@gmail.com",
    phoneNumber: "1209823012",
    companyName: "ABCD",
    companyCode: "5498413",
  },
  {
    id: "2",
    fullName: "Alice",
    employeeRole: "TECHNICIAN",
    email: "abc@gmail.com",
    phoneNumber: "1209823012",
    companyName: "ABCD",
    companyCode: "5498413",
  },
  {
    id: "3",
    fullName: "Michael",
    employeeRole: "TECHNICIAN",
    email: "abc@gmail.com",
    phoneNumber: "1209823012",
    companyName: "ABCD",
    companyCode: "5498413",
  },
  {
    id: "4",
    fullName: "Emily",
    employeeRole: "TECHNICIAN",
    email: "abc@gmail.com",
    phoneNumber: "1209823012",
    companyName: "ABCD",
    companyCode: "5498413",
  },
  {
    id: "5",
    fullName: "David",
    employeeRole: "TECHNICIAN",
    email: "abc@gmail.com",
    phoneNumber: "1209823012",
    companyName: "ABCD",
    companyCode: "5498413",
  },
  {
    id: "6",
    fullName: "Emma",
    employeeRole: "TECHNICIAN",
    email: "abc@gmail.com",
    phoneNumber: "1209823012",
    companyName: "ABCD",
    companyCode: "5498413",
  },
  {
    id: "7",
    fullName: "Olivia",
    employeeRole: "TECHNICIAN",
    email: "abc@gmail.com",
    phoneNumber: "1209823012",
    companyName: "ABCD",
    companyCode: "5498413",
  },
  {
    id: "8",
    fullName: "Daniel",
    employeeRole: "TECHNICIAN",
    email: "abc@gmail.com",
    phoneNumber: "1209823012",
    companyName: "ABCD",
    companyCode: "5498413",
  },
  {
    id: "9",
    fullName: "Sophia",
    employeeRole: "TECHNICIAN",
    email: "abc@gmail.com",
    phoneNumber: "1209823012",
    companyName: "ABCD",
    companyCode: "5498413",
  },
  {
    id: "10",
    fullName: "Matthew",
    employeeRole: "TECHNICIAN",
    email: "abc@gmail.com",
    phoneNumber: "1209823012",
    companyName: "ABCD",
    companyCode: "5498413",
  },
];

export const WeekDays = [
  {
    name: "Monday",
    value: "Monday",
  },
  {
    name: "Tuesday",
    value: "Tuesday",
  },
  {
    name: "Wednesday",
    value: "Wednesday",
  },
  {
    name: "Thursday",
    value: "Thursday",
  },
  {
    name: "Friday",
    value: "Friday",
  },
  {
    name: "Saturday",
    value: "Saturday",
  },
  {
    name: "Sunday",
    value: "Sunday",
  },
];

export const MEETING_CLASS = {
  "MANAGEMENT MEETING": "blue",
  "CLASS SCHEDULING": "green",
  "PROJECT DISCUSSION": "purple",
};

export const settingList = [
  { name: "Profile", key: 0 },
  { name: "Business hours", key: 1 },
  { name: "Service area", key: 2 },
  { name: "Employees & permissions", key: 3 },
  { name: "Billing", key: 4 },
  { name: "Login authentication", key: 5 },
  { name: "Price Book", key: 6 },
  { name: "Lead Sources", key: 7 },
  { name: "Tags", key: 8 },
];

export const settingListMobile = [
  { label: "Profile", value: 0 },
  { label: "Business hours", value: 1 },
  { label: "Service area", value: 2 },
  { label: "Employees & permissions", value: 3 },
  { label: "Billing", value: 4 },
  { label: "Login authentication", value: 5 },
  { name: "Price Book", key: 6 },
  { label: "Lead Sources", value: 7 },
  { label: "Tags", value: 8 },
];

export const priceListBook = [
  { name: "Services", route: "services" },
  { name: "material", route: "material" },
  { name: "Tax Rates", route: "tax-rate" },
  { name: "Service per adjuster", route: "service-adjuster" },
  // { name: "Labor rates", route: "labor-rates" },
  { name: "Material Markup", route: "material-markup" },
  // { name: "Estimate Templates", route: "Estimate Templates" },
];

export const getCustomerRole = (data) => {
  if (data.isHomeOwner) {
    return CUSTOMER_ROLE.HOMEOWNER;
  } else if (data.isBusinessOwner) {
    return CUSTOMER_ROLE.BUSINESS;
  } else {
    return "Unknown role";
  }
};

export const CURRENT_ROUTE = (route) => {
  if (route.match(/\/customer\/(.*)/)) {
    return "Customer Details";
  } else if (route.match(/\/price-book\/(.*)/)) {
    return "Price Book";
  } else {
    route = route.replace(/^\/|\/$/g, "");
    const parts = route.split(/[-\/]/);
    const formattedName = parts
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");
    return formattedName;
  }
};

export const showBackBtn = (path) => {
  if (CURRENT_ROUTE(path) === "Customer Details") {
    return true;
  } else if (CURRENT_ROUTE(path) === "Add Estimate") {
    return true;
  }
};
