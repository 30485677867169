import { Pagination } from "antd";
import React from "react";
import "./styles.scss";

const CommonPagination = ({ total, handlePagination }) => {
  return (
    <Pagination
      total={total}
      onChange={handlePagination}
      showSizeChanger={false}
      pageSize={10}
      position={["bottomCenter"]}
    />
  );
};

export default CommonPagination;
