import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  CommonButton,
  CommonHeading,
  CommonInputField,
  CommonModal,
  CommonTable,
  CommonTextField,
  CommonThreeDotMenu,
} from "../../../components/common";
import { Images } from "../../../theme";
import { Avatar, Col, Pagination, Row, Space } from "antd";
import "./styles.scss";
import { employeeTableData } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getStockRequest } from "../../../redux/slicers/customer";
import { employeeManipulator } from "../../../data-manipulator/user";
import { AddEmployee } from "../../../components";
import {
  getUnverifiedEmployeeRequest,
  rejectEmployeeRequest,
} from "../../../redux/slicers/employee";

const EmployeeRequest = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rejectModal, setRejectModal] = useState(false);
  const [id, setId] = useState(null);
  const [approvedModal, setApprovedModal] = useState(false);

  const searchRef = useRef();
  const [offSet, setOffSet] = useState(0);
  const [total, setTotal] = useState(0);
  const { companyId } = useSelector((t) => t.user.data);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const callRequest = (search = "") => {
    dispatch(
      getUnverifiedEmployeeRequest({
        payloadData: {},
        query: `limit=10&offset=${offSet}&search=${search}&id=${companyId}`,
        responseCallback: (res) => {
          console.log("🚀 ~ useEffect ~ res:", res);
          setIsLoading(false);
          if (res.status) {
            console.log(res, "res");
            setList(employeeManipulator(res.data));
            setTotal(res.total);
          } else {
            console.log(res.errors, "error");
          }
        },
      })
    );
  };

  useEffect(() => {
    callRequest();
  }, [offSet]);

  const handleSearch = (e) => {
    if (searchRef.current) clearTimeout(searchRef.current);

    searchRef.current = setTimeout(() => {
      callRequest(e.target.value);
    }, 300);
  };

  const handlePagination = (e) => {
    console.log(e, "pagination");
    let test = (e - 1) * 10;
    setOffSet(test);
  };

  const renderServiceColumn = useCallback(
    (text) => <CommonTextField text={text} />,
    []
  );

  const renderNameColumn = useCallback(
    (text, { logo }) => (
      <Space>
        <Avatar size={50} src={logo} icon={<img src={Images.avatar1} />} />
        <CommonTextField text={text} />
      </Space>
    ),
    []
  );

  const renderActionColumn = useCallback(
    (_, { id }) => (
      <Space className="action-parent">
        <CommonButton
          text={"Approved"}
          topClass={"small-but"}
          onClick={() => {
            setId(id);
            setApprovedModal(true);
          }}
        />
        <CommonButton
          text={"Reject"}
          topClass={"small-but"}
          onClick={() => {
            setId(id);
            setRejectModal(true);
          }}
        />
      </Space>
    ),
    []
  );

  const columns = [
    {
      title: "Employee name",
      dataIndex: "fullName",
      key: "fullName",
      width: "170px",
      render: renderNameColumn,
    },
    {
      title: "Role",
      dataIndex: "employeeType",
      key: "employeeType",
      render: renderServiceColumn,
    },
    {
      title: "Email address",
      dataIndex: "email",
      key: "email",
      render: renderServiceColumn,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: renderServiceColumn,
    },
    {
      title: "Company",
      dataIndex: "companyName",
      key: "companyName",
      render: renderServiceColumn,
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: renderActionColumn,
      align: "center",
      width: "200px",
    },
  ];
  return (
    <div className="employee-request-parent">
      <div className="search-section">
        <CommonTextField text={"Employee Request"} fontWeight={600} />
        <CommonInputField
          onChange={handleSearch}
          name="search"
          className="small-input"
          placeholder={"search..."}
          suffix={<img src={Images.searchIcon} />}
        />
      </div>
      <CommonTable
        total={total}
        handlePagination={handlePagination}
        columns={columns}
        dataSource={list}
        loading={isLoading}
      />
      <CommonModal
        destroyOnClose={true}
        title={<CommonHeading level={1} text={"Permissions"} />}
        isModalVisible={approvedModal}
        setIsModalVisible={setApprovedModal}
      >
        <AddEmployee
          id={id}
          setId={setId}
          setList={setList}
          list={list}
          setApprovedModal={setApprovedModal}
          approved={true}
        />
      </CommonModal>
      <CommonModal
        isModalVisible={rejectModal}
        setIsModalVisible={setRejectModal}
        discription="Do you want to reject request?"
        // loading={isLoading}
        onConfirm={() => {
          dispatch(
            rejectEmployeeRequest({
              payloadData: {},
              parameter: id,
              responseCallback: (res) => {
                if (res.status) {
                  let listCopy = [...list];
                  let index = listCopy.findIndex((user) => user.id === id);
                  if (index !== -1) {
                    listCopy.splice(index, 1);
                  }
                  setList(listCopy);
                  setRejectModal(false);
                  setId(null);
                } else {
                  console.log(res.errors, "error");
                }
              },
            })
          );
        }}
      ></CommonModal>
    </div>
  );
};

export default EmployeeRequest;
