import React, { useCallback, useState } from "react";
import { CommonInputField, CommonTextField } from "../../../..";
import { Checkbox, Col, Flex, Form, Row } from "antd";

import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CommonButton,
  CommonSelect,
  CommonSwitch,
  CommonTextAreaField,
  ImageUploader,
} from "../../../../common";
import { uploadImgRequest } from "../../../../../redux/slicers/user";

import {
  createServiceRequest,
  createIndustryRequest,
  updateServiceRequest,
  updateIndustryRequest,
  updateTaxRequest,
  createTaxRequest,
} from "../../../../../redux/slicers/priceBook";

const AddTax = ({
  id = null,
  setId = () => {},
  setList = () => {},
  list = [],
  setCreateModal = () => {},
  setUpdateModal = () => {},
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { companyId } = useSelector((t) => t.user.data);

  const onFinish = (values) => {
    setLoading(true);

    const { rate, description } = values;

    const payloadData = {
      rate,
      description,
    };

    console.log(payloadData, "payloadData");

    if (id) {
      dispatch(
        updateTaxRequest({
          payloadData,
          parameter: id,
          responseCallback: (res) => {
            if (res.status) {
              let userCopy = [...list];
              let index = userCopy.findIndex((user) => user.id === id);

              if (index !== -1) {
                userCopy[index] = res.data;
              }
              setList(userCopy);
              setUpdateModal(false);
              setId(null);
            }
          },
        })
      );
    } else {
      dispatch(
        createTaxRequest({
          payloadData: {
            ...payloadData,
            companyId: companyId,
          },
          responseCallback: (res) => {
            console.log(res, "resresresres");
            if (res.status) {
              setList((pre) => [res.data, ...pre]);
              setCreateModal(false);
            }
          },
        })
      );
    }
    setLoading(false);
  };

  const initialValues = useCallback(() => {
    const data = id && list.find((user) => user.id === id);
    return {
      rate: data?.rate,
      description: data?.description,
    };
  }, []);

  return (
    <Form onFinish={onFinish} initialValues={initialValues()}>
      <CommonInputField name="description" placeholder={"Description"} />
      <CommonInputField type={"number"} name="rate" placeholder={"Rate"} />

      <div className="but-sec">
        <CommonButton
          width={"180px"}
          loading={loading}
          htmlType="submit"
          topClass={"small-but"}
          text={`${id ? "Update" : "Add"} Service`}
        />
      </div>
    </Form>
  );
};

export default AddTax;
