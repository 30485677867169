import React, { useEffect, useState } from "react";
import "./styles.scss";
import { CommonHeading, CommonTextField, CommonArrowBut } from "../../common";
import { Drawer, Progress, Space } from "antd";
import { Images } from "../../../theme";
import {
  getProfilePercentage,
  getProfilePercentageRequest,
} from "../../../redux/slicers/userProfile";
import { useDispatch, useSelector } from "react-redux";
import { CURRENT_ROUTE, showBackBtn, USER_ROLE } from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "../..";
import _ from "lodash";

const Header = () => {
  const dispatch = useDispatch();
  const [per, setPer] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const { data } = useSelector((t) => t.user);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsMobile(false);
  }, [location]);

  useEffect(() => {
    if (
      !data?.isFreelancer &&
      (data?.companyProfile || !_.isEmpty(data?.companyProfile))
    ) {
      console.log("test");
      dispatch(
        getProfilePercentageRequest({
          payloadData: {},
          responseCallback: (res) => {
            console.log("🚀 ~  resData:", res);
            if (res.status) {
              setPer(res.data);
            } else {
              // setLoading(false);
            }
          },
        })
      );
    }
  }, []);

  return (
    <>
      <header className="header-parent">
        <div className="main-header">
          <Space align="baseline">
            {showBackBtn(location.pathname) && (
              <img
                className="c-p"
                src={Images.backIconBlack}
                width={"8px"}
                onClick={() => navigate(-1)}
              />
            )}
            <CommonHeading text={CURRENT_ROUTE(location.pathname)} />
          </Space>
          <div>
            <div className="right-side-content">
              <div className="profile-complete">
                <Progress
                  size={50}
                  strokeWidth={10}
                  percent={Number(per)}
                  type="circle"
                />
                <CommonTextField
                  className={"theme-text"}
                  text={"Profile Completed"}
                />
                <CommonArrowBut src={Images.rightArrow} sizeClass={"small"} />
              </div>
              <img src={Images.messageIcon} />
              <img src={Images.locationIcon} />
              <div className="profile-section">
                <img src={Images.avatar} />
                <CommonTextField fontWeight={500} text={"John Smith"} />
                <CommonArrowBut src={Images.rightArrow} sizeClass={"small"} />
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-header">
          <img
            src={Images.sideBarIcon}
            width={"18px"}
            height={"18px"}
            onClick={() => setIsMobile(true)}
          />
          <img src={Images.mainLogo} width={"200px"} />
        </div>
      </header>
      <Drawer
        title={
          <div className="logo">
            <img
              src={Images.mainLogo}
              width="160px"
              onClick={() => {
                setIsMobile(false);
                navigate("/");
              }}
            />
          </div>
        }
        placement="left"
        onClose={() => setIsMobile(false)}
        open={isMobile}
        width="60%"
        className="side-drawer"
      >
        <Sidebar isDrawer={"drawer"} />
      </Drawer>
    </>
  );
};

export default Header;
