// @flow
import _ from "lodash";
import { createSlice, current } from "@reduxjs/toolkit";

const priceBook = createSlice({
  name: "priceBook",
  initialState: {
    data: {},
    services: [],
    materials: [],
    addAllEstimateData: null,
  },
  reducers: {
    getIndustryRequest(state, action) {},
    createIndustryRequest(state, action) {},
    updateIndustryRequest(state, action) {},
    deleteIndustryRequest(state, action) {},

    getCategoryRequest(state, action) {},
    createCategoryRequest(state, action) {},
    updateCategoryRequest(state, action) {},
    deleteCategoryRequest(state, action) {},

    getServiceRequest(state, action) {},
    createServiceRequest(state, action) {},
    updateServiceRequest(state, action) {},
    deleteServiceRequest(state, action) {},
    getServiceByIdRequest(state, action) {},
    getAllServiceRequest(state, action) {},
    addAllServices(state, action) {
      state.services = action.payload;
    },
    addServices(state, action) {
      state.addAllEstimateData = {
        ...state.addAllEstimateData,
        services: [...state.addAllEstimateData.services, action.payload],
      };
    },
    updateServices(state, action) {
      const filter = state.addAllEstimateData.services.map((d) => {
        const match = d.serviceId === action.payload.id;
        if (match) {
          return {
            ...d,
            qty: action.payload.qty,
          };
        }
        return d;
      });
      state.services = filter;

      state.addAllEstimateData = {
        ...state.addAllEstimateData,
        services: filter,
      };
    },
    deleteServices(state, action) {
      const dataCopy = [...state.services];
      dataCopy.splice(action.payload, 1);
      state.services = dataCopy;
    },
    addAllEstimateData(state, action) {
      state.addAllEstimateData = action.payload;
    },

    getTaxRequest(state, action) {},
    getAllTaxRequest(state, action) {},
    createTaxRequest(state, action) {},
    updateTaxRequest(state, action) {},
    deleteTaxRequest(state, action) {},

    getMaterialIndustryRequest(state, action) {},
    createMaterialIndustryRequest(state, action) {},
    updateMaterialIndustryRequest(state, action) {},
    deleteMaterialIndustryRequest(state, action) {},

    getMaterialCategoryRequest(state, action) {},
    createMaterialCategoryRequest(state, action) {},
    updateMaterialCategoryRequest(state, action) {},
    deleteMaterialCategoryRequest(state, action) {},

    getMaterialServiceRequest(state, action) {},
    createMaterialServiceRequest(state, action) {},
    updateMaterialServiceRequest(state, action) {},
    deleteMaterialServiceRequest(state, action) {},
    getMaterialServiceByIdRequest(state, action) {},
    addAllMaterialServices(state, action) {
      state.materials = action.payload;
    },
    addMaterialServices(state, action) {
      state.addAllEstimateData = {
        ...state.addAllEstimateData,
        material: [...state.addAllEstimateData.material, action.payload],
      };
    },
    updateMaterialServices(state, action) {
      const filter = state.addAllEstimateData.material.map((d) => {
        const match = d.materialId === action.payload.id;
        if (match) {
          return {
            ...d,
            qty: action.payload.qty,
          };
        }
        return d;
      });
      state.materials = filter;

      state.addAllEstimateData = {
        ...state.addAllEstimateData,
        material: filter,
      };
    },
    deleteMaterialServices(state, action) {
      const dataCopy = [...state.materials];
      dataCopy.splice(action.payload, 1);
      state.materials = dataCopy;
    },

    createAdjusterRequest(state, action) {},

    getMaterialMarkupRequest(state, action) {},
    createMaterialMarkupRequest(state, action) {},
    updateMaterialMarkupRequest(state, action) {},
    deleteMaterialMarkupRequest(state, action) {},
  },
});

export const {
  getIndustryRequest,
  createIndustryRequest,
  updateIndustryRequest,
  deleteIndustryRequest,

  getCategoryRequest,
  createCategoryRequest,
  updateCategoryRequest,
  deleteCategoryRequest,

  getServiceRequest,
  createServiceRequest,
  updateServiceRequest,
  deleteServiceRequest,
  getServiceByIdRequest,
  getAllServiceRequest,
  addAllServices,
  addServices,
  updateServices,
  deleteServices,
  addAllEstimateData,

  getTaxRequest,
  getAllTaxRequest,
  createTaxRequest,
  updateTaxRequest,
  deleteTaxRequest,

  getMaterialIndustryRequest,
  createMaterialIndustryRequest,
  updateMaterialIndustryRequest,
  deleteMaterialIndustryRequest,

  getMaterialCategoryRequest,
  createMaterialCategoryRequest,
  updateMaterialCategoryRequest,
  deleteMaterialCategoryRequest,

  getMaterialServiceRequest,
  createMaterialServiceRequest,
  updateMaterialServiceRequest,
  deleteMaterialServiceRequest,
  getMaterialServiceByIdRequest,
  addAllMaterialServices,
  addMaterialServices,
  updateMaterialServices,
  deleteMaterialServices,

  createAdjusterRequest,

  getMaterialMarkupRequest,
  createMaterialMarkupRequest,
  updateMaterialMarkupRequest,
  deleteMaterialMarkupRequest,
} = priceBook.actions;

export default priceBook.reducer;
