import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  CommonAddCard,
  CommonButton,
  CommonCard,
  CommonHeading,
  CommonInputField,
  CommonModal,
  CommonPagination,
  CommonTable,
  CommonTextField,
  CommonThreeDotMenu,
} from "../../../../common";
import { Images } from "../../../../../theme";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Flex, Row, Space } from "antd";
import { SERVICE_DETAIL_ROUTE } from "../../../../../constants";
import "./styles.scss";
import {
  deleteServiceRequest,
  getCategoryRequest,
  getServiceRequest,
} from "../../../../../redux/slicers/priceBook";

const Service = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const industryName = params.get("name");
  const industryId = params.get("id");

  const searchRef = useRef();
  const [offSet, setOffSet] = useState(0);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const callRequest = (search = "") => {
    dispatch(
      getServiceRequest({
        payloadData: {},
        query: `limit=10&offset=${offSet}&search=${search}&id=${industryId}`,
        responseCallback: (res) => {
          console.log("🚀 ~ useEffect ~ res:", res);
          setIsLoading(false);
          if (res.status) {
            console.log(res, "res");
            setList(res.data);
            setTotal(res.count);
          } else {
            console.log(res.errors, "error");
          }
        },
      })
    );
  };

  useEffect(() => {
    callRequest();
  }, [offSet]);

  const handleSearch = (e) => {
    if (searchRef.current) clearTimeout(searchRef.current);

    searchRef.current = setTimeout(() => {
      callRequest(e.target.value);
    }, 300);
  };

  const handlePagination = (e) => {
    console.log(e, "pagination");
    let test = (e - 1) * 10;
    setOffSet(test);
  };

  const renderColumn = useCallback(
    (text) => <CommonTextField className={"light-text"} text={text} />,
    []
  );

  const renderPriceColumn = useCallback(
    (text) => <CommonTextField className={"light-text"} text={`$${text}`} />,
    []
  );

  const renderNameColumn = useCallback(
    (text, { image, id }) => (
      <Space className="service-name">
        <img src={image} />
        <CommonTextField className={"light-text c-p"} text={text} />
      </Space>
    ),
    []
  );

  const renderActionColumn = useCallback(
    (_, { id, name }) => (
      <div className="action-parent">
        <CommonButton
          width={"120px"}
          text={"View Details"}
          topClass={"small-but"}
          onClick={() =>
            navigate(`${SERVICE_DETAIL_ROUTE.replace(":id", id)}?&name=${name}`)
          }
          // onClick={() => navigate(CUSTOMER_DETAIL_ROUTE.replace(":id", id))}
        />
        <CommonThreeDotMenu
          onEdit={() => onEdit(id)}
          onDelete={() => onDelete(id)}
        />
      </div>
    ),
    []
  );

  return (
    <div className="common-container">
      <div className="flex-between">
        <Flex align="center" gap={10}>
          <img
            className="c-p"
            src={Images.backIconBlack}
            width={"8px"}
            onClick={() => navigate(-1)}
          />
          <CommonHeading text={industryName} />
        </Flex>

        <div className="search-parent">
          <CommonInputField
            onChange={handleSearch}
            name="search"
            className="small-input"
            placeholder={"search..."}
            suffix={<img src={Images.searchIcon} />}
          />
        </div>
      </div>

      <Row gutter={[20, 20]} className="mt-20">
        {list.map((t) => (
          <Col key={id} xs={24} sm={12} md={12} lg={8} xxl={8}>
            <CommonAddCard data={t} />
          </Col>
        ))}
      </Row>

      <CommonPagination total={total} handlePagination={handlePagination} />
    </div>
  );
};

export default Service;
