import React, { useState } from "react";
import {
  CommonAuthLayout,
  CommonInputField,
  CommonTextField,
} from "../../../components";
import {
  EMAIL_RULE,
  handlePassworMatch,
  passwordValidation,
  phoneValidation,
  validatorField,
} from "../../../utils";
import { Checkbox, Form, Space } from "antd";
import { CommonButton } from "../../../components/common";
import {
  COMPANY_SETUP,
  EMAIL_VERIFICATION,
  LOGIN_ROUTE,
} from "../../../constants";
import { useNavigate } from "react-router-dom";
import { signupRequest } from "../../../redux/slicers/user";
import { useDispatch } from "react-redux";

const CreateOrgProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const { getFieldValue } = form;

  const onFinish = (values) => {
    setLoading(true);
    console.log("🚀 ~ onFinish ~ values:", values);

    const { email, name, phoneNumber, password } = values;

    const payloadData = {
      email: email,
      phone: phoneNumber,
      companyCode: "",
      fullName: name,
      password: password,
      isInvited: false,
      isAdmin: true,
      isFreelancer: false,
      isWorker: false,
      companyName: "",
    };

    dispatch(
      signupRequest({
        payloadData,
        responseCallback: (res) => {
          console.log("🚀 ~ onFinish ~ res:", res);
          if (res.status) {
            navigate(EMAIL_VERIFICATION, {
              state: { email: email, action: "signup" },
            });
          } else {
            // setLoading(false);
          }
          setLoading(false);
        },
      })
    );
  };

  return (
    <CommonAuthLayout
      heading={"Create Your Profile"}
      patternPosition={"right"}
      bottomText={
        <CommonTextField
          topClass="bottom-text"
          text={
            <>
              Have An Account?{" "}
              <span
                className="bottom-link"
                onClick={() => navigate(LOGIN_ROUTE)}
              >
                Login Here
              </span>
            </>
          }
        />
      }
    >
      <Form form={form} onFinish={onFinish}>
        <CommonInputField
          name="name"
          placeholder={"Full Name"}
          rules={[
            {
              validator: (_, value) => {
                return validatorField(_, value, 3, 80);
              },
            },
          ]}
        />
        <CommonInputField
          name="email"
          type={"email"}
          placeholder={"name@email.com"}
          rules={EMAIL_RULE}
        />
        <CommonInputField
          type={"number"}
          name="phoneNumber"
          placeholder={"Phone number"}
          rules={[
            {
              validator: (_, value) => {
                return phoneValidation(_, value);
              },
            },
          ]}
        />
        <CommonInputField
          name="password"
          type={"password"}
          placeholder={"Password"}
          rules={[
            {
              validator: (_, value) => {
                return passwordValidation(_, value);
              },
            },
          ]}
        />
        <CommonInputField
          name="confirmPassword"
          type={"password"}
          placeholder={"Confirm Password"}
          rules={[
            {
              validator: (_, value) => {
                return handlePassworMatch(_, value, getFieldValue("password"));
              },
            },
          ]}
        />

        <CommonButton loading={loading} htmlType="submit" text={"Signup"} />
      </Form>
    </CommonAuthLayout>
  );
};

export default CreateOrgProfile;
