import React from "react";
import {
  CommonAuthLayout,
  CommonInputField,
  CommonTextField,
} from "../../../components";
import {
  EMAIL_RULE,
  handlePassworMatch,
  passwordValidation,
} from "../../../utils";
import { Form } from "antd";
import { CommonButton } from "../../../components/common";
import { EMAIL_VERIFICATION, LOGIN_ROUTE } from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPasswordRequest } from "../../../redux/slicers/user";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { email, otp } = location.state;

  const onFinish = (values) => {
    console.log("🚀 ~ onFinish ~ value:", values);

    const payloadData = {
      email: email,
      password: values.password,
      otp: otp,
    };

    dispatch(
      resetPasswordRequest({
        payloadData,
        responseCallback: (res) => {
          console.log("🚀 ~ onFinish ~ res:", res);
          if (res.status) {
            navigate(LOGIN_ROUTE);
          }
        },
      })
    );
  };

  const [form] = Form.useForm();
  const { getFieldValue } = form;

  return (
    <CommonAuthLayout heading={"Reset Password"} backBut={true}>
      <Form form={form} onFinish={onFinish} className="verification-parent">
        <CommonInputField
          name="password"
          type={"password"}
          placeholder={"Password"}
          rules={[
            {
              validator: (_, value) => {
                return passwordValidation(_, value);
              },
            },
          ]}
        />
        <CommonInputField
          name="confirmPassword"
          type={"password"}
          placeholder={"Confirm Password"}
          rules={[
            {
              validator: (_, value) => {
                return handlePassworMatch(_, value, getFieldValue("password"));
              },
            },
          ]}
        />
        <CommonButton
          topClass="mt-20"
          htmlType="submit"
          text={"Reset Password"}
        />
      </Form>
    </CommonAuthLayout>
  );
};

export default ResetPassword;
