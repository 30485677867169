import React from "react";
import "./styles.scss";
import { Images } from "../../../theme";
import { CommonArrowBut, CommonHeading, CommonTextField } from "../../common";
import { useNavigate } from "react-router-dom";
import {
  HOME_ROUTE,
  LANDING_PAGE_ROUTE,
  LOGIN_ROUTE,
} from "../../../constants";

const CommonAuthLayout = ({
  children,
  heading,
  patternPosition = "left",
  bottomText,
  backBut,
}) => {
  const navigate = useNavigate();
  return (
    <div className={`auth-layout-parent ${patternPosition || ""}`}>
      <div className="left-side"></div>
      <div className="right-side">
        <div className="content">
          <div className="logo-section">
            <img
              className="logo c-p"
              src={Images.mainLogo}
              onClick={() => navigate(HOME_ROUTE)}
            />
            <div className="headind-parent">
              {backBut && (
                <CommonArrowBut
                  src={Images.backArrow}
                  onClick={() => navigate(-1)}
                />
              )}
              <CommonHeading fontWeight={300} text={heading} />
            </div>
          </div>
          {children}
        </div>
        {bottomText}
      </div>
    </div>
  );
};

export default CommonAuthLayout;
