import React from "react";
import { Images } from "../../../theme";
import {
  CommonArrowBut,
  CommonHeading,
  CommonTextField,
} from "../../../components";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { LOGIN_ROUTE } from "../../../constants";

const WaitingScreen = () => {
  const navigate = useNavigate();
  return (
    <div className="waiting-parent">
      <div className="logo-section">
        <CommonArrowBut
          onClick={() => navigate(LOGIN_ROUTE)}
          src={Images.backArrow}
          className={"back-wait"}
        />
        <img className="logo-img" src={Images.mainLogo} />
      </div>
      <img className="wait-img" src={Images.waitingImg} />
      <CommonHeading
        textAlign={"center"}
        fontWeight={300}
        text={"Your Account Is Being Verified"}
      />
      <CommonTextField
        topClass={"content"}
        mt={"20px"}
        textAlign={"center"}
        text={
          "This generally takes24 hours, but in rare cases can take up to two weeks. We’ll notify you when verification is complete"
        }
      />
    </div>
  );
};

export default WaitingScreen;
