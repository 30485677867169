import React, { useEffect, useRef, useState } from "react";
import { Images } from "../../../../theme";
import { Button, Col, Flex, Form, Row, Space } from "antd";
import {
  CommonButton,
  CommonDivider,
  CommonInputField,
  CommonPhoneInput,
  CommonRadio,
  CommonSelect,
  CommonTextField,
} from "../../../common";
import {
  EMAIL_RULE,
  passwordValidation,
  phoneValidation,
  validatorField,
} from "../../../../utils";
import {
  createStockRequest,
  getCompanyListRequest,
  updateStockRequest,
} from "../../../../redux/slicers/customer";
import { useDispatch, useSelector } from "react-redux";
import {
  CompanyListManipulator,
  WorkerManipulator,
  customerManipulator,
  organizationListManipulator,
} from "../../../../data-manipulator/user";
import {
  CUSTOMER_ROLE,
  getCustomerCondition,
  EMPLOYEE_ROLE,
} from "../../../../constants";
import "./styles.scss";

const AddNewCustomer = ({
  onAddCustomer = () => {},
  stockId,
  setAddCustomer,
}) => {
  const [loading, setLoading] = useState(false);
  const { data } = useSelector((t) => t.user);
  onAddCustomer;
  const dispatch = useDispatch();

  const onFinish = (values) => {
    console.log("🚀 ~ onFinish ~ values:", values);
    setLoading(true);
    const {
      fullName,
      mobilePhone,
      displayName,
      workPhone,
      email,
      role,
      streetAddress,
      city,
      unit,
      state,
      zipCode,
      addressNote,
      addresses,
      homePhone,
      customerType,
    } = values;

    const payloadData = {
      fullName: fullName,
      displayName: displayName,
      email: email,
      companyId: data?.companyId,
      mobilePhone: mobilePhone,
      workPhone: workPhone,
      role: role,
      isHomeOwner: customerType === CUSTOMER_ROLE.HOMEOWNER ? true : false,
      isBusinessOwner: customerType === CUSTOMER_ROLE.BUSINESS ? true : false,
      streetAddress: streetAddress,
      city: city,
      unit: unit,
      state: state,
      zip: zipCode,
      addressNotes: addressNote,
      addresses: addresses,
      homePhone: homePhone,
    };

    dispatch(
      createStockRequest({
        payloadData: payloadData,
        responseCallback: (res) => {
          console.log(res, "createStock");

          if (res.status) {
            setAddCustomer(false);
            onAddCustomer(res.data);
          } else {
            console.log(res.errors, "error");
          }
        },
      })
    );

    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const initialValues = () => {
    return {
      customerType: CUSTOMER_ROLE.HOMEOWNER,
      addresses: [{}],
      companyName: data?.businessName,
    };
  };

  return (
    <Form
      initialValues={initialValues()}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="create-customer-parent"
    >
      <Row gutter={[10, 0]}>
        <Col span={24}>
          <CommonInputField name="fullName" placeholder={"Full Name"} />
        </Col>

        <Col lg={24} md={24} sm={24} xs={24}>
          <CommonInputField name="displayName" placeholder={"Display Name"} />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <CommonInputField
            name="email"
            type={"email"}
            className={"auth"}
            placeholder={"john.smith@domain.com"}
            rules={EMAIL_RULE}
          />
        </Col>
        <Col lg={8} md={24} sm={24} xs={24}>
          <CommonInputField
            name="mobilePhone"
            type={"number"}
            placeholder={"Mobile Phone"}
            rules={[
              {
                validator: (_, value) => {
                  return phoneValidation(_, value);
                },
              },
            ]}
          />
        </Col>
        <Col lg={8} md={12} sm={12} xs={24}>
          <CommonInputField
            name="homePhone"
            type={"number"}
            placeholder={"Home Phone"}
            rules={[
              {
                validator: (_, value) => {
                  return phoneValidation(_, value);
                },
              },
            ]}
          />
        </Col>
        <Col lg={8} md={12} sm={12} xs={24}>
          <CommonInputField
            name="workPhone"
            type={"number"}
            placeholder={"Work Phone"}
            rules={[
              {
                validator: (_, value) => {
                  return phoneValidation(_, value);
                },
              },
            ]}
          />
        </Col>
        <Col lg={12} md={12} sm={12} xs={24}>
          <CommonInputField name="companyName" placeholder={"Company Name"} />
        </Col>
        <Col lg={12} md={12} sm={12} xs={24}>
          <CommonInputField name="role" placeholder={"Role"} />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <CommonRadio
            options={[
              {
                name: (
                  <CommonTextField
                    className={"light-text"}
                    text={CUSTOMER_ROLE["HOMEOWNER"]}
                  />
                ),
                value: CUSTOMER_ROLE["HOMEOWNER"],
              },
              {
                name: (
                  <CommonTextField
                    className={"light-text"}
                    text={CUSTOMER_ROLE["BUSINESS"]}
                  />
                ),
                value: CUSTOMER_ROLE["BUSINESS"],
              },
            ]}
            name={"customerType"}
          />
          <CommonDivider className={"role-divider"} />
        </Col>
        <Col lg={12} md={12} sm={12} xs={24}>
          <CommonInputField
            name="streetAddress"
            placeholder={"Street Address"}
          />
        </Col>
        <Col lg={12} md={12} sm={12} xs={24}>
          <CommonInputField name="unit" placeholder={"Unit"} />
        </Col>

        <Col lg={8} md={12} sm={12} xs={24}>
          <CommonInputField name="city" placeholder={"City"} />
        </Col>
        <Col lg={8} md={12} sm={12} xs={24}>
          <CommonInputField name="state" placeholder={"State"} />
        </Col>
        <Col lg={8} md={12} sm={12} xs={24}>
          <CommonInputField
            type={"number"}
            name="zipCode"
            placeholder={"Zip Code"}
          />
        </Col>
        <Col lg={24} md={12} sm={12} xs={24}>
          <CommonInputField name="addressNote" placeholder={"Address Note"} />
        </Col>

        <CommonDivider />
        <Form.List name="addresses">
          {(fields, { add, remove }) => (
            <>
              <div className="flex-between address-text">
                <CommonTextField
                  text={"Add Address"}
                  className={"theme-text"}
                  fontWeight={500}
                />
                <img src={Images.add} onClick={() => add()} />
              </div>

              {fields.map(({ key, name, ...restField }) => (
                <>
                  <div key={key} className="address-container">
                    <Row gutter={[10, 0]}>
                      <Col lg={12} md={12} sm={12} xs={24}>
                        <CommonInputField
                          name={[name, "customerNote"]}
                          field={{ ...restField }}
                          placeholder={"Customer Note"}
                        />
                      </Col>

                      <Col lg={12} md={12} sm={12} xs={24}>
                        <CommonInputField
                          name={[name, "customerTag"]}
                          field={{ ...restField }}
                          placeholder={"Customer Tags"}
                        />
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={24}>
                        <CommonInputField
                          name={[name, "customerBillTo"]}
                          field={{ ...restField }}
                          placeholder={"This customer bill to"}
                        />
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={24}>
                        <CommonInputField
                          name={[name, "leadResource"]}
                          field={{ ...restField }}
                          placeholder={"Lead Source"}
                        />
                      </Col>
                    </Row>
                    {fields.length > 1 ? (
                      <div
                        className="delete-option"
                        onClick={() => remove(name)}
                      >
                        <img src={Images.deleteIcon} />
                      </div>
                    ) : null}
                  </div>

                  <CommonDivider className={"address-divider"} />
                </>
              ))}
            </>
          )}
        </Form.List>
      </Row>

      <CommonButton
        topClass={"small-but"}
        width={"160px"}
        htmlType={"submit"}
        loading={loading}
        text={`${stockId ? "Update" : "Create"} Customer`}
      />
    </Form>
  );
};
export default AddNewCustomer;
