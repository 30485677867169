import React from "react";
import { CommonButton, CommonTextAreaField } from "../../../../common";
import { Form } from "antd";

const OrgMessageModal = ({ form, setOrgMessageModal }) => {
  const onFinish = ({ orgMessage }) => {
    console.log("🚀 ~ onFinish ~ orgMessage:", orgMessage);
    form.setFieldsValue({
      orgMessage: orgMessage,
    });
    setOrgMessageModal(false);
  };

  return (
    <Form
      onFinish={onFinish}
      initialValues={{ orgMessage: form.getFieldValue("orgMessage") }}
    >
      <CommonTextAreaField name={"orgMessage"} placeholder={"Write here..."} />
      <CommonButton
        topClass={"estimate-btn small-but"}
        text={"Save "}
        htmlType="submit"
      />
    </Form>
  );
};

export default OrgMessageModal;
