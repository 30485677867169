import React, { useCallback } from "react";
import { useLocation } from "react-router-dom";
import Industry from "./Industry";
import Category from "./Category";
import Service from "./Service";

const ServicesPriceBookList = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type = params.get("type");

  const renderComponent = useCallback(() => {
    if (type == "category") {
      return <Category />;
    } else if (type == "service") {
      return <Service />;
    } else {
      return <Industry />;
    }
  }, [location]);

  return <div>{renderComponent()}</div>;
};

export default ServicesPriceBookList;
